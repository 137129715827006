.filterBox {
  display: flex;
  padding: var(--spacing-sm, 2px) var(--spacing-md, 2px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-md, 8px);
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: #fff;
  height: 32px;
  width: 32px;
  border-radius: 50%;
}

.menu {
  width: 229px;
  padding: 4px 4px;
  border-radius: 4px;
}

.newButton,
.newButton:hover,
.newButton:focus {
  gap: 8px;
  width: 59px;
  height: 28px;
  display: flex;
  font-size: 14px;
  padding: 4px 14px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  border: 1px solid var(--Primary-600, #158ecc);
  background: var(--Primary-600, #158ecc);
}

.total {
  color: var(--Gray-600, #475467);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: 0.08px;
}

.totalCount {
  color: var(--Primary-500, #1ab2ff);
  text-align: center;
  display: flex;
  padding: var(--spacing-xxs, 2px) var(--spacing-md, 8px);
  align-items: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 183.333% */
  letter-spacing: 0.12px;
  border-radius: 16px;
  border: 1px solid var(--Primary-200, #a3e0ff);
  background: var(--Primary-50, #ebf8ff);
}

.clientImg {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border: 0.75px solid #ccc;
  margin-right: 10px;
  background: linear-gradient(180deg, #f9fafb 0%, #edf0f3 100%);
}

.ellipsis {
  white-space: nowrap;
  width: 224px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.location {
  width: 36px;
  height: 36px;
  display: flex;
  padding: var(--spacing-md, 8px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-md, 8px);
  border-radius: var(--radius-md, 8px);
  border: 1px solid var(--Primary-200, #a3e0ff);
  background: var(--Primary-25, #f2faff);
}

.active {
  background: #ebf8ff;
}

.selectedCount {
  font-size: 16px;
  font-weight: 600;
  margin: 0px;
  color: var(--Primary-500, #1ab2ff);
}

.deletedRow td {
  background-color: #fffbfa !important;
}
