.floatButton {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  right: 16px;
  bottom: 16px;
  background: #fff;
  display: flex;
  padding: var(--spacing-lg, 12px);
  align-items: flex-start;
  gap: var(--spacing-md, 8px);
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}
