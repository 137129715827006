.modal {
  width: 607px;
  border-radius: var(--radius-xs, 4px);
  background: var(--Base-White, #fff);
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
}

.modalHeader {
  gap: 16px;
  display: flex;
  align-items: center;
}

.headerTitle {
  font-size: 18px;
  font-weight: 600;
  color: var(--Gray-700, #344054);
}

.weekNames {
  gap: 16px;
  align-items: center;
  margin-bottom: 16px;
}

.weekName {
  color: var(--Primary-700, #106b99);
  text-align: center;

  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  display: flex;
  padding: var(--spacing-xxs, 2px) var(--spacing-md, 8px);
  align-items: center;
  border-radius: 16px;
  border: 1px solid var(--Primary-200, #a3e0ff);
  background: var(--Primary-25, #f2faff);
  mix-blend-mode: multiply;
}

.iconStyle {
  border-radius: 28px;
  border: 8px solid var(--Primary-50, #fffaeb);
  background: var(--Primary-100, #fef0c7);
  display: flex;
  width: 48px;
  height: 48px;
  padding: 7px;
  justify-content: center;
  align-items: center;
}

.paidTotal {
  width: fit-content;
  color: var(--Success-700, #067647);
  text-align: center;

  /* Title section */
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 123.077% */
  letter-spacing: -0.39px;
  display: flex;
  padding: var(--spacing-xxs, 2px) var(--spacing-xl, 16px)
    var(--spacing-xxs, 2px) var(--spacing-lg, 12px);
  align-items: center;
  gap: var(--spacing-md, 8px);
  border-radius: var(--radius-xl, 12px);
  border: 1px solid var(--Success-200, #a9efc5);
  background: var(--Success-50, #ecfdf3);
  mix-blend-mode: multiply;
}

.unpaidTotal {
  width: fit-content;
  color: var(--Error-700, #b42318);
  text-align: center;

  /* Title section */
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 123.077% */
  letter-spacing: -0.39px;
  display: flex;
  padding: var(--spacing-xxs, 2px) var(--spacing-xl, 16px)
    var(--spacing-xxs, 2px) var(--spacing-lg, 12px);
  align-items: center;
  gap: var(--spacing-md, 8px);
  border-radius: var(--radius-xl, 12px);
  border: 1px solid var(--Error-200, #fecdca);
  background: var(--Error-50, #fef3f2);
  mix-blend-mode: multiply;
}
