.profileBox {
  border-radius: var(--radius-xs, 4px);
  border: 1px solid #dedede;
  width: 56px;
  height: 56px;
  overflow: hidden;
}

.box {
  background: var(--Gray-50, #f9fafb);
  padding: 24px 16px;
  border-radius: 4px;
  margin-bottom: 24px;
  position: relative;
}

.boxLabel {
  color: #475467;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
}

.boxLabel2 {
  color: #475467;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 16px;
}

.label {
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 6px;
  display: block;
}

.text {
  color: #475467;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.iconBoxsContainer {
  position: absolute;
  right: 16px;
  top: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.iconBox {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Gray-200, #eaecf0);
  background: #fff;
}

.serviceTag {
  color: var(--Gray-700, #344054);
  text-align: center;

  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  display: flex;
  padding: var(--spacing-xxs, 2px) var(--spacing-sm, 6px);
  align-items: center;
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
}