.circledesignstyle {
    .out {
      border-radius: 28px;
      border: 6px solid var(--Success-50, #ecfdf3);
      background: var(--Success-100, #dcfae6);
      padding: 6px;
    }
  }
  
  .tab {
    display: flex;
    padding: var(--spacing-lg, 12px) var(--spacing-xl, 16px);
    align-items: center;
    gap: var(--spacing-xs, 4px);
    flex: 1 0 0;
    border-radius: var(--radius-xs, 4px);
    border: 1px solid #eaecf0;
    background: #fff;
    cursor: pointer;
  
    .iconBox {
      display: flex;
      width: 40px;
      height: 40px;
      padding: 8px;
      justify-content: center;
      align-items: center;
      border-radius: var(--radius-full, 9999px);
      background: var(--Primary-50, #ebf8ff);
    }
  
    .tabText {
      color: #344054;
      font-size: 14px;
      font-weight: 500;
      margin-left: 12px;
    }
  }
  
  .tab.active {
    border: 1px solid var(--Primary-300, #76d1ff);
    background: var(--Primary-50, #ebf8ff);
  
    .iconBox {
      border-radius: var(--radius-full, 9999px);
      background: var(--Primary-100, #bae8ff);
    }
  
    .tabText {
      color: var(--Primary-700, #106b99);
    }
  }
  
  .bgGreay {
    margin: 0px;
    padding: 24px 5px;
    border-radius: var(--radius-xs, 4px);
    background: #f9fafb;
    margin-bottom: 24px !important;
  }
  
  .fileUploadBox {
    display: flex;
    padding: var(--spacing-xl, 16px) var(--spacing-3xl, 24px);
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-xs, 4px);
    align-self: stretch;
    border-radius: var(--radius-xs, 4px);
    border: 1px solid var(--Gray-200, #eaecf0);
    background: var(--Base-White, #fff);
    margin: 16px 0px;
  }
  
  .uploadedImgBox {
    width: 56px;
    height: 56px;
    flex-shrink: 0;
    border-radius: 36px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #dedede;
  }
  
  .uploadedText1 {
    color: #475467;
    font-size: 14px;
  }
  
  .uploadedText2 {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    color: var(--Primary-700, #106b99);
  }
  
  .lable {
    color: var(--Gray-600, #475467);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    letter-spacing: 0.14px;
  }
  
  .inputText {
    width: 100%;
    padding: 10px 14px;
    border-radius: var(--radius-xs, 4px);
    border: 1px solid var(--Border, #d0d5dd);
    background: var(--Base-White, #fff);
    box-shadow: none !important;
    outline: none !important;
    font-family: Inter;
    color: var(--Gray-900, #101828) !important;
  }
  
  .error {
    border: 1px solid red !important;
  }
  
  .phoneInput {
    width: 100%;
    height: 46px !important;
  }
  
  .countrySelector {
    height: 46px;
  }
  
  .headingInputs {
    color: var(--Gray-700, #344054);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
    margin-bottom: 16px;
  }
  
  .dropdownSelect {
    border-radius: var(--radius-xs, 4px);
    border: 1px solid var(--Border, #d0d5dd);
    background: var(--Base-White, #fff);
    box-shadow: none !important;
    outline: none !important;
  }
  
  .tempDelete, .tempDelete:hover, .tempDelete:focus {
    color: #b42318;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    display: flex;
    padding: var(--spacing-md, 8px) 14px;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-md, 8px);
    border-radius: var(--radius-xs, 4px);
    border: 1px solid var(--Error-200, #fecdca);
    background: var(--Error-50, #fef3f2);
  }
  
  .tempAdd, .tempAdd:hover, .tempAdd:focus {
    color: var(--Primary-700, #106b99);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    display: flex;
    padding: var(--spacing-md, 8px) 14px;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-md, 8px);
    border-radius: var(--radius-xs, 4px);
    border: 1px solid var(--Primary-200, #a3e0ff);
    background: var(--Primary-50, #ebf8ff);
  }
  