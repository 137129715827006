.BoxGridWrap {
  border-radius: var(--radius-sm, 6px);
  border: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Colors-Foreground-fg-white, #fff);
  padding: var(--spacing-3xl, 24px);
  min-height: 286px;
  margin-bottom: 24px;
  .head {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .disconnected {
      border-radius: 16px;
      border: 1px solid var(--Warning-200, #fedf89);
      background: var(--Warning-50, #fffaeb);
      mix-blend-mode: multiply;
      padding: var(--spacing-xxs, 2px) var(--spacing-md, 8px)
        var(--spacing-xxs, 2px) var(--spacing-sm, 6px);
      color: var(--Warning-700, #b54708);
      text-align: center;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      .dots {
        width: 8px;
        height: 8px;
        color: var(--Warning-700, #b54708);
        text-align: center;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        background: #b54708;
        display: inline-block;
        border-radius: 100%;
        margin-left: 6px;
        padding: 0;
        margin-right: 0;
      }
    }
    button {
      border-radius: 16px;
      border: 1px solid var(--Success-200, #a9efc5);
      background: var(--Success-50, #ecfdf3);
      mix-blend-mode: multiply;
      padding: var(--spacing-xxs, 2px) var(--spacing-md, 8px);
      color: var(--Success-700, #067647);
      text-align: center;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
    }
  }
  .body {
    h3 {
      color: var(--Gray-900, #101828);
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.18px;
      margin: 16px 0;
    }
    p {
      color: var(--Gray-500, #667085);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.14px;
    }
  }

  .bottom {
    bottom: 16px;
    position: absolute;
  }

  .infoButton {
    border-radius: var(--radius-xs, 4px);
    border: 1px solid var(--Primary-200, #a3e0ff);
    background: var(--Primary-50, #ebf8ff);
    padding: 10px var(--spacing-xl, 16px);
    color: var(--Primary-700, #106b99);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
}

.modal {
  width: 671px;
  border-radius: var(--radius-xs, 4px);
  background: var(--Base-White, #fff);
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
}

.modalHeader {
  gap: 16px;
  display: flex;
  align-items: center;
}

.headerTitle {
  font-size: 18px;
  font-weight: 600;
  color: var(--Gray-700, #344054);
}

.boxGroupList {
  .googlereviewBox {
    label {
      display: block;
      width: 100%;
    }
    input {
      width: 100%;
    }
  }
  label {
    color: var(--Gray-800, #1d2939);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.14px;
    white-space: nowrap;
    width: 180px;
  }
  .commesionBox {
    display: flex;
    align-items: center;
    border-radius: var(--radius-xs, 4px);
    border: 1px solid var(--Border, #d0d5dd);
    background: #fff;
    padding: var(--spacing-md, 8px) var(--spacing-lg, 12px);
    span {
      margin-right: 6px;
    }
    input {
      width: 50px !important;
      display: flex;
      align-items: center !important;
      border-radius: var(--radius-xs, 0px) !important;
      border: 0px solid var(--Border, #d0d5dd) !important;
      background: #fff !important;
      padding: var(--spacing-md, 0px) var(--spacing-lg, 0px) !important;
    }
  }
  input {
    border-radius: var(--radius-xs, 4px);
    border: 1px solid var(--Border, #d0d5dd);
    background: #fff;
    padding: var(--spacing-md, 8px) var(--spacing-lg, 12px);
    color: var(--Gray-900, #101828);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.08px;
    width: 411px;
  }
}
.tmsCondition {
  border-radius: var(--radius-md, 8px);
  border: 1px solid var(--Gray-100, #f2f4f7);
  background: #fff;
  padding: 16px;
  ul {
    h3 {
      color: var(--Gray-800, #1d2939);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0.175px;
      margin-top: 25px;
    }
    li {
      color: var(--Gray-800, #1d2939);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.14px;
      position: relative;
      padding-left: 13px;
      &:before {
        position: absolute;
        content: "";
        background: #000;
        width: 5px;
        height: 5px;
        border-radius: 100%;
        left: 0;
        top: 9px;
      }
    }
  }
}

.countrySelector {
  height: 38px;
}