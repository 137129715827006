.modal {
  width: 607px;
  border-radius: var(--radius-xs, 4px);
  background: var(--Base-White, #fff);
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
}

.modalHeader {
  gap: 10px;
  display: flex;
  align-items: center;
}

.headerTitle {
  font-size: 18px;
  font-weight: 600;
  color: var(--Gray-700, #344054);
}
