.NewQuoteModal .stepBoxStyle {
  text-align: center;
  padding: 45px 24px 32px;
}
.newQuotewrap h3 {
  color: var(--Gray-700, #344054);
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.18px;
}
.NewQuoteModal .footerButton {
  padding: 24px 24px 24px !important;
}
.newQuotewrap p {
  color: var(--colors-text-text-tertiary-600, #475467);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 0 10px;
}

.newQuotePage {
  background: #f7f9fc;
  height: calc(100vh - 122px);
  /* padding: 25px 28px; */
}
.navStepsticky {
  padding: 25px 38px;
}
.navStepsticky .formgroupWrap1 {
  width: 720px;
  margin: 0 auto;
}
/* .newQuotePage .dFlex {
    display: flex;
    justify-content: center;
    align-items: flex-start;
} */
.newQuotePage .newQuoteBack {
  position: absolute;
  left: 42px;
}
.newQuoteContent h3 {
  color: var(--Gray-700, #344054);
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.33px;
  text-align: left;
  border-bottom: 1px solid #eaecf0;
  padding-bottom: 20px;
}
.formgroupWrap ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 720px;
}
.formgroupWrap ul li a,
.formgroupWrap1 ul li a {
  border-radius: var(--radius-xs, 4px);
  border: 1px solid #eaecf0;
  background: #fff;
  padding: 11px 16px;
  color: #344054;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.formgroupWrap ul li {
  width: 48.4%;
  text-align: left;
}
.formgroupWrap ul li a span {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  padding: 0;
  border-radius: var(--radius-full, 9999px);
  background: var(--Primary-50, #ebf8ff);
}
.formgroupWrap1 ul li a span {
  border-radius: 4px;
  background: linear-gradient(180deg, #f9fafb 0%, #edf0f3 100%);
  border: 0.75px solid #d8d8d8;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  padding: 0;
}
.newQuotePage .newQuoteBack button a {
  color: var(--Gray-800, #1d2939);
}
.newQuotePage .newQuoteBack button {
  color: var(--Gray-800, #1d2939);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  border-radius: 4px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navStepClient ul {
  display: flex;
  justify-content: center;
  align-items: center;
}
.navStepClient ul li span {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  position: relative;
  z-index: 999;
  border-radius: 4px;
  border: 1px solid var(--Primary-200, #a3e0ff);
  background: var(--Base-White, #fff);
}
.newQuoteContent .react-international-phone-country-selector-button {
  border: 0px solid
    var(
      --react-international-phone-country-selector-border-color,
      var(--react-international-phone-border-color, gainsboro)
    );
  margin: 0;
}
.newQuoteContent
  .react-international-phone-input-container
  .react-international-phone-input {
  border: 0px solid var(--react-international-phone-border-color, gainsboro);
}
.navStepClient ul li:first-child:before {
  position: absolute;
  background: #eaecf0;
  width: 100%;
  height: 2px;
  content: "";
  top: 22%;
  z-index: 1;
}
.navStepClient ul li:nth-child(2):before {
  position: absolute;
  background: #eaecf0;
  width: 100%;
  height: 2px;
  content: "";
  top: 22%;
  z-index: 1;
}
.navStepClient ul li {
  text-align: center;
  position: relative;
  padding: 0 34px;
}
.navStepClient ul li p {
  color: var(--Gray-700, #a6a6a6);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.175px;
  margin-top: 12px;
}
.navStepClient .activeClientTab p {
  color: var(--Gray-700, #344054);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.175px;
}
.navStepClient .activeClientTab span svg {
  fill: #158ecc;
}
.navStepClient ul li svg {
  fill: #76d1ff;
}
.navStepClient .activeClientTab span {
  border-radius: 4px;
  border: 1px solid var(--Primary-300, #76d1ff);
  background: var(--Primary-50, #ebf8ff);
}
.navStepClient .activeClientTab span {
  border-radius: 4px;
  border: 1px solid var(--Primary-300, #76d1ff) !important;
  background: var(--Primary-50, #ebf8ff) !important;
}
.deactiveColorBox span {
  border-radius: 4px !important;
  border: 1px solid var(--Gray-200, #eaecf0) !important;
  background: var(--Base-White, #fff) !important;
}
.deactiveColorBox span svg {
  fill: #d0d5dd !important;
}
/* Business Detils Page */
.formgroupWrap1 .businessTab {
  color: var(--Primary-700, #106b99);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Primary-300, #76d1ff);
  background: var(--Primary-50, #ebf8ff);
}
button.CustonCloseModal {
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0px;
}
.my-custom-class .MuiButtonBase-root {
  display: block;
  width: 100%;
}
.my-custom-class .MuiButtonBase-root p {
  padding: 0;
  margin: 0;
}
.formgroupWrap1 .businessTab span {
  background: linear-gradient(180deg, #f9fafb 0%, #f9fafb 0.01%, #bae8ff 100%);
  border-radius: 4px;
  border: 0.75px solid var(--Primary-600, #158ecc29);
}
.formgroupWrap1 .businessTab span {
  background: linear-gradient(180deg, #f9fafb 0%, #f9fafb 0.01%, #bae8ff 100%);
  border-radius: 100%;
  border: 0.75px solid var(--Primary-600, #158ecc29);
}
.newtaskaddModal .inputInfo img.ExclamationCircle {
  margin-right: 0;
  opacity: 1;
}
.bsdpage .formgroupWrap1 .businessTab span svg {
  fill: #1ab2ff;
}
.formgroupboxs .text-left {
  text-align: left;
}
.formgroupboxs h2 {
  color: var(--Gray-600, #475467);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.18px;
}
.formgroupboxs .inputInfo input {
  /* color: var(--Gray-500, #667085); */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.08px;
  width: 100%;
}
.formgroup label {
  color: var(--Gray-600, #475467);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.14px;
  margin-bottom: 6px;
}
.formgroupboxs .inputInfo {
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Border, #d0d5dd);
  background: var(--Base-White, #fff);
  padding: 10px 14px;
  height: 44px;
}

.formgroupboxs .inputInfo.textarea {
  height: unset !important;
}
/* .newQuotePage .dFlex {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: auto;
    height: calc(100vh - 446px);
    padding-bottom: 0;
} */
.newQuotePage .dFlex {
  overflow: auto;
  height: calc(100vh - 217px);
}
.overflowScroll .dFlex {
  overflow: hidden;
}
.overflowScroll div#item-list {
  overflow: auto;
  height: calc(100vh - 407px);
}
.overflowScroll div#item-list::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  padding: 5px;
}

.overflowScroll div#item-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px #fff;
  border-radius: 10px;
}

.overflowScroll div#item-list::-webkit-scrollbar-thumb {
  background: #eaecf0;
  border-radius: 10px;
}

.overflowScroll div#item-list::-webkit-scrollbar-thumb:hover {
  background: #eaecf0;
}
.newQuoteContent {
  width: 720px;
  margin: 0 auto;
}
.textRightRow {
  text-align: right;
}
.addbuttonRight {
  border-radius: 4px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  padding: 8px 14px;
  color: var(--Gray-700, #344054);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.newQuoteContent .upload-btn-wrapper {
  width: 100%;
}
.newQuoteContent .upload-btn-wrapper .btnup {
  width: 100%;
}
.newQuoteContent .iconPerson {
  border-radius: 4px;
  border: 0.75px solid #eaecf0;
  display: inline-block;
  padding: 12px;
}
.bsdpage .updateButtonGeneral {
  padding-right: 34px;
  padding-bottom: 15px;
}
.uploadImgWrap {
  position: absolute;
  z-index: 9;
  top: 0;
  background: #fff;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Base-White, #fff);
}
.updatebut button {
  margin: 0 9px;
}
.updatebut .update {
  border: 0;
  background: transparent;
  padding: 0;
  color: var(--Primary-500, #9e77ed);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.updatebut .delete {
  color: var(--Error-700, #b42318);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  border: 0;
  background: transparent;
}
.uploadImgWrap img {
  object-fit: cover;
  width: 64px;
  height: 64px;
}
.customerCategory select {
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}
.customerCategory .MuiSelect-select {
  padding: 0;
}
.customerCategory
  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #1976d2;
  border-width: 0px;
}
.customerCategory .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-width: 0px;
}
.bsdScope .inputInfo textarea {
  /* color: var(--Gray-400, #98A2B3); */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  width: 100%;
  border: 0;
  min-height: 237px;
}
.bsdScope .updateButtonGeneral {
  padding: 18px 30px;
}
.bsdScope button.cancel,
.bsdScope .updateButtonGeneral .save {
  margin-top: 0;
  margin-bottom: 0;
}
.bsdScope .formgroup span {
  color: var(--Gray-600, #475467);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.bsdScope .upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.bsdScope .btn {
  color: var(--Gray-800, #1d2939);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  border-radius: 4px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  padding: 8px 14px;
}

.bsdScope .upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.bsdScope .notCustomer {
  color: var(--Gray-600, #475467);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.12px;
  padding: 0;
  margin: 0;
}
.newQuotePage.bsdScope .dFlex {
  height: calc(100vh - 221px);
}
/* ExitingFilter */
.filterListSearch ul li,
.filterListSearch ul li a {
  color: var(--Gray-800, #1d2939);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.14px;
  padding: 33px 0;
}
.filterListSearch ul {
  text-align: left;
}
.existingClients {
  padding: 33px 0;
}
.searchGroupFilter {
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Border, #d0d5dd);
  background: var(--Base-White, #fff);
  padding: 10px 14px;
  display: inline-block;
  min-width: 720px;
}
.searchGroupFilter input {
  width: 94%;
}
.filterListSearch ul li span.icon,
.filterListSearch span.icon1 img {
  border: 0.75px solid #10182869;
  border-radius: 4px;
  background: linear-gradient(180deg, #f9fafb 0%, #edf0f3 100%);
  width: 32px;
  height: 32px;
  padding: 6.86px;
}
.clstep01 ul li a span {
  border-radius: 4px;
  background: linear-gradient(180deg, #f9fafb 0%, #edf0f3 100%);
  border: 0.75px solid #1018280c;
}
.clstep01 ul li:last-child a span {
  border-radius: 19px;
}
.filterListSearch ul li span.email {
  color: #98a2b3;
}
.NewQuoteModal {
  padding: 0 !important;
}
.iconinnerStyle1 {
  border-radius: 28px;
  border: 6px solid var(--Error-50, #fef3f2);
  background: var(--Error-100, #fee4e2);
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 11px;
}
.footerButton button.save {
  border-radius: 4px;
  background: #9e77ed;
  padding: 10px 18px;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  border: 1px solid #9e77ed;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter";
  text-transform: initial;
}
.bsdScope .css-b62m3t-container {
  width: 100%;
}
.bsdScope .css-13cymwt-control {
  border: 0;
  padding: 0;
}
/* Calclution */
.calMainWrap .textMidde h2 {
  color: var(--Gray-800, #1d2939);
  text-align: center;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.33px;
}
.calMainWrap .newQuoteBack button {
  border: 0;
  background: transparent;
  padding: 0;
}
.calMainWrap .newQuoteBack {
  position: initial;
  left: auto;
}
.calMainWrap .newQuoteBack button a {
  color: var(--Gray-800, #1d2939);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  border-radius: 4px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.calMainWrap .mainHead {
  padding: 16px 32px;
  background: #fff;
}
/* .calMainWrap {
    height: calc(100vh - 1px);
    overflow: auto;
} */
.calScrollSec {
  padding: 28px 32px;
  background: #d2d6df;
  overflow: auto;
  height: calc(100vh - 361px);
}
.boxGreyBox {
  border-radius: var(--radius-xs, 4px);
  background: #fff;
  padding: 24px;
}
.calStyleBox01 h3 {
  color: var(--Gray-700, #344054);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.08px;
  margin: 0;
  display: flex;
  align-items: center;
}
.calStyleBox01 p {
  color: var(--Gray-600, #475467);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.08px;
}
.calStyleBox01 h5 {
  color: var(--Gray-500, #667085);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.175px;
  margin: 0;
}
.calStyleBox01 ul li {
  color: var(--Gray-800, #1d2939);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.08px;
}
.calStyleBox01 button {
  color: #158ecc;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  border: 0;
  background: transparent;
  padding: 0;
}
.quoteTableadd tr td {
  border: 0;
  background: transparent !important;
  padding: 14px 16px !important;
  color: var(--Gray-400, #98a2b3);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.quoteTableadd tr th {
  border: 0;
  background: transparent !important;
  padding: 14px 16px !important;
  color: #475467;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 183.333% */
  letter-spacing: 0.12px;
}
.addItemRow {
  border-radius: 4px;
  border: 1px solid var(--Primary-200, #e9d7fe);
  background: var(--Gray-50, #f9fafb);
  color: var(--Primary-600, #7f56d9);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  padding: 8px 14px;
  margin: 23px auto 0;
  text-align: center;
  width: 117px;
  display: block;
}
.quoteInvoice .inputInfo1 textarea {
  width: 100%;
  min-height: 320px;
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  padding: 12px 14px;
  color: var(--Gray-900, #101828);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.quoteInvoice .custom-radio-button {
  display: block;
  margin-left: 0;
}
.quoteInvoice .custom-radio-button {
  text-align: left;
  margin-bottom: 21px;
}
.toggle-switch-wrap em {
  color: var(--Gray-700, #344054);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.175px;
  margin-left: 12px;
}
.toggle-switch-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.quoteInvoice .custom-radio-button span {
  color: var(--Gray-600, #475467);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.quoteInvoice .radio-buttons {
  display: block;
}

.quoteInvoice .custom-radio-button b {
  display: block;
  color: var(--Gray-700, #344054);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.quoteInvoice .formgroup .inputInfo {
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Border, #d0d5dd);
  background: var(--Base-White, #fff);
  padding: 10px 14px;
  color: var(--Gray-400, #98a2b3);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.08px;
}
.quoteInvoice .dflexFilds {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dflexFilds .css-z3c6am-MuiFormControl-root-MuiTextField-root {
  width: 100%;
}
.quoteInvoice .dflexFilds span {
  color: var(--Gray-600, #475467);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.14px;
}
.quoteInvoice .formgroup .inputInfo input {
  color: var(--Gray-400, #98a2b3);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.08px;
}
.quoteInvoice .formgroup .inputInfo .MuiOutlinedInput-root {
  color: var(--Gray-400, #98a2b3);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.08px;
}
/* Toggle Switch */
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
}
.toggle-switch input[type="checkbox"] {
  display: none;
}
.toggle-switch .switch {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
  border-radius: var(--radius-xl, 12px);
  background: var(--Gray-100, #f2f4f7);
}
.toggle-switch .switch::before {
  position: absolute;
  content: "";
  left: 2px;
  top: 2px;
  width: 20px;
  height: 20px;
  background-color: #aaa;
  border-radius: 50%;
  transition: transform 0.3s ease;
  border-radius: var(--radius-full, 9999px);
  background: var(--Base-White, #fff);
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.06);
}
table.department-calculation tbody tr:hover td:first-child svg {
  fill: #1ab2ff;
}
.calMainWrap table tbody tr td {
  padding: 4.8px 10px !important;
}
.calMainWrap table thead tr th {
  padding: 11px 10px !important;
}
/* .calMainWrap .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
    padding: 0;
} */
.toggle-switch input[type="checkbox"]:checked + .switch::before {
  transform: translateX(20px);
  background: var(--Base-White, #1ab2ff);

  /* Shadows/shadow-sm */
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.06);
}
.toggle-switch input[type="checkbox"]:checked + .switch {
  background-color: #ebf8ff;
}
/* Toggle Switch */
.footerCalAction ul li {
  display: inline-block;
}

.rowWrapTop {
  background: var(--Gray-25, #fcfcfd);
  padding: 8px 24px;
}
.footerCalAction {
  border-top: 1px solid var(--Gray-200, #eaecf0);
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.06);
}
.rowWrapTop ul li span {
  color: var(--Gray-500, #667085);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.08px;
  padding-bottom: 4px;
  display: block;
}
.rowWrapTop ul li p {
  color: var(--Gray-600, #475467);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.18px;
}
.rowWrapTop ul.left li {
  margin-right: 96px;
}
.rowWrapTop ul.right li {
  margin-left: 96px;
  text-align: left;
}
.rowWrapTop ul.right li.total p {
  color: var(--Gray-600, #475467);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.18px;
}
.rowWrapTop ul.left li.budgetColor p {
  color: var(--Success-600, #079455);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.18px;
}
.footerCalAction ul li.cancel button {
  color: var(--Error-700, #b42318);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  border: 0;
  background: transparent;
  margin-right: 20px;
}
.footerCalAction ul li.invoicePDF a {
  color: var(--Primary-700, #106b99);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.calMainWrap table thead tr th:first-child,
.calMainWrap table tbody tr td:first-child {
  padding: 0px 0px 0 7px !important;
  text-align: center;
}
.CreateProposalCal .linkByttonStyle {
  color: var(--Primary-700, #106b99);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-left: 20px;
  background: transparent !important;
  text-transform: capitalize;
}
.footerCalAction ul.right li.SaveDraftBut button {
  color: var(--Primary-700, #106b99);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-right: 18px;
  border: 0;
  background: transparent;
}
.footerCalAction ul.right li.SaveButtonCal button {
  padding: 10px 18px;
  color: var(--Primary-700, #106b99);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-right: 18px;
  border-radius: 4px;
  border: 1px solid var(--Primary-200, #a3e0ff);
  background: var(--Primary-50, #ebf8ff);
}
.footerCalAction ul.right li.SaveandSendBut button {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding: 10px 18px;
  border-radius: 4px;
  border: 1px solid var(--Primary-600, #158ecc);
  background: var(--Primary-600, #158ecc);
}
.calActionBottom {
  padding: 8px 21px;
  display: flex;
  align-items: center;
}
.calScrollSec .TooltipWrapper button {
  background: transparent;
  border: 0;
  padding: 0;
  position: initial;
  right: 0;
  top: 0;
  margin-left: 6px;
}
.QuoteToborder {
  text-align: left !important;
  border-left: 1px solid #f2f4f7;
  padding-left: 23px;
}
.calStyleBox01 ul li input,
.calStyleBox01 ul li .inputInfo {
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Border, #d0d5dd);
  background: var(--Base-White, #fff);
  padding: 10px 14px;
  margin: 8px 0;
  color: var(--Gray-900, #101828);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.08px;
  width: 100%;
}
.quoteInvoice .css-b62m3t-container {
  position: relative;
  box-sizing: border-box;
  width: 100%;
}
.quoteInvoice .css-1p3m7a8-multiValue {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 0;
  background-color: hsl(0, 0%, 90%);
  border-radius: 2px;
  margin: 2px;
  box-sizing: border-box;
  border-radius: var(--radius-sm, 6px);
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  padding: 2px 5px;
}
.quoteInvoice .css-wsp0cs-MultiValueGeneric {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  color: hsl(0, 0%, 20%);
  font-size: 85%;
  padding: 3px;
  padding-left: 6px;
  box-sizing: border-box;
  color: var(--Gray-700, #344054);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.quoteInvoice .css-tj5bde-Svg {
  fill: #98a2b3;
}
.updateButtonGeneral .CancelActionBut button {
  color: var(--Error-700, #b42318);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  background: transparent;
  border: 0;
}
.formgroupSelect .inputInfo {
  padding: 6px 15px !important;
}
.quoteInvoice .css-12a83d4-MultiValueRemove:hover {
  background: transparent;
}
.quoteInvoice .css-13cymwt-control {
  border-width: 0px !important;
}
.alertBoxFlex {
  display: flex;
  align-items: self-start;
  text-align: left;
}
.alertBoxFlex .alertIcon {
  margin-right: 15px;
}
.alertBoxFlex .righttext h5 {
  color: var(--Gray-700, #344054);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.alertBoxFlex .righttext p {
  color: var(--Gray-600, #475467);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.alert-alertBoxWrap {
  border-radius: 4px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  padding: 16px;
}
.phoneInputBoxStyle .inputInfo {
  padding: 6px 13px;
}
.phoneInputBoxStyle
  .inputInfo
  button.react-international-phone-country-selector-button {
  border: 0;
}
.phoneInputBoxStyle .inputInfo input.react-international-phone-input {
  border: 0;
}

ul.indexCalname td .menu-item {
  position: relative;
  cursor: pointer;
}
ul.indexCalname td {
  text-align: left;
  position: relative;
}
ul.subindexCalname {
  position: absolute;
  top: 24px;
  border-radius: var(--radius-md, 8px) 0px 0px var(--radius-md, 8px);
  border: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Base-White, #fff);
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  width: 233px;
  padding: 4px 8px;
}
ul.indexCalname {
  display: flex;
  align-items: center;
  border-radius: var(--radius-xs, 4px);
  background: var(--Base-White, #fff);
  justify-content: space-between;
  width: 160px;
}
ul.subindexCalname td {
  color: var(--Gray-900, #101828);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  padding: 10px 10px 10px var(--spacing-md, 8px);
  border-radius: 6px;
  background: #fff;
  margin: 5px 0;
  cursor: pointer;
}

ul.subindexCalname td:hover {
  border-radius: var(--radius-sm, 6px);
  background: var(--Primary-50, #f9f5ff);
  color: var(--Primary-600, #7f56d9);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
/* .menu-item-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
table.indexCalname tbody tr td {
    vertical-align: top;
    background: transparent;
    border: 0;
    padding-top: 20px !important;
}
table.indexCalname thead tr th{
    background: transparent;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom:1px solid #ccc ;
    
} */
/* table.indexCalname {
    width: 100%;
}
table.indexCalname input {
    width: 62px;
    background: transparent;
    pointer-events: none;
}
table.indexCalname input:focus {
    width: 62px;
    background: transparent;
    pointer-events: visible;
}
table.indexCalname select {
    background: transparent;
    border: 0;
    color: var(--Gray-900, #101828);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
table.indexCalname tbody tr td div:last-child {
    margin-bottom: 0;
}
table.indexCalname textarea {
    border-radius: var(--radius-xs, 4px);
    border: 1px solid var(--Primary-300, #D6BBFB);
    background: var(--Base-White, #FFF);
    padding: 12px;
    width: 100%;
    min-height: 120px;
}
table.indexCalname tbody tr td div {
    margin-bottom: 25px;
}
table.indexCalname tbody tr td:nth-child(2) {
    width: 192px;
} */
/* table.indexCalname tbody tr td:nth-child(3) {
    width: 409px;
} */
table.indexCalname tbody tr td {
  color: var(--Gray-900, #101828);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
/* table.indexCalname tbody tr td:nth-child(4) {
    width:140px;
}
table.indexCalname tbody tr td:nth-child(5) {
    width:114px;
}
table.indexCalname tbody tr td:nth-child(5) {
    width:151px;
}
table.indexCalname tbody tr td:nth-child(6) {
    width: 201px;
}
table.indexCalname tbody tr td:nth-child(7) {
    width: 110px;
}
table.indexCalname tbody tr td:nth-child(8) {
    width: 110px;
}
table.indexCalname tbody tr td:nth-child(9) {
    width: 64px;
} */
/* Management.css */

.dropdown-toggle {
  background-color: #28a745;
  color: white;
}

.dropdown-toggle:hover {
  background-color: #218838;
}

.dropdown-menu {
  background-color: #f8f9fa;
  border: 1px solid #ddd;
}

.dropdown-item {
  color: #333;
}

.dropdown-item:hover {
  background-color: #e9ecef;
  color: #000;
}

.nav-dropdown {
  position: relative;
}

.nav-dropdown:hover .dropdown-menu {
  display: block;
}

.nav-dropdown .dropdown-menu {
  display: none;
  position: absolute;
  left: 100%;
  top: 0;
  margin-left: 0.1rem;
  background-color: #f8f9fa;
}

.nav-dropdown .dropdown-item {
  color: #333;
}

.nav-dropdown .dropdown-item:hover {
  background-color: #e9ecef;
  color: #000;
}

.customDropDown button {
  background: #ccc;
  padding: 3px 8px;
  border-radius: 4px;
  color: #000;
}
.customDropDown {
  position: relative;
  text-align: left;
}
.customDropDown ul {
  display: none;
}

.customDropDown:hover ul.mainMenuData {
  position: absolute;
  display: block;
  background: #ccc;
  padding: 15px;
  cursor: pointer;
}
.customDropDown ul.mainMenuData li:hover ul.subMenudata {
  position: absolute;
  display: block;
  background: #ccc;
  padding: 15px;
  left: 75px;
  margin-top: -18px;
  cursor: pointer;
}
.DepartmentQuote input[type="text"] {
  color: var(--Gray-900, #101828);

  /* Text md/Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin: 0 2px;
}
.DepartmentQuote input[type="text"]:focus {
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Primary-300, #76d1ff);
  background: var(--Base-White, #fff);
}

.DepartmentQuote input[type="text"]::placeholder,
.DepartmentQuote textarea::placeholder {
  font-size: 14px !important;
  color: #98a2b3;
}

.DepartmentQuote textarea:focus {
  border-radius: var(--radius-xs, 4px) !important;
  border: 1px solid var(--Primary-300, #76d1ff) !important;
  background: var(--Base-White, #fff) !important;
  min-height: 144px;
  overflow: auto;
  padding: 12px 16px;
}
.DepartmentQuote textarea {
  width: 100%;
  overflow: hidden;
  color: var(--Gray-900, #101828);

  /* Text md/Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.DepartmentQuote textarea:focus::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  padding: 5px;
}
.DepartmentQuote textarea:focus::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px #fff;
  border-radius: 10px;
}
.DepartmentQuote textarea:focus::-webkit-scrollbar-thumb {
  background: #eaecf0;
  border-radius: 10px;
}
.DepartmentQuote textarea:focus::-webkit-scrollbar-thumb:hover {
  background: #d9d9da;
}

/* Create Proposal */
.creteProposalModel .modal-dialog {
  max-width: 878px;
}
.creteProposalModel .modal-content {
  width: 100%;
}
.cancelProposalBut {
  color: var(--Error-700, #b42318) !important;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  background: transparent !important;
  border: 0 !important;
  text-align: left;
  padding: 0;
}
.popoverbottom .rightBut .addNewBut {
  border-radius: 4px;
  border: 1px solid var(--Primary-200, #a3e0ff);
  background: var(--Primary-50, #ebf8ff);
  padding: 10px 18px;
  color: var(--Primary-700, #106b99);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.popoverbottom .rightBut .createAndSave {
  border-radius: 4px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: #fff;
  padding: 10px 18px;
  color: var(--Gray-700, #344054);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin: 0 9px;
}
.popoverbottom .rightBut .sendProposalBut {
  border-radius: 4px;
  border: 1px solid var(--Primary-600, #158ecc);
  background: var(--Primary-600, #158ecc);
  padding: 10px 18px;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.modelInputSelectStyle input,
.modelInputSelectStyle .inputInfo {
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Border, #d0d5dd);
  background: var(--Base-White, #fff);
  padding: 10px 14px;
  margin: 8px 0;
  color: var(--Gray-900, #101828);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.08px;
  width: 100%;
}
.borderSkyColor .navStepClient ul li span {
  border-radius: 4px;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Base-White, #fff);
}

.no-search h1 {
  color: var(--Gray-900, #101828);
  text-align: center;

  /* Title Screen */
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px; /* 113.333% */
  letter-spacing: -0.75px;
}

.no-search p {
  color: var(--Gray-600, #475467);
  text-align: center;

  /* Title body */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.18px;
}

ul.client li {
  padding: 12px;
  text-align: left;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

ul.client li:hover {
  background: #eaf8ff;
}

ul.client li .name {
  color: var(--Gray-800, #1d2939);

  /* Body default */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  letter-spacing: 0.14px;
  margin-left: 8px;
}

ul.client {
  overflow: auto;
  height: calc(100% - 100px);
}

.custom-select-country {
  padding: 3.5px 0px;
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Border, #d0d5dd);
  background: var(--Base-White, #fff);
}

.custom-select-country.error-border {
  border: 1px solid var(--Border, red) !important;
}

.custom-select-country span {
  background-color: white !important;
}

.custom-select-country #react-select-7-listbox {
  max-height: 200px !important;
}

.custom-select-country div {
  border: none !important;
}

.phoneInputBoxStyle .inputInfo input.react-international-phone-input {
  width: 270px;
}

.individual.height {
  width: calc(100% + 60px);
  overflow: auto;
  height: calc(100% - 85px);
  padding: 0px 30px;
}

.individual.bottomBox {
  width: 100%;
  height: 76px;
  position: fixed;
  bottom: 0px;
  left: 0px;
  border-top: 1px solid var(--Gray-200, #eaecf0);
  background: #fff;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 12px;
  padding: 16px 24px;
}

.individual.bottomBox .submit-button,
.calculation-quote-bottom .submit-button {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 40px;
  border: 1px solid var(--Primary-600, #158ecc);
  background: var(--Primary-600, #158ecc);
}

.individual.bottomBox .cancel-button {
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--Gray-700, #344054);

  /* Text md/Semibold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  border-radius: 40px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: #fff;
}

.individual .inputInfo textarea {
  width: 100%;
  min-height: 260px;
  background: var(--Base-White, #fff);
  color: var(--Gray-900, #101828);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  resize: none;
}

.individual .inputInfo textarea::placeholder {
  color: #98a2b3;
}

.custom-upload-btn {
  color: var(--Gray-800, #1d2939);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  cursor: pointer;
}

.uploaded-files ul li {
  display: flex;
  gap: 8px;
  padding: 12px 14px;
  align-items: center;
}

.delete-button-client,
.delete-button-client:hover {
  color: var(--Error-700, #b42318);

  /* Text sm/Semibold */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  display: flex;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid var(--Error-300, #fda29b);
  background: var(--Base-White, #fff);
}

.add-button-client,
.add-button-client:hover,
.back-button,
.back-button:hover {
  color: var(--Gray-700, #344054);

  /* Text sm/Semibold */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  display: flex;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 40px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
}

.formgroupboxs .inputInfo > *,
.formgroupboxs .inputInfo .MuiInputBase-root.MuiOutlinedInput-root > * {
  color: #101828 !important;
}

.formgroupboxs .inputInfo > *::placeholder {
  color: #667085 !important;
}

.button-custom, .button-custom:hover, .button-custom:focus  {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
}

.calculation-quote-bottom .text-button,
.calculation-quote-bottom .text-button:hover {
  color: #106b99;
  border: none;
  background-color: transparent;
}

.calculation-quote-bottom .submit-button-light,
.calculation-quote-bottom .submit-button-light:hover {
  color: #106b99 !important;
  border-radius: 40px;
  border: 1px solid var(--Primary-200, #a3e0ff);
  background: var(--Primary-50, #ebf8ff);
}

.calculation-quote-bottom .item {
  text-align: left;
  min-width: 158px;
}

.calculation-quote-bottom .item label {
  color: var(--Gray-500, #667085);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.08px;
}

.calculation-quote-bottom .item .amount {
  color: var(--Gray-600, #475467);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.18px;
}

.text-button.padding-left-0 {
  padding-left: 0px !important;
}

.disabled-no-pointer {
  cursor: not-allowed !important;
  opacity: 0.6 !important;
}

.btn.text-button {
  font-size: 14px;
  color: #158ecc;
}

.mergeForm .modal-content {
  border-radius: 10px;
}