.footerwrap {
    display: flex;
    align-items: center;
    justify-content: end;
    border-top: 1px solid var(--Gray-200, #EAECF0);
    padding: 16px 24px 24px;
    .cancelbut {
      border-radius: var(--radius-xs, 4px);
      border: 1px solid var(--Gray-300, #D0D5DD);
      background: #FFF;
      padding: var(--spacing-md, 8px) 14px;
      color: var(--Gray-800, #1D2939);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      margin-right: 12px;
  }
    .editbut{
      border-radius: var(--radius-xs, 4px);
      border: 1px solid var(--Primary-600, #158ECC);
      background: var(--Primary-600, #158ECC);
      padding: var(--spacing-md, 8px) 14px;
      color: #FFF;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
  }
  
  .padding_24 {
    padding: 24px 24px 16px;
}
  .paddingLR_24 {
    padding: 0 24px 24px;
}
