.filterBox {
  display: flex;
  padding: var(--spacing-sm, 2px) var(--spacing-md, 2px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-md, 8px);
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: #fff;
  height: 32px;
  width: 32px;
  border-radius: 50%;
}

.newButton,
.newButton:hover,
.newButton:focus {
  gap: 8px;
  display: flex;
  padding: 4px 14px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--Orange-600, #d59346);
  background: var(--Orange-600, #d59346);
}


.orderImg {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border: 0.75px solid #ccc;
  margin-right: 10px;
  background: linear-gradient(180deg, #f9fafb 0%, #edf0f3 100%);
}
.inProgress {
  margin: auto;
  width: fit-content;
  color: var(--Warning-700, #b54708);
  text-align: center;
  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  display: flex;
  padding: var(--spacing-xxs, 2px) var(--spacing-md, 8px);
  align-items: center;
  border-radius: 16px;
  border: 1px solid var(--Warning-200, #fedf89);
  background: var(--Warning-50, #fffaeb);
}
.complete {
  margin: auto;
  width: fit-content;
  color: var(--Success-700, #067647);
  text-align: center;

  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  display: flex;
  padding: var(--spacing-xxs, 2px) var(--spacing-md, 8px);
  align-items: center;
  border-radius: 16px;
  border: 1px solid var(--Success-200, #a9efc5);
  background: var(--Success-50, #ecfdf3);
}
.lost {
  margin: auto;
  width: fit-content;
  color: var(--Error-700, #b42318);
  text-align: center;

  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  display: flex;
  padding: var(--spacing-xxs, 2px) var(--spacing-md, 8px);
  align-items: center;
  border-radius: 16px;
  border: 1px solid var(--Error-200, #fecdca);
  background: var(--Error-50, #fef3f2);
}
.completeProfit {
  margin-left: auto;
  width: fit-content;
  color: var(--Success-800, #085d3a);
  text-align: center;

  display: flex;
  padding: var(--spacing-xxs, 2px) var(--spacing-sm, 6px);
  align-items: center;
  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Success-300, #75e0a7);
  background: var(--Success-100, #dcfae6);
}

.lostProfit {
  margin-left: auto;
  width: fit-content;
  color: var(--Error-800, #912018);
  text-align: center;

  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  display: flex;
  padding: var(--spacing-xxs, 2px) var(--spacing-sm, 6px);
  align-items: center;
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Error-300, #fda29b);
  background: var(--Error-100, #fee4e2);
}

.piCircleStyle span{
  border-radius: 16px;
  mix-blend-mode: multiply;
  padding: var(--spacing-xxs, 2px) var(--spacing-md, 8px);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin-left: 10px;

}
.RealCostCircleStyle span{
border: 1px solid #A4E3E1;
background: #EDFFFE;
color: #0D8A86;
}
.labourCostCircleStyle span{
  border: 1px solid var(--Error-200, #FECDCA);
  background: var(--Error-50, #FEF3F2);
  color: var(--Error-700, #B42318);
}
.saleCircleStyle span{
  border: 1px solid var(--Error-200, #FECDCA);
  background: var(--Error-50, #FEF3F2);
  color: var(--Error-700, #B42318);
}
.operCircleStyle span{
  border: 1px solid var(--Primary-200, #A3E0FF);
  background: var(--Primary-50, #EBF8FF);
  color: var(--Primary-700, #106B99);
}
.profitdata{
  border-radius: 16px;
border: 1px solid var(--Warning-200, #FEDF89);
background: var(--Warning-50, #FFFAEB);
mix-blend-mode: multiply;
color: var(--Warning-700, #B54708);
text-align: center;

/* Text xs/Medium */
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 18px; /* 150% */
padding: var(--spacing-xxs, 2px) var(--spacing-md, 8px) var(--spacing-xxs, 2px) var(--spacing-sm, 6px);
}

.inProgressProfit{
  border-radius: 30px;
border: 1px solid var(--Success-200, #ABEFC6);
background: var(--Success-50, #ECFDF3);
mix-blend-mode: multiply;
padding: var(--spacing-xxs, 2px) var(--spacing-md, 8px) var(--spacing-xxs, 2px) var(--spacing-sm, 6px);
color: var(--Success-700, #067647);
text-align: center;

/* Text xs/Medium */
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 18px; /* 150% */

}
.completeProfit{
  border-radius: 30px;
border: 1px solid var(--Warning-200, #FEDF89);
background: var(--Warning-50, #FFFAEB);
mix-blend-mode: multiply;
padding: var(--spacing-xxs, 2px) var(--spacing-md, 8px) var(--spacing-xxs, 2px) var(--spacing-sm, 6px);
color: var(--Warning-700, #B54708);
text-align: center;

/* Text xs/Medium */
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 18px; /* 150% */

}
.lostProfit{
  border-radius: 30px;
  border: 1px solid var(--Error-200, #FECDCA);
  background: var(--Error-50, #FEF3F2);
  mix-blend-mode: multiply;
padding: var(--spacing-xxs, 2px) var(--spacing-md, 8px) var(--spacing-xxs, 2px) var(--spacing-sm, 6px);
color: var(--Warning-700, #B42318);
text-align: center;

/* Text xs/Medium */
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 18px; /* 150% */

}
.statusComplete{
  border-radius: 16px;
  border: 1px solid var(--Success-200, #A9EFC5);
  background: var(--Success-50, #ECFDF3);
  mix-blend-mode: multiply;
  padding: var(--spacing-xxs, 2px) var(--spacing-md, 8px) var(--spacing-xxs, 2px) var(--spacing-sm, 6px);
  color: var(--Success-700, #067647);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; 

}
.modal {
  width: 400px;
  border-radius: var(--radius-xs, 4px);
  background: var(--Base-White, #fff);
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
   
}
.exploreModel{
  h2{
    color: var(--Gray-900, #101828);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; 
  }
  ul{
    li{
      border-radius: var(--radius-xs, 4px);
      border: 1px solid var(--Gray-100, #F2F4F7);
      padding: var(--spacing-md, 8px);
      h3{
        color: var(--Gray-600, #475467);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; 
        letter-spacing: -0.08px;
        strong{
          color: var(--Gray-700, #344054);
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0.08px;
          display: block;
        }
      }
    }
  }
}