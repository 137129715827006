.wraper {
  height: calc(100vh - 207px) !important;
}

.topHeadBorder {
  border-bottom: 1px solid #eaecf0;
  margin-bottom: 24px;
}

.p-highlight {
  border-bottom: 0 !important;
}

.innerExpandIcon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f4f7;
  border-radius: 22px;
  margin-right: 12px;
}

.innerCollapseIcon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #bae8ff;
  border-radius: 22px;
  margin-right: 12px;
}

.accorHeadbox {
  border-radius: var(--radius-xs, 4px);
  border: 1px solid #fff;
  background: #fff;
  .p-icon {
    display: none;
  }

  .hoverToShow {
    opacity: 0;
    margin-right: 20px;
  }
  .downBoxStyle {
    border-radius: 22px;
    background: var(--Gray-100, #f2f4f7);
    padding: var(--spacing-xs, 4px);
  }
  &:hover {
    border-radius: var(--radius-xs, 4px);
    border: 1px solid var(--Primary-200, #a3e0ff);
    background: var(--Primary-25, #f2faff);
    .hoverToShow {
      opacity: 1;
      fill: #1ab2ff;
    }
  }
  .iconSpace {
    margin: 0 8px;
  }
  .creaeDepartment {
    color: #475467;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    background: none;
    border: 0;
    padding: 0;
    &:hover {
      color: #475467;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      background: none;
      border: 0;
      padding: 0;
    }
  }

  a {
    border-radius: var(--radius-xs, 4px);
    border: 1px solid #eaecf0;
    background: transparent;
    padding: var(--spacing-xl, 16px);
    border: 0;
  }
  .accorHeadStyle {
    color: var(--Primary-700, #344054);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }

  .accorInHeadStyle {
    color: var(--Gray-700, #344054);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.18px;
  }
  .innerBoxStyle {
    border-bottom: 1px solid #eaecf0;
    a {
      background: var(--Gray-50, #f9fafb);
      padding: var(--spacing-xl, 16px);
      border: 0;
      color: var(--Gray-700, #344054);
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.18px;
    }
    .calculateBox {
      background: #f9fafb;
      padding-top: 1px;
      .boxcal {
        background: var(--Gray-100, #f2f4f7);
        padding: 19px 56px;
      }
      .profit {
        background: var(--Success-50, #ecfdf3);

        h6 {
          color: #085d3a;
        }
        strong {
          color: var(--Success-800, #085d3a);
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0.08px;
        }
      }

      ul {
        display: flex;
        justify-content: space-between;

        background: #f9fafb;

        li {
          display: flex;
          justify-content: flex-start;

          h6 {
            color: var(--Gray-700, #344054);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0.14px;
            margin-bottom: 4px;
          }
          strong {
            color: var(--Gray-800, #1d2939);
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.08px;
            margin: 0;
          }
        }
      }
    }
  }

  .RItem {
    display: flex;
    align-items: center;
    .delete {
      color: #b42318;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 142.857% */
      padding: var(--spacing-md, 8px) 14px;
      background: transparent;
      border: 0;
      display: flex;
      align-items: center;
      &:hover {
        color: #b42318;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
        padding: var(--spacing-md, 8px) 14px;
        background: transparent;
        border: 0;
        display: flex;
        align-items: center;
      }
    }
    .create {
      color: #106b99;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 142.857% */
      padding: var(--spacing-md, 8px) 14px;
      background: transparent;
      border: 0;
      display: flex;
      align-items: center;
      &:hover {
        color: #106b99;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
        padding: var(--spacing-md, 8px) 14px;
        background: transparent;
        border: 0;
        display: flex;
        align-items: center;
      }
    }
    .editBut {
      color: #1d2939;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 142.857% */
      display: flex;
      align-items: center;
      border-radius: var(--radius-xs, 4px);
      border: 1px solid var(--Gray-300, #d0d5dd);
      background: #fff;
      &:hover {
        color: #106b99;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
        border-radius: var(--radius-xs, 4px);
        border: 1px solid var(--Gray-300, #d0d5dd);
        background: #fff;

        display: flex;
        align-items: center;
      }
    }
  }

  .contentStyle {
    padding: 16px 24px 16px 52px;
    border-top: 0px solid #e5e7eb;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.08px;
    background: #f9fafb;
    border-bottom: 1px solid #e5e7eb;
    label {
      color: var(--Gray-500, #667085);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.14px;
      margin-bottom: 0px;
      display: block;
    }

    strong {
      padding: 0;
      margin: 0;
      color: var(--Gray-600, #475467);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.08px;
    }
  }
}

.modelStyleBoxstatus {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  background: var(--Base-White, #fff);
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
    0px 20px 24px -4px rgba(16, 24, 40, 0.08);
  padding: 0px;
}
.iconborderStyle {
  border-radius: 28px;
  border: 8px solid var(--Success-50, #ecfdf3);
  background: var(--Success-100, #dcfae6);
  padding: 5px;
  display: flex;
  align-items: center;
  margin-right: 9px;
}
.stepBoxStylePayment {
  background: var(--Gray-25, #fcfcfd);
  padding: 16px;
  .inputInfo {
    border-radius: var(--radius-xs, 4px);
    border: 1px solid var(--Border, #d0d5dd);
    background: #fff;
    padding: 8px 14px;
    color: var(--Gray-900, #101828);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.08px;
  }
}

.modal {
  width: 500px;
  border-radius: var(--radius-xs, 4px);
  background: var(--Base-White, #fff);
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
}

.modalHeader {
  gap: 16px;
  display: flex;
  align-items: center;
}

.headerTitle {
  font-size: 18px;
  font-weight: 600;
  color: var(--Gray-700, #344054);
}

.circledesignstyle {
  .out {
    border-radius: 50%;
    width: 48px;
    height: 48px;
    border: 6px solid var(--Success-50, #ecfdf3);
    background: var(--Success-100, #dcfae6);
    padding: 6px;
  }
}

.inputBox,
.inputBox:focus {
  color: var(--Gray-900, #101828);

  /* Body large */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.08px;
  display: flex;
  padding: 10px 14px;
  align-items: center;
  gap: var(--spacing-md, 8px);
  align-self: stretch;
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Border, #d0d5dd);
  background: #fff;
  box-shadow: none !important;
  outline: none !important;
}

.editIcon {
  padding: 0px;
  width: 24px;
  height: 24px;
  border-radius: 22px;
  border: 1px solid #f2f4f7;
  background: var(--Gray-100, #f2f4f7);
}

.deleteIcon {
  width: 24px;
  height: 24px;
  padding: 0;
  border-radius: 22px;
  border: 1px solid #fee4e2;
  background: var(--Error-100, #fee4e2);
}

.inputNumber input {
  width: 60% !important;
  background: transparent;
  padding: 10px 4px 10px 2px;
  box-shadow: none !important;
  outline: none !important;
}

.inputNumber2 input {
  text-align: end;
  width: 60px !important;
  background: transparent;
  padding: 10px 4px 10px 2px;
  box-shadow: none !important;
  outline: none !important;
}

.bottom {
  right: 0px;
  width: calc(100% - 301px);
  bottom: 0px;
  height: 76px;
  z-index: 100000;
  position: fixed;
  padding: 16px 24px;
  background: #fff;
  border-top: 1px solid var(--Gray-200, #eaecf0);
}

.editIconBox {
  width: 28px;
  height: 28px;
  display: flex;
  padding: var(--spacing-sm, 6px);
  align-items: center;
  gap: var(--spacing-md, 8px);
  border-radius: 300px;
  background: var(--Primary-100, #bae8ff);
  margin-left: 12px;
  margin-right: 12px;
}

.editIconBox2 {
  width: 28px;
  height: 28px;
  display: flex;
  padding: var(--spacing-sm, 6px);
  align-items: center;
  gap: var(--spacing-md, 8px);
  border-radius: 300px;
  margin-left: 12px;
  margin-right: 12px;
}
.edidcodtUpdate {
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: #fff;
  padding: 12px 12px;
  margin: 0 0;
}
