.activelist {
    color: var(--Primary-600, #158ECC);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.08px;
}

.viewBorderWrap {
    border-top: 1px solid #EAECF0;
}
.editBorderWrap {
    .editinfo{
        border-top: 1px solid #EAECF0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-right: 56px;
        padding: 32px 0;
        display: grid;
        grid-template-columns: 361px 1fr 1fr;
        align-items: center;
    }
}

.editpara{
    p {
        margin: 0;
    width: 320px;
    }
}

.viewBorderWrap{
.editinfo {
    display: grid;
    grid-template-columns: 361px 1fr 1fr;
    align-items: center;
    padding: 32px 0;
}
}


.listwrapp {
    ul {
        li {
            display: grid;
            grid-template-columns: 361px 1fr 1fr;
            padding: 20px 0;
            align-items: center;
            border-top: 1px solid #EAECF0;
        }
    }
}