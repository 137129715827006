@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  overflow: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Inter", sans-serif;
}
.main-wrapper {
  text-align: center;
}

a {
  text-decoration: none;
}

.headerNav .middle {
  text-align: center;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 0px 0px #23232329;
}
ul {
  margin: 0;
  padding: 0;
}

.mr {
  margin-right: 12px;
}

table thead tr th {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.12px;
  padding: 11px 16px !important;
  text-align: left;
  vertical-align: middle;
  color: #475467 !important;
}
table:not(.p-datatable-table):not(.custom-table):not(.p-datepicker-calendar)
  tbody
  tr
  td {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.14px;
  vertical-align: middle;
  padding: 4.8px 15px !important;
}
table tbody tr:nth-child(odd) td {
  background: #f9fafb;
}

table tbody tr:nth-child(even) td {
  background: #ffffff;
}

table tbody tr:hover td {
  background: #ebf8ff;
}
.filterSearch:focus-visible,
.filterSearch input {
  border: 0 !important;
  outline: 0;
}
:focus-visible,
input {
  border: 0;
  outline: 0;
}
.ContactModelIn textarea::placeholder {
  color: #98a2b3;
  opacity: 1;
}

.ContactModelIn textarea::-ms-input-placeholder {
  color: #98a2b3;
}
.modal-backdrop {
  background-color: #1018281a !important;
}
.flexEndbox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.innerTableStyle tr td {
  border: 0;
}
table.innerTableStyle tr,
table.innerTableStyle {
  text-align: center;
  width: 100%;
}
.flexcenterbox {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tooltipDefaultStyle,
.tooltipDefaultStyle:hover,
.tooltipDefaultStyle:focus {
  background: initial;
  border: initial;
  color: initial;
  text-transform: inherit;
  font-size: inherit;
  color: var(--Gray-600, #475467);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.iconOutStyle {
  width: 48px;
  background: #ecfdf3;
  height: 48px;
  padding: 4px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 11px;
}

.iconinStyle {
  width: 32px;
  background: #dcfae6;
  height: 32px;
  /* padding: 4px; */
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.iconinnerStyle {
  border-radius: 16px;
  border: 1px solid #ecfdf3;
  background: #dcfae6;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
}
.modelStyle .modal-header span,
.popoverHead span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.modelStyle .modal-header span,
.popoverHead span {
  color: #344054;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.33px;
}
.savebox,
.popoverbottom .savebox {
  border-radius: 40px;
  background: #158ecc;
  padding: 10px 18px;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  border: 1px solid #158ecc;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
}

.savebox:hover,
.popoverbottom .savebox:hover {
  border-radius: 40px;
  background: #158ecc;
  padding: 10px 18px;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  border: 1px solid #158ecc;
  opacity: 0.98;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.closebox,
.popoverbottom .closebox {
  border-radius: 40px;
  background: #fff;
  padding: 10px 18px;
  color: #344054;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  border: 1px solid #d0d5dd;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
}
.ContactModelIn .text-danger {
  color: var(--Error-500, #f04438);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  border-color: transparent;
}
.closebox:hover,
.popoverbottom .closebox:hover {
  border-radius: 40px;
  background: #fff;
  padding: 10px 18px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 288px; */
}
.modelStyle .modal-footer,
.popoverbottom {
  justify-content: flex-end;
  display: flex;
}

.modelStyle .modal-header,
.popoverHead {
  border-bottom: 1px solid #eaecf0;
}
.modelStyle .modal-footer,
.popoverbottom {
  border-top: 1px solid #eaecf0;
  padding: 12px 24px 16px !important;
  margin-top: 13px;
}
.modelStyle textarea {
  border-radius: 4px;
  border: 1px solid #d0d5dd;
  background: #fff;
}
.modelStyle label {
  color: #667085;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
tr.nodataTableRow {
  height: calc(100vh - 220px);
}
tr.nodataTableRow td {
  border: 0;
  background: transparent !important;
}
.modelStyle .form-floating {
  position: relative;
  margin: 24px 0;
}
.modelStyle .modal-dialog {
  max-width: 589px;
}
.modelStyle .modal-content {
  border: 0;
  border-radius: 4px;
  background: #fff;
}
.QuoteLost .iconinnerStyle {
  border-radius: 16px;
  border: 1px solid #fef3f2;
  background: #fee4e2;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
}
.flatpickr-current-month input.cur-year {
  background: #fff !important;
}
.numInputWrapper span {
  border: 0px solid rgba(72, 72, 72, 0.15) !important;
  right: -8px !important;
}
.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: #000 !important;
}
.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: #000 !important;
}
.numInputWrapper span:hover {
  background: rgb(0 0 0 / 0%) !important;
}
.QuoteLost .iconOutStyle {
  width: 48px;
  height: 48px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 11px;
  border-radius: 28px;
  border: 8px solid var(--Error-50, #fef3f2);
  background: var(--Error-100, #fee4e2);
}
.tableTopBar .tabActive {
  border-radius: 4px;
  border: 1px solid #158ecc;
  background: #158ecc;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-left: 9px;
  padding: 4px 13px;
  color: #fff !important;
  min-width: 59px;
  height: 28px;
  display: flex;
  align-items: center;
}
table.table {
  margin-bottom: 0;
}
.popoverHead,
.popoverbottom {
  padding: 15px;
}
.linkByttonStyle,
.linkByttonStyle:hover {
  color: #158ecc;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  text-decoration: none;
  display: inline-block;
  padding: 0;
  cursor: pointer;
}
.clientAddress .linkByttonStyle {
  color: #158ecc;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  text-decoration: none;
  display: inline-block;
  padding: 0;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 8px;
  border: 1px solid var(--Primary-200, #a3e0ff);
  background: var(--Primary-25, #f2faff);
}
.clientAddress .linkByttonStyle svg {
  margin-left: 0;
}
table tbody tr .linkByttonStyle,
.linkByttonStyle {
  opacity: 0;
}
table tbody tr:hover .linkByttonStyle,
.linkByttonStyle {
  opacity: 1;
}

.linkByttonStyle svg {
  margin-left: 4px;
}
.leftStyle span {
  padding-right: 10px;
  display: inline-block;
  min-width: 125px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
button {
  cursor: pointer;
}

.boxLogin input:focus {
  border: 0;
  outline: none;
}

.error-message {
  color: var(--color6);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.14px;
  margin-top: 3px;
  margin-bottom: 0;
}

.inputInfo {
  width: 100%;
  position: relative;
}

.inputInfo img.ExclamationCircle {
  position: absolute;
  margin-right: 0;
  right: 8px;
  opacity: 0;
}

.error-border img.ExclamationCircle {
  opacity: 1;
}

.inputInfo.error-border {
  border-radius: 4px;
  border: 1px solid #fda29b !important;
}

.inputInfo.error-border input {
  border: none !important;
  padding-right: 10px;
}

.inputInfo.successBorder {
  border: 1px solid #d6bbfb !important;
}
/* Table */
.tableStyle {
  border-collapse: collapse;
  width: 100%;
}

.tableStyle td,
.tableStyle th {
  border: 1px solid var(--color10);
  background: var(--color2);
  padding: 8px;
  padding: 4px 16px;
  vertical-align: middle;
  text-align: left;
  white-space: nowrap;
}

.tableStyle tr:nth-child(even) {
  background: var(--color9);
}

.tableStyle tr:hover {
  background-color: #ddd;
}

.tableStyle thead tr th {
  color: var(--colorG3);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.12px;
  padding: 12px 12px 12px 16px;
}

.alighCenter {
  text-align: center;
}

#noneStyle-btn p {
  margin: 0;
  padding: 0;
}
#noneStyle-btn {
  color: var(--colorG4);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.12px;
  background: transparent;
  box-shadow: none !important;
  display: block;
  padding: 0;
  margin: 0 auto;
}

ul.listStyle li {
  list-style: none;
  display: inline-block;
  padding-right: 12px;
}

ul.listStyle li:last-child {
  padding-right: 0px;
}

.paddingLR {
  padding: 0 19px;
}

/* Home Page Style */
.topHeadingHome h1 {
  color: var(--Gray-900, #101828);
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.39px;
}
.HomePageWrap .grid-item {
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Base-White, #fff);
  padding-top: 16px;
}
.HomePageWrap .grid-item label input {
  display: none;
}
.HomePageWrap .grid-item label {
  text-align: left;
  padding: 0px 24px 16px;
}
.boxCheckNo span {
  border-radius: 28px;
  border: 4px solid var(--Gray-50, #f9fafb);
  background: var(--Gray-100, #f2f4f7);
  mix-blend-mode: multiply;
  width: 32px;
  height: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--Gray-700, #344054);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.boxCheckNo {
  margin-bottom: 18px;
}
.HomePageWrap .grid-item.active .boxCheckNo .ckeckIcon {
  display: flex;
  border-radius: 28px;
  border: 4px solid var(--Primary-50, #ebf8ff);
  background: var(--Primary-100, #bae8ff);
  mix-blend-mode: multiply;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
}
.HomePageWrap .grid-item.active .boxCheckNo .ckeckIcon {
  display: flex;
}
.HomePageWrap .grid-item .boxCheckNo span {
  display: block;
}
.HomePageWrap .grid-item.active .boxCheckNo span {
  display: none;
}
.HomePageWrap .grid-item .boxCheckNo .ckeckIcon {
  display: none;
}
.boxItemBut {
  border-top: 1px solid #eaecf0;
  padding: 16px 24px;
}
.boxItemBut button {
  color: #106b99;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-decoration: none;
  border-radius: 4px;
  border: 1px solid var(--Primary-200, #a3e0ff);
  background: var(--Primary-50, #ebf8ff);
  padding: 8px 14px;
}
.HomePageWrap .grid-item.active .boxItemBut button {
  border-radius: 4px;
  border: 1px solid var(--Primary-600, #158ecc);
  background: var(--Primary-600, #158ecc);
  color: #fff;
}
.HomePageWrap .boxItem h2 {
  color: var(--Gray-900, #101828);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.HomePageWrap .boxItem p {
  overflow: hidden;
  color: var(--Gray-600, #475467);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.HomePageWrap .grid-item:hover button {
  border-radius: 4px;
  border: 1px solid var(--Primary-600, #158ecc);
  background: var(--Primary-600, #158ecc);
  color: #fff;
}
.HomePageWrap .grid-item input[type="radio"] {
  width: 100%;
  height: 100%;
}
.HomePageWrap .grid-item.active {
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Primary-200, #a3e0ff);
  background: var(--Primary-50, #ebf8ff);
}
.bigBoxHome {
  border-radius: var(--radius-xl, 12px);
  border: 2px solid var(--Gray-100, #f2f4f7);
  background: #fff;
  padding: 24px;
  position: relative;
  border-radius: 4px;
}

.tobePaidWrap .tobePaidH {
  border-radius: var(--radius-xs, 4px);
  background: linear-gradient(
    135deg,
    rgba(247, 79, 172, 0.15) 0%,
    rgba(252, 178, 79, 0.15) 100%
  );
}
.TooltipOverlay [data-popper-placement^="top"] .tooltip-arrow::before,
.TooltipOverlay .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height)
    calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: #fff;
}
.TooltipWrapper button:hover,
.TooltipWrapper button:focus,
.TooltipWrapper button:active,
.TooltipWrapper button:active:focus-visible {
  background: transparent;
  border: 0;
  box-shadow: none !important;
}
.TooltipOverlay .tooltip-inner {
  background: #fff;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border: 6px;
  color: #000;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  padding: 10px;
}
.TooltipWrapper button {
  background: transparent;
  border: 0;
  padding: 0;
  position: absolute;
  right: 15px;
  top: 4px;
}
.bigBoxHome h3 {
  color: var(--Gray-600, #475467);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.154px;
}
.bigBoxHome h4 {
  color: var(--Gray-700, #344054);
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.39px;
  margin-bottom: 0;
}
.countNoBox {
  display: flex;
  width: 224px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: var(--spacing-md, 8px);
  margin: 16px auto 21px;
  position: relative;
  border: 1px solid #fff;
}
.countNoBox.tobePaidH {
  border-radius: 4px;
  background: linear-gradient(
    135deg,
    rgba(247, 79, 172, 0.15) 0%,
    rgba(252, 178, 79, 0.15) 100%
  );
}
.countNoBox.invoiceDueH {
  border-radius: 4px;
  background: linear-gradient(
    45deg,
    rgba(74, 135, 154, 0.15) 0%,
    rgba(197, 237, 245, 0.15) 100%
  );
}

.tobePaidH span {
  background: linear-gradient(to top, #fcb24f 0%, #f74fac 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.invoiceDueH span {
  background: linear-gradient(45deg, #4a879a 0%, #c5edf5 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.jobScheduledH span {
  background: linear-gradient(0deg, #97c3d9 0%, #106b99 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.activeQouteH span {
  background: linear-gradient(0deg, #97c3d9 0%, #106b99 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.jobWaitingH span {
  background: #ffb800;
  background: linear-gradient(to top, #ffb800 0%, #fff500 78%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.ActiveProjectH span {
  background: #89f7fe;
  background: linear-gradient(to top, #89f7fe 0%, #66a6ff 78%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.jobScheduledH {
  border-radius: var(--radius-xs, 4px);
  background: linear-gradient(
    180deg,
    rgba(186, 232, 255, 0.15) 0%,
    rgba(16, 107, 153, 0.15) 100%
  );
}
.ActiveProjectH {
  border-radius: var(--radius-xs, 4px);
  background: linear-gradient(
    135deg,
    rgba(137, 247, 254, 0.15) 0%,
    rgba(102, 166, 255, 0.15) 100%
  );
}
.jobWaitingH {
  border-radius: var(--radius-xs, 4px);
  background: linear-gradient(
    45deg,
    rgba(255, 184, 0, 0.15) 0%,
    rgba(255, 245, 0, 0.15) 100%
  );
}
.activeQouteH {
  border-radius: var(--radius-xs, 4px);
  background: linear-gradient(
    180deg,
    rgba(186, 232, 255, 0.15) 0%,
    rgba(16, 107, 153, 0.15) 100%
  );
}
.goodHeading {
  /* position: sticky; */
  /* top: 0; */
  background: #f7f9fc;
  padding: 13px 0 13px;
  z-index: 9;
}
.imageBoxHome:hover {
  -webkit-filter: sepia(1);
  filter: sepia(1);
}
.HomePageWrap {
  background: var(--BG, #f7f9fc);
  overflow-y: auto;
  height: calc(100vh - 149px);
  padding-bottom: 16px;
}
.homeBottom {
  background: var(--BG, #f7f9fc);
  padding-bottom: 28px;
  padding-top: 15px;
}
.HomePageWrap::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  padding: 5px;
}
.homeBottom ul li {
  display: inline-block;
  margin: 0 8px;
}
.HomePageWrap::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px #fff;
  border-radius: 10px;
}
.HomePageWrap::-webkit-scrollbar-thumb {
  background: #eaecf0;
  border-radius: 10px;
}
.HomePageWrap::-webkit-scrollbar-thumb:hover {
  background: #eaecf0;
}

.countNoBox span {
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: -1.4px;
  padding: var(--spacing-3xl, 24px) var(--spacing-4xl, 32px);
}
.HomePageWrap {
  background: var(--BG, #f7f9fc);
}
.imageBoxHome {
  height: 196px;
  padding: var(--spacing-lg, 12px) var(--spacing-xl, 16px);
  align-items: center;
  gap: var(--spacing-xl, 16px);
  flex: 1 0;
  background-position: center center;
  border-radius: var(--radius-xs, 4px);
  width: 154px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  text-align: center;
}
.imageBoxHome h3 {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.08px;
  position: absolute;
  top: 12px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.imageBoxHome:before {
  position: absolute;
  content: "";
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.4) -48.32%,
      rgba(0, 0, 0, 0) 103.16%
    ),
    linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--radius-xs, 4px);
}
.imageBoxHome .textOverly {
  z-index: 999;
}
.imageBoxHome button,
.imageBoxHome button:hover {
  border-radius: 28px;
  border: 1px solid #fff;
  display: flex;
  padding: 4px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  color: #fff;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin: 0 auto;
}
.bigBoxHome .pluslgIcon {
  position: absolute;
  right: -7px;
  top: -11px;
  border-radius: 300px;
  background: #bae8ff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  width: 24px;
  height: 24px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.bigBoxHome .pluslgIcon:hover svg {
  fill: #34637b;
}
.bigBoxHome .pluslgIcon:hover {
  background: #bae8ff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.centerTabSales ul li {
  display: inline-block;
}
.styleActionBtn {
  display: flex;
  justify-content: center;
}
.centerTabSales ul li a {
  color: #344054;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.styleT2 {
  border-radius: 16px;
  border: 1px solid #a3e0ff;
  background: #ebf8ff;
  color: #1ab2ff;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.12px;
  padding: 1px 8px;
  margin: 0px 8px;
}
.styleT3 {
  color: rgb(71, 84, 103);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.08px;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.filterSearch input[type="text"] {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
}
.filterDropDown {
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  padding: 4px 4px;
  display: flex;
  width: 32px;
  text-align: center;
  height: 32px;
  align-items: center;
  border-radius: 50%;
}
.filterSearch input[type="text"]::placeholder {
  color: #98a2b3;
  opacity: 1; /* Firefox */
}
.filterSearch input[type="text"]::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: #98a2b3;
}
.filterDropDown button {
  padding: 0;
}
.filterSearch {
  border-radius: 4px;
  border: 1px solid var(--Border, #d0d5dd);
  background: var(--Base-White, #fff);
  padding: 2px 8px;
  margin-left: 16px;
  width: 184px;
  height: 32px;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #98a2b3;
}
.filterSearch div {
  display: flex;
}
.filterSearch input,
.filterSearch input::placeholder,
.filterSearch input::-ms-input-placeholder {
  color: #98a2b3;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.filterSearch span {
  margin-right: 9px;
  cursor: pointer;
  margin-top: -2px;
}
.supplierTableWrap .bs-tooltip-top {
  white-space: nowrap;
}
.filtterBoxWrapper li.nav-item button,
.filtterBoxWrapper li.nav-item button:hover {
  align-items: center;
  color: #344054;
  color: var(--Gray-700, #344054);
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.14px;
  line-height: 22px;
  padding: 9px 10px;
  width: 100%;
  border: 0;
  box-shadow: none;
}
.filtterBoxWrapper li.nav-item button.active svg {
  fill: #158ecc;
}
.filtterBoxWrapper li.nav-item button.active,
.filtterBoxWrapper li.nav-item button:hover.active {
  background: #ebf8ff;
  background: var(--Primary-50, #ebf8ff);
  border-radius: 6px;
  color: #158ecc;
  color: var(--Primary-500, #158ecc);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.175px;
  line-height: 22px;
}
.filtterBoxWrapper li.nav-item button svg,
.filtterBoxWrapper li.nav-item button:hover svg {
  margin-right: 12px;
}
.tab-content .buttomBottom {
  border-top: 1px solid #eaecf0;
  border-top: 1px solid var(--Gray-200, #eaecf0);
  margin: 16px auto 0;
  padding: 16px 6px;
  width: 100%;
}
.tab-content .buttomBottom .btn-tabConten {
  background: #fff;
  background: var(--Base-White, #fff);
  border: 1px solid #d0d5dd;
  border: 1px solid var(--Gray-300, #d0d5dd);
  border-radius: 4px;
  color: #1d2939;
  color: var(--Gray-800, #1d2939);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  padding: 8px 14px;
  width: 100%;
}
.tab-content .buttomBottom .tabCancel,
.tab-content .buttomBottom .tabCancel:hover {
  background: #fff;
  background: var(--Base-White, #fff);
  border: 1px solid #d0d5dd;
  border: 1px solid var(--Gray-300, #d0d5dd);
  border-radius: 4px;
  color: #1d2939;
  color: var(--Gray-800, #1d2939);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  padding: 8px 14px;
  width: 100%;
}
.tab-content .buttomBottom .tabApply:hover,
.tab-content .buttomBottom .tabApply {
  background: #158ecc;
  background: var(--Primary-600, #158ecc);
  border: 1px solid #158ecc;
  border: 1px solid var(--Primary-600, #158ecc);
  border-radius: 4px;
  color: #fff;
  width: 100%;
}
.tab-content .customCheckBox .checkmark svg {
  opacity: 0;
}
.tab-content .customCheckBox input:checked ~ .checkmark svg {
  opacity: 1;
}
.tab-content .customCheckBox input:checked ~ .checkmark:after {
  display: block;
}
.tab-content .customCheckBox .checkmark {
  border: 0 solid #d0d5dd;
  border: 0 solid var(--Gray-300, #d0d5dd);
}
.tab-content .customCheckBox input:checked ~ .checkmark {
  border: 0 !important;
  background: transparent;
}
.tab-content .customCheckBox {
  position: relative;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--Gray-50, #fff);
  border-radius: var(--radius-sm, 6px);
  margin: 3px 6px;
  padding: 10px 10px 10px var(--spacing-md, 8px);
}
.tab-content .checkedList .customCheckBox {
  background: var(--Gray-50, #f9fafb);
}
.flatpickr-days {
  border: 0 !important;
}
.flatpickr-calendar {
  box-shadow: none !important;
}
.filtterBoxWrapper.nav.nav-tabs {
  display: block;
  position: absolute;
  z-index: 999;
  background: #fff;
  background: var(--Base-White, #fff);
  border: 1px solid #eaecf0;
  border: 1px solid var(--Gray-200, #eaecf0);
  border-radius: 4px 0 0 4px;
  padding: 2px 6px;
  width: 211px;
  margin-left: 45px;
  margin-top: -7px;
}
.main-wrapper .tab-content .tab-pane {
  background: #fff;
  background: var(--Base-White, #fff);
  border: 1px solid #f2f4f7;
  border: 1px solid var(--Gray-100, #f2f4f7);
  border-radius: 0 8px 8px 8px;
  border-radius: 0 var(--radius-md, 8px) var(--radius-md, 8px)
    var(--radius-md, 8px);
  min-width: 278px;
  position: absolute;
  left: 255px;
  z-index: 998;
  margin-top: -7px;
}
.custom-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 19px 10px;
  gap: 10px;
}
.custom-div p {
  padding: 0;
  margin: 0;
  white-space: noWrap;
}
.flatpickr-current-month
  .flatpickr-monthDropdown-months
  .flatpickr-monthDropdown-month {
  background-color: #ffffff !important;
  outline: none;
  padding: 0;
  font-size: 15px !important;
}
.custom-div span,
.custom-div p {
  border-radius: 4px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  color: var(--Gray-800, #1d2939);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  padding: 10px 5px;
  flex: 1;
}
input.flatpickr-input {
  display: none;
}
.flatpickr-months span {
  margin: 0;
  padding: 6px 12px !important;
}
select.flatpickr-monthDropdown-months,
.numInputWrapper input {
  color: var(--Gray-700, #344054);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 24px;
  letter-spacing: 0.08px;
}

.calenderBut .tabApply {
  color: #fff;
  width: 100%;
  margin: 0 6px;
  border-radius: 4px;
  border: 1px solid var(--Primary-600, #158ecc);
  background: var(--Primary-600, #158ecc);
  padding: 8px 14px;
}
.calenderBut .tabCancel {
  padding: 8px 14px;
  width: 100%;
  margin: 0 6px;
  border-radius: 4px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: #fff;
  color: var(--Gray-800, #1d2939);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.calenderBut {
  display: flex;
  margin: 15px 0;
}

.tab-content .customCheckBox .userName {
  align-items: center;
  color: #101828;
  color: var(--Gray-900, var(--Gray-900, #101828));
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.tab-content .customCheckBox .userName img {
  margin-right: 7px;
  margin-top: -3px;
}
div#controlled-tab-example-tabpane-Status span.checkmark {
  border: 1px solid #d0d5dd;
  border: 1px solid var(--Gray-300, #d0d5dd);
}
div#controlled-tab-example-tabpane-Status
  .customCheckBox
  input:checked
  ~ .checkmark {
  border-radius: var(--radius-sm, 6px) !important;
  border: 1px solid var(--Primary-500, #9e77ed) !important;
  background: var(--Primary-50, #f9f5ff) !important;
}
#controlled-tab-example-tabpane-Status label .userName {
  margin-left: 20px;
}
.tableTopBar .styleT3 .styleT2 {
  border-radius: 16px;
  border: 1px solid #fff0c1;
  background: #fffaeb;
  mix-blend-mode: multiply;
  color: #ffc301;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.12px;
}
#controlled-tab-example-tabpane-Status label {
  justify-content: flex-start !important;
}
.tab-content .customCheckBox .checkmark {
  top: 0px;
}
.shortNameTag {
  color: #475467;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-left: 8px;
}
.statusInfo a,
.NameStatus {
  border-radius: 16px;
  mix-blend-mode: multiply;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  padding: 3px 8px;
}
.statusInfo.Draft a {
  color: #344054;
  border: 1px solid #eaecf0;
  background: #f9fafb;
}
p.flexEndStyle strong {
  font-weight: 600 !important;
}
.btn-wonFilter,
.salesTableWrap .btn-wonFilter:hover,
.btn-wonFilter:active {
  border-radius: 4px;
  border: 1px solid var(--Success-200, #a9efc5) !important;
  background: var(--Success-50, #ecfdf3) !important;
  color: var(--Success-700, #067647) !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  padding: 4px 14px;
  min-width: 59px;
  min-height: 31px;
  display: flex;
  align-items: center;
}
.btn-lostFilter,
.salesTableWrap .btn-lostFilter:hover,
.btn-lostFilter:active {
  border-radius: 4px;
  border: 1px solid var(--Error-300, #fda29b) !important;
  background: var(--Error-25, #fffbfa) !important;
  color: var(--Success-700, #b42318) !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  padding: 4px 14px;
  min-width: 59px;
  min-height: 31px;
}
.tableTopBar ul.filterBtn li {
  display: inline-block;
  margin: 0 5px;
}
.tableTopBar ul.filterBtn {
  margin-left: 20px;
}
.tableTopBar ul.filterBtn li svg {
  margin-left: 6px;
}
.tableTopBar ul li .btn-downloadBtn,
.tableTopBar ul li .btn-downloadBtn:hover {
  border-radius: 4px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  padding: 3px;
  min-width: 31px;
  min-height: 31px;
}
.tableTopBar ul li .btn-downloadBtn svg {
  margin-left: 0 !important;
}
.loginPage .SinglBgRight p {
  color: var(--Base-White, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px;
  z-index: 999;
  width: 616px;
  margin-bottom: 37px;
}
.loginPage .SinglBgRight {
  height: 100vh;
  background-position: center center;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.my-component {
  transition: all 0.5s ease;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -243px;
}
.my-component.show {
  bottom: -243px;
}
.my-component.hide {
  bottom: 0px;
}
.my-component .downArrowIcon {
  border: 0;
  background: transparent;
}
.my-component .downArrowIcon svg {
  fill: #475467;
}

.boxLogin .inputInfo button.eyeButton {
  border: 0;
  background: transparent;
}
/* .my-component .downArrowIcon {
  transform: translateX(-50%);
  animation: arrowAnimation 1s infinite alternate; 
}

@keyframes arrowAnimation {
  0% {
    transform: translateX(-50%) translateY(0);
  }
  100% {
    transform: translateX(-50%) translateY(-5px);
  }
} */

input::-webkit-input-placeholder {
  color: #98a2b3;
}
input:focus::-webkit-input-placeholder {
  color: #98a2b3;
}

input::-webkit-input-placeholder {
  color: #98a2b3;
}
input:focus::-webkit-input-placeholder {
  color: #98a2b3;
}

@media only screen and (max-width: 1600px) and (min-width: 1280px) {
  table thead tr th,
  table tbody tr td {
    border-right: 1px solid var(--Gray-200, #eaecf0);
    white-space: nowrap;
  }
}

.szh-menu-container.threeDots ul {
  left: -225px !important;
  top: -0px !important;
}

.szh-menu-container.threeDots-setting ul {
  left: -155px !important;
  top: -0px !important;
}

.hover-greay:hover {
  background: #dedede;
  border-radius: 4px;
}

.react-international-phone-country-selector-dropdown__list-item {
  display: flex !important;
  justify-content: start !important;
  align-items: center !important;
}

.react-international-phone-country-selector-dropdown {
  margin-top: 5px !important;
}
