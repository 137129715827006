.disabledSelectBox {
  width: 150px;
  display: flex;
  color: #98a2b3;
  font-weight: 400;
  align-items: center;
  cursor: not-allowed;
  justify-content: space-between;
}

.disabledSelectBox .disabledSelectBoxLabel {
  max-width: 135px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.DepartmentQuote .salesTableWrap .select-menu {
  position: relative;
}

.DepartmentQuote .select-menu .select-btn {
  display: flex;
  border-radius: 8px;
  align-items: center;
  cursor: pointer;
  justify-content: flex-start;
}

.DepartmentQuote .select-menu .select-btn svg {
  position: absolute;
  right: 0;
}

.DepartmentQuote .select-btn i {
  font-size: 25px;
  transition: 0.3s;
}

.DepartmentQuote .options {
  display: none;
  position: absolute;
  list-style: none;
  padding: 0;
  margin: 0;
}
.dropdown.dropdown1551 {
  margin-top: 200px;
}
.DepartmentQuote .options.active {
  display: block;
}
.DepartmentQuote .szh-menu__item.szh-menu__item--submenu,
li.szh-menu__item {
  border-radius: var(--radius-sm, 6px);
  background: var(--Primary-50, #ffffff);
  padding: 10px 10px 10px var(--spacing-md, 8px);
  width: 95%;
  margin: 0 auto;
  color: var(--Gray-900, #101828);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  white-space: normal;
}
.szh-menu__submenu .szh-menu {
  margin-left: 8px !important;
  overflow: auto;
  height: 320px;
  width: 320px;
}
.szh-menu__submenu .szh-menu::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  padding: 5px;
}
.szh-menu__submenu .szh-menu::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px #fff;
  border-radius: 10px;
}
.szh-menu__submenu .szh-menu::-webkit-scrollbar-thumb {
  background: #eaecf0;
  border-radius: 10px;
}
.szh-menu__submenu .szh-menu::-webkit-scrollbar-thumb:hover {
  background: #d9d9da;
}

.DepartmentQuote .szh-menu__item.szh-menu__item--submenu:hover,
li.szh-menu__item:hover {
  border-radius: var(--radius-sm, 6px);
  background: var(--Primary-50, #ebf8ff);
  padding: 10px 10px 10px var(--spacing-md, 8px);
  color: var(--Primary-600, #158ecc);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.DepartmentQuote .select-menu .options {
  position: absolute;
  padding: 0 6px;
  margin-top: 10px;
  width: 278px;
  z-index: 99;
  left: -99px;
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Base-White, #fff);
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.DepartmentQuote .select-menu.active .options {
  display: block;
}

.DepartmentQuote .options .option {
  cursor: pointer;
  padding: 12px 21px 17px;
  border-radius: 8px;
  align-items: center;
  background: #fff;
  margin: 6px 0;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
}

.dropdown-item {
  padding: 8px 16px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

.submenu {
  position: absolute;
  top: 0;
  left: 100%;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.submenu-item {
  padding: 8px 16px;
  cursor: pointer;
}

.submenu-item:hover {
  background-color: #f1f1f1;
}

/*  merge items css */
.BoxNo {
  width: 51px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--radius-xs, 4px);
  border: 2px solid #1ab2ff;
  background: radial-gradient(
      2061.16% 127.06% at -1.24% 2.75%,
      #1ab2ff 0%,
      #ffb258 100%
    ),
    #fff;
}

.BoxNo .no {
  color: #fff;
  font-size: 10px;
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  border: 1px solid #fff;
}

.note-textarea .textarea,
.note-textarea .textarea:focus {
  font-size: 16px;
  border-radius: var(--radius-xs, 4px) !important;
  border: 1px solid var(--Gray-300, #d0d5dd) !important;
  background: var(--Base-White, #fff) !important;
  outline: none !important;
  box-shadow: none !important;
}

.form-control::placeholder {
  color: #98a2b3 !important;
}

.inputInfo .no-border-outline {
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
}

.text-left {
  text-align: left;
}

.border-right-0 {
  border: left 0px;
}

.mui-select-custom .MuiSelect-select,
.mui-select-custom .MuiOutlinedInput-notchedOutline {
  padding: 11px 14px !important;
  color: #101828 !important;
  border: 1px solid #d0d5dd !important;
}

.mui-select-custom .MuiSelect-icon {
  color: #98a2b3;
}

.basic-multi-select > * {
  border: none !important;
  outline: none !important;
}

.basic-multi-select span {
  background: transparent !important;
}

.szh-menu::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  padding: 5px;
}

.szh-menu::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px #fff;
  border-radius: 10px;
}

.szh-menu::-webkit-scrollbar-thumb {
  background: #eaecf0;
  border-radius: 10px;
}

.szh-menu::-webkit-scrollbar-thumb:hover {
  background: #eaecf0;
}

ul.szh-menu {
  width: 221px;
}

.szh-menu__submenu {
  width: 200px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  text-align: left;
}
