.modal {
  width: 607px;
  border-radius: var(--radius-xs, 4px);
  background: var(--Base-White, #fff);
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
}

.modalHeader {
  gap: 16px;
  display: flex;
  align-items: center;
}

.headerTitle {
  font-size: 18px;
  font-weight: 600;
  color: var(--Gray-700, #344054);
}

.weekNames {
  gap: 16px;
  align-items: center;
  margin-bottom: 16px;
}

.weekName {
  color: var(--Primary-700, #106b99);
  text-align: center;

  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  display: flex;
  padding: var(--spacing-xxs, 2px) var(--spacing-md, 8px);
  align-items: center;
  border-radius: 16px;
  border: 1px solid var(--Primary-200, #a3e0ff);
  background: var(--Primary-25, #f2faff);
  mix-blend-mode: multiply;
}

.iconJobstyle {
  border-radius: 28px;
  border: 8px solid var(--Primary-50, #EBF8FF);
  background: var(--Primary-100, #BAE8FF);
  display: flex;
  width: 48px;
  height: 48px;
  padding: 7px;
  justify-content: center;
  align-items: center;
}