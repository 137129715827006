:root {
  --neutral20: #d0d0d0; /* Default color if not set by JS */
}

.button {
  height: 44px;
  display: flex;
  font-weight: 400;
  padding: 4px 12px;
  border-radius: 4px;
  align-items: center;
  border: 1px solid #ddd;
  background-color: white;
  justify-content: space-between;
}

.error {
  border-color: #dc3545;
}

.selectedValue {
  color: #101828;
}

.placeholder {
  color: #98a2b3;
}

.select {
  width: 100%;
  border: none;
  position: absolute;
}

.menu {
  z-index: 2;
  margin-top: 8px;
  border-radius: 4px;
  position: absolute;
  background-color: white;
  box-shadow: 0 0 0 1px hsla(218, 50%, 10%, 0.1),
    0 4px 11px hsla(218, 50%, 10%, 0.1);
}

.blanket {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  position: fixed;
}

.dropdown {
  position: relative;
}

.dropdownIndicator {
  width: 32px;
  height: 28px;
  color: var(--neutral20);
}
