.newJobTag {
  color: var(--Primary-800, #0a4766);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
  display: flex;
  padding: var(--spacing-xs, 4px) var(--spacing-xl, 10px);
  align-items: center;
  border-radius: 16px;
  border: 1px solid var(--Primary-100, #bae8ff);
  background: var(--Primary-50, #ebf8ff);
  mix-blend-mode: multiply;
}

.border {
  border: 1px solid #f2f4f7 !important;
}

.borderBottom {
  border-bottom: 1px solid #f2f4f7 !important;
}

.background {
  background: #fbfcfd;
}

.customLabel {
  color: var(--Gray-600, #475467);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 22px;
  letter-spacing: 0.14px;
  margin-bottom: 8px;
}

.inputBox {
  display: flex;
  padding: 10px 14px;
  align-items: center;
  gap: var(--spacing-md, 8px);
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Border, #d0d5dd);
  background: var(--Base-White, #fff);
}

.chooseUserBox {
  display: flex;
  padding: var(--spacing-lg, 12px) var(--spacing-xl, 16px);
  align-items: center;
  gap: var(--spacing-xl, 16px);
  flex: 1 0 0;
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Primary-200, #EAECF0);
  background: var(--Primary-50, #fff);
}
.paymentType {
  &.typeBorder {
    border-top: 1px solid #eaecf0;
    padding-top: 20px;
    padding-bottom: 30px;
  }
  .paymentmain {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 6px;
    span {
      margin: 0 24px;
      color: var(--Gray-600, #475467);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.14px;
    }
  }
  .customRadio {
    // Hide the original radio button
    opacity: 0;
    position: absolute;
    width: 138px;
    height: 44px;

    // Create a custom radio button
    + label {
      position: relative;
      padding-left: 30px;
      cursor: pointer;
      font-size: 16px;
      user-select: none;
    }

    // Create the circle (custom radio button)
    + label {
      position: absolute;
      width: 138px;
      height: 44px;
      background: transparent;
      transition: all 0.3s ease;
      border-radius: var(--radius-xs, 4px);
      display: flex;
      align-items: center;
      padding: 10px 18px;
      justify-content: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      white-space: nowrap;
    }

    + label.fix {
      color: #1d2939;
      border: 1px solid #98b15b;
    }

    &:checked + label.fix {
      background: #e1fc9d;
      border: 1px solid #e1fc9d;
    }

    + label.hours {
      color: #5b5813;
      border: 1px solid #d0ca63;
    }

    &:checked + label.hours {
      background: #f9f49a;
      border: 1px solid #f9f49a;
    }

    + label.timetracker {
      color: #51256e;
      border: 1px solid #c295e0;
    }

    &:checked + label.timetracker {
      color: #1d2939;
      background: #e8ccfb;
      border: 1px solid #e8ccfb;
    }

    + label.shift,
    + label.timeFrame {
      color: #344054;
      border: 1px solid #d0d5dd;
    }

    &:checked + label.shift,
    &:checked + label.timeFrame {
      color: #344054;
      background: #f2faff;
      border: 1px solid #bae8ff;
    }
  }

  .radioLabel {
    margin-left: 0px; // Adjust spacing between the radio button and label text
  }

  .RadioButton {
    width: 138px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.fileBox {
  width: 100%;
  padding: 15px;
  display: flex;
  gap: 10px;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Base-White, #fff);
}

.imgBox {
  position: relative;
}

.fileType {
  bottom: 4px;
  left: 7px;
  color: var(--Base-White, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 9px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  position: absolute;
}

.fileNameBox p {
  overflow: hidden;
  color: var(--Gray-700, #344054);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.deleteBox {
  display: inline-flex;
  padding: 4px;
  align-items: center;
  gap: 8px;
  border-radius: 22px;
  background: var(--Error-100, #fee4e2);
}

.openTransition {
  transition: all 0.5s;
}