#calender .scheduler_default_rowheader_scroll,
#calender .scheduler_default_scrollable {
  height: calc(100vh - 234px) !important;
}

#calender .scheduler_default_rowheader_inner_text h2 {
  color: var(--Gray-600, #475467);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.08px;
  max-width: 268px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.scheduler_default_rowheader_inner {
  position: relative;
  inset: 0px;
  border-right: 1px solid rgb(224, 224, 224);
  padding: 2px;
  display: flex;
  align-items: center;
}

.scheduler_default_timeheadergroup_inner.scheduler_default_timeheader_cell_inner {
  color: #475467;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.12px;
  border: 0;
  background: #fff;
}

.scheduler_default_timeheadercol_inner.scheduler_default_timeheader_cell_inner {
  color: #475467;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.12px;
  border: 0;
  background: #fff;
}

.scheduler_default_separator:after {
  position: absolute;
  content: "";
  background: #48c1ff;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  left: -4px;
  z-index: 9;
  top: 0;
}

.scheduler_default_cell.scheduler_default_cell_business {
  background-color: #fcfcfd;
}

.resourceList {
  border-top: 1px solid #eaecf0 !important;
}

.gantt_default_tree_image_expand {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnPjxwYXRoIGQ9J00gMS41IDAuNSBMIDYuNSA1IEwgMS41IDkuNScgc3R5bGU9J2ZpbGw6bm9uZTtzdHJva2U6Izk5OTk5OTtzdHJva2Utd2lkdGg6MjtzdHJva2UtbGluZWpvaW46cm91bmQ7c3Ryb2tlLWxpbmVjYXA6YnV0dCcgLz48L3N2Zz4=");
}

.gantt_default_tree_image_collapse {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTAnIGhlaWdodD0nMTAnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTSAwLjUgMS41IEwgNSA2LjUgTCA5LjUgMS41JyBzdHlsZT0nZmlsbDpub25lO3N0cm9rZTojOTk5OTk5O3N0cm9rZS13aWR0aDoyO3N0cm9rZS1saW5lam9pbjpyb3VuZDtzdHJva2UtbGluZWNhcDpidXR0JyAvPjwvc3ZnPg==");
}

.eventInnerText {
  padding-left: 25px;
}

ul.resourceMan {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 9px 4px 9px 25px;
  background: #fcfcfd;
}

ul.resourceMan li {
  color: var(--Gray-500, #667085);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.12px;
  margin-right: 14px;
}

ul.resourceMan li span {
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  border-radius: 16px;
  background: var(--Warning-25, #fffcf5);
  mix-blend-mode: multiply;
  padding: 2px 8px;
}

ul.resourceMan li span.pending {
  color: var(--Warning-500, #f79009) !important;
  background: var(--Warning-25, #fffcf5) !important;
  border: 1px solid var(--Warning-200, #fedf89) !important;
}

ul.resourceMan li span.done {
  color: var(--Success-500, #17b26a) !important;
  background: var(--Success-25, #f6fef9) !important;
  border: 1px solid var(--Success-200, #a9efc5) !important;
}

ul.resourceMan li span.not-started {
  color: var(--Gray-500, #667085) !important;
  background: var(--Gray-50, #f9fafb) !important;
  border: 1px solid var(--Gray-300, #d0d5dd) !important;
}

ul.resourceMan li span.not-done {
  color: var(--Error-500, #f04438) !important;
  background: var(--Error-25, #fffbfa) !important;
  border: 1px solid var(--Error-200, #fecdca) !important;
}

.resourceList {
  text-align: left;
}

.resourceList .small {
  color: var(--Gray-500, #667085);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.08px;
  max-width: 268px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.resourceList h2 {
  color: var(--Gray-600, #475467);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.175px;
}

.resourceList em {
  border-radius: 16px;
  mix-blend-mode: multiply;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  padding: 2px 8px;
  /* color: var(--Warning-700, #b54708);
  background: var(--Warning-50, #fffaeb);
  border: 1px solid var(--Warning-200, #fedf89); */
}

.scheduler_default_event.job-item {
  margin-top: 40px !important;
  height: 40px !important;
}

.scheduler_default_event_inner {
  display: inline-grid;
  align-items: center;
}

.scheduler_default_event_inner ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.scheduler_default_event_inner {
  border-radius: 5px;
  background: var(--Primary-100, #bae8ff);
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.06);
  border: 0;
  padding: 2px 9px 2px 20px;
}

.scheduler_default_event_inner ul li span,
.scheduler_default_event_float ul li span {
  border-radius: 225px;
  border: 2px solid var(--Primary-200, #a3e0ff);
  background: #f5f7f8;
  color: var(--Gray-600, #475467);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.15px;
  display: flex;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
}

.scheduler_default_tree_image_expand {
  background-image: url('data:image/svg+xml,<svg width="1em" height="1em" fill="667085" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.7 11.3l-6-6c-0.4-0.4-1-0.4-1.4 0s-0.4 1 0 1.4l5.3 5.3-5.3 5.3c-0.4 0.4-0.4 1 0 1.4 0.2 0.2 0.4 0.3 0.7 0.3s0.5-0.1 0.7-0.3l6-6c0.4-0.4 0.4-1 0-1.4z"></path></svg>') !important;
  width: 17px !important;
  height: 14px !important;
  top: 9px !important;
}

.scheduler_default_tree_image_collapse {
  background-image: url('data:image/svg+xml,<svg width="1em" height="1em" fill="%231AB2FF" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.7 11.3l-6-6c-0.4-0.4-1-0.4-1.4 0s-0.4 1 0 1.4l5.3 5.3-5.3 5.3c-0.4 0.4-0.4 1 0 1.4 0.2 0.2 0.4 0.3 0.7 0.3s0.5-0.1 0.7-0.3l6-6c0.4-0.4 0.4-1 0-1.4z"></path></svg>') !important;
  width: 17px !important;
  height: 17px !important;
  top: 9px !important;
  transform: rotate(-90deg);
  background: #ebf8ff;
  border-radius: 100%;
  display: inline-block;
}

.scheduler_default_event_float ul li span {
  margin-left: 20px;
}

.scheduler_default_rowheader_inner_text {
  margin-left: 0 !important;
  padding-left: 0;
}

.resourcesRow .scheduler_default_rowheader_inner_text .resourceList .small,
.resourcesRow .scheduler_default_rowheader_inner_text .resourceList h2,
.resourcesRow .scheduler_default_rowheader_inner_text .resourceList em {
  margin-left: 25px;
}

ul.eventStyleCal li:first-child span {
  background: linear-gradient(180deg, #f9fafb 0%, #edf0f3 100%);
  border-radius: 4px;
  border: 0.75px solid #d1d1d1;
  padding: 4px 0px 5px 4px;
  margin-right: 6px;
}

ul.eventStyleCal li:first-child {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  padding-left: 14px;
}

.scheduler_default_event.job-item .scheduler_default_event_float_inner::after {
  top: 10px !important;
}
ul.eventStyleCal li {
  color: var(--Gray-700, #344054);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.14px;
}
ul.eventStyleCal li:nth-child(2) {
  color: var(--Primary-800, #0a4766);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.14px;
}

ul.eventStyleCal {
  display: flex;
}
.scheduler_default_corner_inner {
  background: #fff;
}

.rowResourceEvent:before {
  position: absolute;
  right: 0;
  content: "";
  background: var(--main-color);
  width: 4px;
  height: 100vh;
  z-index: 10;
}
.create-task-div:before {
  position: absolute;
  right: 0;
  content: "";
  background: var(--main-color);
  width: 4px;
  height: 100vh;
  z-index: 10;
}

.task-list.rowResourceEvent:before {
  top: -10px;
}

.create-task-div.rowResourceEvent:before {
  bottom: -10px;
}

.scheduler_default_rowheader {
  position: relative;
}

#calender .scheduler_default_rowheader_inner {
  position: absolute;
  inset: 0px;
  border-right: 0px solid #eaecf0;
  padding: 0;
  display: inherit;
  align-items: center;
  background: #fcfcfd;
}

.scheduler_default_rowheader .scheduler_default_resourcedivider {
  background: transparent;
}

.scheduler_default_tree_image_collapse {
  right: 8px;
}

.scheduler_default_tree_image_expand {
  right: 8px;
}
.scheduler_default_event_inner {
  padding: 9px 19px 9px 0 !important;
  border-radius: 10px !important;
  box-shadow: 0px 3px 5px 0px rgba(16, 24, 40, 0.1),
0px 3px 4px 0px rgba(16, 24, 40, 0.06) !important;
  border: 0 !important;
  display: block !important;
}
.scheduler_default_rowheader {
  text-align: left;
}

#calender .scheduler_default_tree_image_no_children {
  display: none;
}

#calender .scheduler_default_rowheader_inner_indent {
  margin-left: 0 !important;
}

.scheduler_default_rowheader_inner_text {
  color: var(--Gray-700, #344054);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.12px;
}

.scheduler_default_event {
  overflow: visible !important;
}

.my-event1 {
  padding: 7px 0;
}

.childEvent .childEventborder {
  border: 0 !important;
  border-radius: 0 !important;
  padding: 0 !important;
}

.childEvent .scheduler_default_event_inner {
  color: var(--Gray-700, #344054);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.12px;
  text-align: left;
  padding: 7px !important;
}

.task-list {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 5px 25px;
  height: 38px;
  background-color: #f2faff !important;
}

.task-list .flex {
  display: flex;
}

.title-description-section {
  position: relative;
  top: -3px;
  padding-left: 5px;
}

.title-description-section .task-assigner {
  color: var(--Gray-700, #344054);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.125px;
  margin-bottom: 0px;
  display: block;
  max-width: 224px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.title-description-section .task-title {
  color: var(--Gray-700, #344054);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.12px;
  margin-bottom: 0px;
  display: block;
  max-width: 224px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.create-task-div {
  height: 100vh;
  display: flex;
  align-items: end;
  padding-left: 25px;
  padding-bottom: 20px;
  background-color: #f2faff !important;
}

.create-task-div button {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  background-color: transparent !important;
  color: var(--Primary-600, #158ecc);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.scheduler_default_tree_image_collapse {
  position: relative;
}

#calender .scheduler_default_tree_image_collapse::after {
  position: absolute;
  top: -300px;
  right: 29px;
  content: "";
  background: #f2faff;
  width: 100px;
  height: 323px;
  z-index: -1;
}

.project-content {
  padding-top: 6px;
  position: relative;
  z-index: 10;
  cursor: pointer;
}

/* Project Card Model */
.projectCardModel .modal-dialog {
  min-width: 1284px;
}
strong.dollorIcon {
  border-radius: 28px;
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -3px;
}
.projectCardModel .cardId {
  margin-left: 16px;
  color: var(--Gray-700, #344054);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.27px;
}
.projectCardModel .selectedStatusWrap label select {
  background: transparent;
  border: 0;
}
.projectCardModel .selectedStatusWrap label {
  border-radius: 4px;
  border: 1px solid var(--Error-100, #fee4e2);
  background: var(--Error-50, #ffe8cd);
  mix-blend-mode: multiply;
  padding: 4px 7px;
  text-align: center;
  margin: 0 14px;
  color: var(--Orange-800, #6d471a);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.14px;
  position: relative;
  border-left: 4px solid #ffb258;
}

.projectCardModel .refrencesTag {
  color: var(--Gray-500, #667085);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.08px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.projectCardModel .refrencesTag strong {
  color: var(--Gray-600, #475467);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.08px;
}
.projectCardModel .modal-header {
  border-bottom: 1px solid #eaecf0;
  padding: 16px 16px !important;
}

.customScrollBar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  padding: 5px;
}
.customScrollBar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 8px #f2f4f7;
  border-radius: 10px;
}
.customScrollBar::-webkit-scrollbar-thumb {
  background: #76d1ff;
  border-radius: 10px;
}
.customScrollBar::-webkit-scrollbar-thumb:hover {
  background: #76d1ff;
}
.orderDiscription .customScrollBar {
  height: 137px;
  overflow: auto;
  color: var(--Text-Dark-Gray, #344054);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.08px;
  padding-right: 22px;
}
.orderDiscription strong {
  color: var(--Gray-800, #1d2939);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.08px;
}
.currentJobsTable h5 {
  color: var(--Gray-800, #1d2939);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.08px;
}
.currentJobsTable table thead th {
  color: #475467;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.12px;
}
.currentJobsTable table tbody td {
  color: var(--Gray-700, #344054);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.12px;
  height: 40px;
}
.currentJobsTable table tbody td:first-child,
.currentJobsTable table thead th:first-child {
  width: 159px;
  text-align: left;
}
.currentJobsTable table tbody td:last-child,
.currentJobsTable table thead th:last-child {
  width: 96px;
  text-align: center;
}
.currentJobsTable table tbody td:nth-child(2),
.currentJobsTable table thead th:nth-child(2) {
  width: 230px;
  text-align: left;
}
.currentJobsTable table tbody td:nth-child(3),
.currentJobsTable table thead th:nth-child(3) {
  width: 76px;
  text-align: center;
}
.currentJobsTable table tbody td:nth-child(4),
.currentJobsTable table thead th:nth-child(4) {
  width: 116px;
  text-align: right;
}
.currentJobsTable table tbody td:first-child,
.currentJobsTable table thead th:first-child {
  border-right: 1px solid var(--Gray-200, #eaecf0);
}
.currentJobsTable table {
  border-top: 1px solid var(--Gray-200, #eaecf0);
  border-bottom: 1px solid var(--Gray-200, #eaecf0);
  border-left: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Base-White, #fff);
}
.orderDiscription.col {
  border-right: 1px solid #eaecf0;
  padding: 16px 8px 8px 24px;
}
.tabModelMenu {
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  padding: 8px;
  border-radius: 6px;
  min-width: 89%;
}
.projectHistoryCol {
  border-left: 1px solid #eaecf0;
  padding: 16px 24px;
}
.tabModelMenu .col {
  text-align: center;
}
.tabModelMenu .linkByttonStyle {
  color: var(--Gray-700, #344054);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  padding: 0 24px;
}
.taskModelProject .sendSMSPhone .inputInfo {
  width: 100%;
  display: flex;
}
.projectHistoryCol .filter span {
  border-radius: 4px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: #fff;
  padding: 8px;
}
.projectHistoryCol .filter {
  min-width: 11%;
}
.projectHistorygroup ul {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.projectHistorygroup ul li {
  display: flex;
  align-items: center;
  padding-right: 8px;
}
.projectHistorygroup ul li strong {
  color: var(--Text-Dark-Gray, #344054);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.08px;
}
.projectHistoryWrap h3 {
  color: var(--Gray-700, #344054);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.18px;
}
.projectHistorygroup h6 {
  color: var(--Gray-500, #667085);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.14px;
  margin: 0;
  padding: 0;
}
.projectHistorygroup h5 {
  color: var(--Gray-500, #344054);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.14px;
  margin: 0;
  padding: 0;
}
.projectHistorygroup p {
  color: var(--Gray-400, #98a2b3);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.1px;
  margin: 0;
  padding: 0;
}
.projectHistoryScroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  padding: 5px;
}
.currentJobsTable .table-responsive::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  /* padding: 5px; */
}
.projectHistoryScroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 8px #f2f4f7;
  border-radius: 10px;
}
.currentJobsTable .table-responsive::-webkit-scrollbar-track {
  box-shadow: inset 0 0 8px #f2f4f7;
  border-radius: 10px;
}
.projectHistoryWrap {
  margin-top: 16px;
}
.projectHistoryScroll::-webkit-scrollbar-thumb {
  background: #76d1ff;
  border-radius: 10px;
}
.currentJobsTable .table-responsive::-webkit-scrollbar-thumb {
  background: #76d1ff;
  border-radius: 10px;
}
.projectHistoryScroll::-webkit-scrollbar-thumb:hover {
  background: #76d1ff;
}
.currentJobsTable .table-responsive::-webkit-scrollbar-thumb:hover {
  background: #76d1ff;
}
.projectHistoryScroll {
  height: 429px;
  overflow: auto;
  margin-right: 15px;
}
.projectCardMain {
  border-bottom: 1px solid #f1f1f1;
  margin: 0;
}
.projectHistorygroup {
  margin-bottom: 18px;
}
.projectHistorygroup:last-child {
  margin-bottom: 0px;
}
.currentJobsTable .table-responsive {
  height: 320px;
}
.currentJobsTable table tbody td span {
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  border-radius: 16px;
  mix-blend-mode: multiply;
  padding: 2px 8px;
  white-space: nowrap;
}

.currentJobsTable td.status span.paid {
  border: 1px solid var(--Success-200, #a9efc5);
  background: var(--Success-50, #ecfdf3);
  color: #067647;
}
.currentJobsTable td.status span.unpaid {
  border: 1px solid var(--Error-200, #fecdca);
  background: var(--Error-50, #fef3f2);
  color: #b42318;
}
.currentJobsTable td.status span {
  border: 1px solid #ccc;
  background: #fff;
  color: #000;
}
.projectCardButWrap.row {
  margin: 0;
}
.projectCardButWrap button img {
  margin-left: 7px;
}
.projectCardButWrap button {
  border-radius: 4px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  padding: 10px 18px;
  color: var(--Gray-700, #344054);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.projectCardButWrap .col {
  gap: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px 24px;
}
.projectCardCalculation.row {
  margin: 0;
  border-top: 1px solid #eaecf0;
  border-bottom: 1px solid #eaecf0;
}
.proBuget {
  background: var(--Gray-50, #f9fafb);
  max-width: 236.75px;
}
.proBuget h5,
.proBuget p {
  color: #475467;
}
.proRealCost {
  background: #f3ffff;
  max-width: 236.75px;
}
.proRealCost h5,
.proRealCost p {
  color: #2a928f;
}

.proCostSale {
  background: var(--Error-50, #fef3f2);
  max-width: 236.75px;
}
.proCostSale h5,
.proCostSale p {
  color: #b42318;
}

.proLabour {
  background: var(--Warning-50, #fffaeb);
  max-width: 236.75px;
}
.proLabour h5,
.proLabour p {
  color: #93370d;
}

.proProfit {
  background: var(--Success-100, #dcfae6);
  max-width: 337px;
  justify-content: space-between !important;
}
.proProfit span svg {
  margin-left: 8px;
}
.projectColBg {
  text-align: right;
  padding: 4px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.projectColBg h5 {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.08px;
  margin: 0;
}
.projectColBg p {
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.18px;
  margin: 0;
}
.proProfit span {
  color: var(--Success-800, #085d3a);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.18px;
  display: flex;
  align-items: center;
}
.proProfit strong {
  color: var(--Success-800, #085d3a);
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.33px;
}
.projectCardactionBut.row {
  margin: 0;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eaecf0;
}
.projectCardactionBut .actionLeftSide button,
.projectCardactionBut .actionLeftSide button:hover {
  color: var(--Gray-600, #475467);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  background: transparent;
  border: 0;
  padding: 0;
}
.projectCardactionBut .actionLeftSide button.declineAction {
  color: #b42318;
  display: flex;
    align-items: center;
}
.projectCardactionBut .actionLeftSide button svg {
 margin-right: 5px;
}
.projectCardactionBut .actionLeftSide button.duplicateAction {
  color: #106b99;
  display: flex;
    align-items: center;
}
.projectCardactionBut .actionLeftSide button.sendBackAction {
  color: #475467;
  display: flex;
    align-items: center;
}
.projectCardactionBut .actionLeftSide button.duplicateAction {
  padding: 0 32px;
}
.projectCardactionBut .actionRightSide button,
.projectCardactionBut .actionRightSide button:hover {
  border-radius: 4px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: #fff;
  padding: 10px 18px;
  color: var(--Gray-700, #344054);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.projectCardactionBut .actionRightSide {
  text-align: right;
  padding-right: 0;
}
.projectCardactionBut .actionLeftSide {
  padding-left: 0;
  display: flex;
  align-items: center;
}
.projectCardactionBut .actionRightSide button.ProgressAction {
  margin: 0 8px;
}
.projectCardactionBut .actionRightSide button.CompleteActionBut {
  border-radius: 4px;
  border: 1px solid var(--Primary-600, #158ecc);
  background: var(--Primary-600, #158ecc);
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.projectBreakdown.row {
  margin: 0;
  padding: 8px 24px;
  text-align: center;
}
.projectBreakdown.row button,
.projectBreakdown.row button:hover {
  color: var(--Primary-700, #106b99);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  background: transparent;
  border: 0;
}

.topbar {
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  border-top: 1px solid var(--Gray-200, #eaecf0);
  border-bottom: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Dark-Base-Base-White, #fff);
}

.bottom-border.topbar {
  border-bottom: 1px solid var(--Gray-200, #eaecf0) !important;
}

#calender {
  border-top: 0px solid #fff !important;
}

.featureName .title {
  color: var(--Gray-600, #475467);
  margin-bottom: 0px;
  /* Title body */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.18px;
  padding: 14px 24px;
}

.featureName .filters {
  gap: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filters .btn2 button {
  color: var(--Gray-800, #1d2939);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */

  display: flex;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 4px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: #fff;
}
.taskModelProject .modal-header {
  border-bottom: 1px solid #eaecf0 !important;
  justify-content: space-between;
}
.taskModelProject .modal-header span img {
  margin-right: 12px;
}
.taskModelProject .inputInfo {
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Border, #d0d5dd);
  background: var(--Base-White, #fff);
  padding: 8px 14px;
}
.taskModelProject .inputInfo textarea {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.08px;
  width: 100%;
  min-height: 140px;
  border: 0;
}
.taskModelProject .upload-btn-wrapper {
  display: block;
  width: 100% !important;
}
.taskModelProject .upload-btn-wrapper button.btnup {
  width: 100%;
}
.taskModelProject .upload-btn-wrapper .textbtm p span {
  color: var(--Primary-700, #106b99);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.taskModelProject .popoverbottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0 0 !important;
}
.taskModelProject .popoverbottom .btn-outline-danger,
.taskModelProject .popoverbottom .btn-outline-danger:hover {
  color: var(--Error-700, #b42318);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  border: 0;
  padding: 0;
  text-align: left;
  background: transparent;
}
.taskModelProject .popoverbottom .save,
.taskModelProject .popoverbottom .save:hover {
  border-radius: 4px;
  border: 1px solid var(--Primary-600, #158ecc);
  background: var(--Primary-600, #158ecc);
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.sendSMSPhone .inputInfo span {
  border-radius: 6px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  display: flex;
  padding: 2px 4px 2px 5px;
  justify-content: center;
  align-items: center;
  color: var(--Gray-700, #344054);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-right: 7px;
}
.taskModelCompose .modal-dialog {
  max-width: 896px;
}
.taskModelCompose .select__control {
  border: 0px;
  width: 100%;
  max-width: 100%;
  min-width: 100% !important;
}
.taskModelProject .inputInfo {
  width: 100%;
  display: block;
}
.formgroupSelectTag .inputInfo span {
  border-radius: 6px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  padding: 2px 4px 2px 5px;
  color: var(--Gray-700, #344054);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.formgroupSelectTag .basic-multi-select {
  width: 100%;
}
.formgroupSelectTag .inputInfo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.projectCardFilterDropdown .dropdown-menuF {
  position: absolute;
  margin-top: 11px;
  padding: 10px;
  list-style-type: none;
  width: 263px;
  z-index: 1000;
  right: 0;
  border-radius: 0px var(--radius-xs, 4px) var(--radius-xs, 4px)
    var(--radius-xs, 4px);
  border: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Base-White, #fff);
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
}
.projectCardFilterDropdown .dropdown-menuF ul {
  padding: 0;
  margin: 0;
}
.projectCardFilterDropdown .dropdown-menuF .all {
  color: var(--Gray-900, #101828);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  border: 0;
  background: transparent;
  padding: 10px 10px 10px var(--spacing-md, 8px);
}
.projectCardFilterDropdown .dropdown-menuF li {
  color: var(--Gray-900, #101828);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  padding: 10px 10px 10px var(--spacing-md, 8px);
  margin: 6px 0;
}
.projectCardFilterDropdown .dropdown-buttonsF .apply {
  border-radius: 4px;
  border: 1px solid var(--Primary-600, #158ecc);
  background: var(--Primary-600, #158ecc);
  padding: 8px 14px;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  width: 50%;
}
.projectCardFilterDropdown .dropdown-menuF li label svg {
  margin-right: 8px;
}
.projectCardFilterDropdown .dropdown-buttonsF .cancel {
  border-radius: 4px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: #fff;
  padding: 8px 14px;
  color: var(--Gray-800, #1d2939);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  width: 50%;
}
.projectCardFilterDropdown .dropdown-menuF li label {
  display: flex;
  align-items: center;
}
.projectCardFilterDropdown .dropdown-menuF li.active {
  border-radius: var(--radius-sm, 6px);
  background: var(--Gray-50, #f9fafb);
  padding: 10px 10px 10px var(--spacing-md, 8px);
}
.projectCardFilterDropdown .dropdown-menuF li input {
  opacity: 0;
  position: absolute;
  width: 100%;
  margin-right: 8px;
}
.projectCardFilterDropdown .dropdown-buttonsF {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.projectCardFilterDropdown .dropdown-buttonsF button {
  margin: 0 5px;
}

.projectCardFilterDropdown .selected-options {
  margin-top: 20px;
}
.projectCardFilterDropdown {
  position: relative;
  cursor: pointer;
}
.taskModelProjectFiles .modal-dialog {
  max-width: 1080px;
}
.taskModelProjectFiles .ContactModel {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 48px;
  gap: 20px;
  position: relative;
  align-items: center;
}
.taskModelProjectFiles .dragItem {
  border-radius: var(--radius-sm, 6px);
  background: var(--Gray-100, #f2f4f7);
  padding: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.CustonCloseModalBottom .but {
  border-radius: 4px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  padding: 10px 18px;
  color: var(--Gray-700, #344054);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.CustonCloseModalBottom {
  border-top: 1px solid var(--Gray-200, #eaecf0);
  padding: var(--spacing-xl, 16px) var(--spacing-none, 0px);
  text-align: center;
}

.scheduler_default_divider,
.scheduler_default_splitter {
  height: calc(100vh - 170px) !important;
}

#calender .scheduler_default_rowheader {
  background: transparent !important;
}

#calender {
  height: auto !important;
}
.refrencesTag span {
  border-radius: 300px;
  background: var(--Primary-25, #f2faff);
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
}
.orderDiscription ul li {
  display: block;
  padding-bottom: 8px;
  color: var(--Text-Dark-Gray, #344054);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.08px;
}
.projectCardactionBut .actionRightSide button.InvoiceAction:hover {
  border-radius: var(--radius-xs, 4px);
  border: 1px solid #d7f2ee;
  background: #fff;
}
/* .projectCardactionBut .actionRightSide button.InvoiceAction.InvoiceActive {
  border-radius: var(--radius-xs, 4px);
  border: 1px solid #97E4CE;
  background: linear-gradient(0deg, rgba(132, 250, 176, 0.10) 0%, rgba(172, 203, 238, 0.10) 100%);
  color: #60938E;
} */
.projectCardButWrap button.expense:hover {
  border-radius: var(--radius-xs, 4px);
  border: 1px solid #fcbed9;
  background: #fff;
  color: var(--Gray-700, #344054);
}
/* .projectCardButWrap button.expActive {
  border-radius: var(--radius-xs, 4px);
  border: 1px solid #F9758A;
  background: linear-gradient(135deg, rgba(247, 79, 172, 0.10) 0%, rgba(252, 178, 79, 0.10) 100%);
  color: #F96A94;
} */
.projectCardButWrap button.createJob:hover {
  border-radius: var(--radius-xs, 4px);
  border: 1px solid #ffb800;
  background: #fff;
  color: var(--Gray-700, #344054);
}
/* .projectCardButWrap button.jobActive {
  border-radius: var(--radius-xs, 4px);
border: 1px solid #FFB800;
background: linear-gradient(45deg, rgba(255, 184, 0, 0.10) 0%, rgba(255, 245, 0, 0.10) 100%);
color: #FFC501;
} */
.projectCardButWrap button.googleBut:hover {
  border-radius: var(--radius-xs, 4px);
  border: 1px solid #d9e8ff;
  background: #fff;
  color: var(--Gray-700, #344054);
}
/* .projectCardButWrap button.googleActive {
  border-radius: var(--radius-xs, 4px);
  border: 1px solid #D9E8FF;
  background: #F0F5FF;
color: #4285F4;
} */
.projectCardButWrap button.schedule:hover {
  border-radius: var(--radius-xs, 4px);
  border: 1px solid #6da7bd;
  background: rgba(255, 255, 255, 0.1);
  color: var(--Gray-700, #344054);
}
 .projectCardButWrap .dateSelectdTaskBar {
  border-radius: var(--radius-xs, 4px);
  border: 1px solid #6da7bd;
  background: rgba(109, 167, 189, 0.05);
color: #6DA7BD;
} 
.projectCardButWrap button.calenBut:hover {
  border-radius: var(--radius-xs, 4px);
  border: 1px solid #4285f4;
  background: #fff;
  color: var(--Gray-700, #344054);
}
/* .projectCardButWrap button.calenActive {
  border-radius: var(--radius-xs, 4px);
border: 1px solid #D9E8FF;
background: #F0F5FF;
color: #4285F4;
} */
.projectCardButWrap button.createPo:hover {
  border-radius: var(--radius-xs, 4px);
  border: 1px solid #4285f4;
  background: #fff;
  color: var(--Gray-700, #344054);
}
/* .projectCardButWrap button.poActive {
  border-radius: var(--radius-xs, 4px);
border: 1px solid #D9E8FF;
background: #F0F5FF;
color: #4285F4;
} */

.projectCardButWrap button.filebut {
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  padding: 10px 18px;
  background: #fff;
  color: var(--Gray-700, #344054);
  color: var(--Gray-700, #344054);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.projectCardButWrap button.filebut:hover {
  border-radius: var(--radius-xs, 4px);
  border: 1px solid #4285f4;
  background: #fff;
  color: var(--Gray-700, #344054);
}
/* .projectCardButWrap button.fileActive {
  border-radius: var(--radius-xs, 4px);
  padding: 10px 18px;
border: 1px solid #D9E8FF;
background: #F0F5FF;
color: #4285F4;
} */

.projectCardModel .szh-menu {
  width: 204px;
  height: 238px;
  overflow-y: auto;
}
.projectCardModel .szh-menu::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  padding: 5px;
}
.projectCardModel .szh-menu::-webkit-scrollbar-track {
  box-shadow: inset 0 0 8px #f2f4f7;
  border-radius: 10px;
}
.projectCardModel .szh-menu::-webkit-scrollbar-thumb {
  background: #76d1ff;
  border-radius: 10px;
}
.projectCardModel .szh-menu::-webkit-scrollbar-thumb:hover {
  background: #76d1ff;
}
.selectButStatus span.outArrow {
  display: none;
}
.selectButStatus .statusFlex {
  justify-content: space-between;
  padding-right: 8px;
}
.selectButStatus li.szh-menu__item:hover {
  background: transparent;
}
