/* Top Bars */
.salesTableWrap .table-responsive::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  padding: 5px;
}
.salesTableWrap tr.nodataTableRow:hover td {
  background: none;
}
.salesTableWrap .table-responsive::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px #fff;
  border-radius: 10px;
}
.salesTableWrap .table-responsive::-webkit-scrollbar-thumb {
  background: #eaecf0;
  border-radius: 10px;
}
.salesTableWrap .table-responsive::-webkit-scrollbar-thumb:hover {
  background: #eaecf0;
}
.tableTopBarSales .styleT3 span.styleT2 {
  border-radius: 16px;
  border: 1px solid var(--Primary-200, #a3e0ff);
  background: var(--Primary-50, #ebf8ff);
  mix-blend-mode: multiply;
  color: var(--Primary-500, #1ab2ff);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.12px;
}
.salesTableWrap button.btn.btn-ContactAdd {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .salesTableWrap .centerTabSales {
  height: 30px;
} */
.salesTableWrap .tabActive svg {
  margin-left: 7px;
}
.salesTableWrap .tabActive {
  border-radius: 4px;
  border: 1px solid #158ecc;
  background: #158ecc;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-left: 9px;
  padding: 4px 13px;
  color: #fff !important;
  min-width: 59px;
  height: 28px;
  display: flex;
  align-items: center;
  font-family: "Inter";
  border-radius: 40px;
}
.salesTableWrap
  .flatpickr-day.selected.startRange
  + .endRange:not(:nth-child(7n + 1)),
.salesTableWrap
  .flatpickr-day.startRange.startRange
  + .endRange:not(:nth-child(7n + 1)),
.salesTableWrap
  .flatpickr-day.endRange.startRange
  + .endRange:not(:nth-child(7n + 1)) {
  box-shadow: -13px 0 0 #7f56d9c7, 13px 0 0 #7f56d9c7 !important;
}
.salesTableWrap .flatpickr-day.inRange {
  box-shadow: -13px 0 0 #e2e2e2, 13px 0 0 #e2e2e2 !important;
}

.salesTableWrap table.innerTableStyle tr td {
  width: 109px;
  text-align: center;
}

.salesTableWrap .contactButList {
  width: 40px;
  background: #ebf8ff;
  height: 40px;
  padding: 4px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 auto;
}
.salesTableWrap .contactButListIn1 {
  width: 32px;
  background: #f2faff;
  height: 32px;
  padding: 4px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.salesTableWrap .styleT3 {
  color: rgb(71, 84, 103);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.08px;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.salesTableWrap .styleT4 {
  color: #1ab2ff;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.18px;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.salesTableWrap .styleT1 {
  color: rgb(7, 148, 85);
}
.salesTableWrap .contactButListIn2 {
  border-radius: 16px;
  border: 1px solid #76d1ff;
  background: #ebf8ff;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.salesTableWrap .modal-header {
  align-items: start;
}
.salesTableWrap button.btn-close {
  font-size: 12px;
}
.salesTableWrap .SalesContact.modal-header {
  padding: 24px 24px 0;
}
.salesTableWrap .SalesContact .modal-dialog .modal-content {
  width: 449px;
  border-radius: 12px;
  background: var(--Base-White, #fff);
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
    0px 20px 24px -4px rgba(16, 24, 40, 0.08);
  border: 0;
}
.salesTableWrap .styleColor1 {
  text-align: left;
}

.salesTableWrap .tableSales tbody tr td:nth-child(6) {
  text-align: center;
}
.salesTableWrap .saleNoteBorder span svg {
  margin-left: 2px;
}
.salesTableWrap .statusInfo {
  text-align: center;
}
.salesTableWrap .saleNoteBorder span {
  border-radius: 16px;
  border: 1px solid #eaecf0;
  background: #fff;
  color: #667085;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.12px;
  width: 58px;
  margin: 0 auto;
  padding: 4px 6px 4px 8px;
  display: flex;
  align-items: center;
}
.salesTableWrap .styleColor1 strong {
  color: #667085;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.14px;
  text-wrap: nowrap;
}
.salesTableWrap .leftStyle span {
  color: #344054;
}
.salesTableWrap .mainStyle {
  color: #344054;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.14px;
}
.salesTableWrap .noteInfo.Yes span {
  border-color: #17b26a;
  color: #17b26a;
}

.salesTableWrap .styleColor1 p {
  color: var(--colorG6);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.12px;
  margin: 0;
  text-wrap: nowrap;
}
.salesTableWrap .styleColor2 {
  color: var(--colorG1);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.14px;
}
.salesTableWrap .select-menu .select-btn span {
  padding-left: 9px;
  color: #667085;
}
/* .salesTableWrap .popupcrossInfo {
  position: absolute !important;
  top: 8px;
  right: 8px;
} */
div#popoverStyleChange {
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Base-White, #fff);
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
}
#placeholderColor {
  color: var(--Gray-400, #98a2b3);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.popoverHeadflex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.popupcrossInfomain h3 {
  color: var(--Gray-600, #475467);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.14px;
}
.popupcrossInfomain {
  background: var(--Gray-25, #fcfcfd);
}
.popoverendflex .popoverbottom {
  justify-content: end;
}
.popoverendflex button {
  padding: 10px 18px;
}
.popoverendflex .closebox {
  margin-right: 12px;
}

.salesTableWrap .contactInfo .contactdate {
  color: var(--gray-900, var(--gray-900, #101828));
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}
.salesTableWrap .contactInfo p {
  color: var(--gray-600, #475467);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
}

.salesTableWrap .statusInfo.Saved a,
.salesTableWrap .Saved .NameStatus {
  color: #1ab2ff;
  border: 1px solid #a3e0ff;
  background: #ebf8ff;
}
.MuiButtonBase-roo.LmenuList {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}
.salesTableWrap .flexBoxLabel {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}
.salesTableWrap .statusInfo.Accepted a,
.salesTableWrap .Accepted .NameStatus {
  color: #067647;
  border: 1px solid #abefc6;
  background: #ecfdf3;
}
.salesTableWrap .countStatus {
  border-radius: 16px;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Base-White, #fff);
  padding: 2px 8px;
  margin-left: 24px;
}
.salesTableWrap .statusInfo.Sent a,
.salesTableWrap .Sent .NameStatus {
  color: #026aa2;
  border: 1px solid #b9e6fe;
  background: #f0f9ff;
}

.salesTableWrap .statusInfo.Recurring a {
  color: #175cd3;
  border: 1px solid #b2ddff;
  background: #eff8ff;
}
.salesTableWrap .statusInfo.Review a {
  color: #175cd3;
  border: 1px solid #b2ddff;
  background: #eff8ff;
}

.salesTableWrap .statusInfo.Declined a {
  color: #b42318;
  border: 1px solid #fecdca;
  background: #fef3f2;
}
.salesTableWrap .statusInfo.Accepted a {
  color: #067647;
  border: 1px solid #abefc6;
  background: #ecfdf3;
}

.salesTableWrap .statusInfo.Required a {
  color: #b54708;
  border: 1px solid #fedf89;
  background: #fffaeb;
}
/* .salesTableWrap .disPlayInlineCenter li:nth-child(2) {
  padding: 0 25px;
} */
.salesTableWrap .disPlayInline li {
  display: inline-block;
  margin: 0 4px;
  padding: 10px;
  border-radius: 6px;
  width: 41px;
}
.mainStyleMin {
  width: 297px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap !important;
}
.salesTableWrap .disPlayInlineCenter li.Draft {
  pointer-events: none;
}
.salesTableWrap .disPlayInlineCenter li.Draft a svg {
  fill: #c3bdbd;
}
.salesTableWrap .disPlayInline .plusminus:hover {
  background: #fefaec !important;
}
.salesTableWrap .disPlayInline .plusminus:hover svg {
  fill: #fdb022 !important;
}
.salesTableWrap .disPlayInline li:nth-child(2):hover {
  background: #fcf3f2;
}
.salesTableWrap .disPlayInline li:nth-child(2):hover svg {
  fill: #ff0000;
}
.salesTableWrap .disPlayInline li:last-child:hover {
  background: #eff3fe;
}
.salesTableWrap .disPlayInline li:last-child:hover svg {
  fill: #3366cc;
}
.salesTableWrap .disPlayInlineCenter li.disable svg {
  fill: #98a2b3;
}
.salesTableWrap .disPlayInlineCenter li {
  cursor: pointer;
}

.salesTableWrap .circleImgStyle {
  border-radius: 200px;
  border: 1px solid var(--gray-200, #eaecf0);
  background: linear-gradient(180deg, #f9fafb 0%, #edf0f3 100%), #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}
.salesTableWrap .selected-row .styleColor1 strong {
  color: #344054;
}
.salesTableWrap .selected-row .leftStyle span {
  color: #667085;
}
.salesTableWrap .selected-row .disPlayInline svg {
  fill: #667085;
}
.salesTableWrap .circleImgStyle span {
  text-transform: uppercase;
  color: var(--Gray-600, #475467);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.25px;
  cursor: pointer;
  line-height: 17px !important;
  padding: 1px;
  display: inline-block;
}
.salesTableWrap #tooltip-top-start .tooltip-inner {
  border-radius: var(--radius-md, 8px);
  background: var(--Base-White, #fff);
  padding: 8px 12px;
  color: var(--Gray-700, #344054);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  margin: 0;
}

.salesTableWrap #tooltip-top-start {
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
}

.salesTableWrap #tooltip-top-start .tooltip-arrow {
  border-bottom-color: #434a54;
}

.salesTableWrap
  #tooltip-top-start[data-popper-placement^="top"]
  .tooltip-arrow::before,
.salesTableWrap #tooltip-top-start .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height)
    calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: #fff;
}

.salesTableWrap .progress {
  height: 20px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.salesTableWrap .progress-bar {
  float: left;
  width: 0%;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #337ab7;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

.salesTableWrap img.truePhotoStyle {
  border-radius: 4px;
}
.salesTableWrap img.falsePhotoStyle {
  border-radius: 100px;
}

.salesTableWrap .userImgStyle img {
  border: 0px solid #d7d7d7;
  width: 32px;
  height: 32px;
  object-fit: cover;
  float: left;
  padding: 2px;
}

.salesTableWrap .Quote a {
  border-radius: 16px;
  border: 1px solid var(--colorG2);
  background: var(--color9);
  mix-blend-mode: multiply;
  color: var(--colorG1);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  padding: 2px 8px;
  text-wrap: nowrap;
}

.salesTableWrap .innerFlex {
  display: flex;
  align-items: center;
  text-align: left;
}

.salesTableWrap .styleColor2 {
  color: var(--colorG4);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.14px;
}

.salesTableWrap .options {
  display: none;
  position: absolute;
  list-style: none;
  padding: 0;
  margin: 0;
}

.salesTableWrap .options.active {
  display: block;
}

.salesTableWrap .sBtn-text {
  display: flex;
  align-items: center;
  position: relative;
}
.salesTableWrap .progress {
  width: 32px;
  height: 32px;
  background: none;
  box-shadow: none;
  position: relative;
}
.salesTableWrap .progress:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 4px solid #efefef;
  position: absolute;
  top: 0;
  left: 0;
}
.salesTableWrap .progress > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}
.salesTableWrap .progress .progress-left {
  left: 0;
}
.salesTableWrap .progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 4px;
  border-style: solid;
  position: absolute;
  top: 0;
  border-color: #1ab2ff;
}
.salesTableWrap .progress .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}
.salesTableWrap .progress .progress-right {
  right: 0;
}
.salesTableWrap .progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
}
.salesTableWrap .progress .progress-value {
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  color: var(--Gray-500, #667085);
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.1px;
}
.salesTableWrap .progress .progress-value span {
  font-size: 12px;
  text-transform: uppercase;
}

/* This for loop creates the necessary css animation names 
Due to the split circle of progress-left and progress right, we must use the animations on each side. 
*/
.salesTableWrap .progress[data-percentage="1"] .progress-right .progress-bar {
  animation: loading-1 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="1"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="2"] .progress-right .progress-bar {
  animation: loading-2 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="2"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="3"] .progress-right .progress-bar {
  animation: loading-3 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="3"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="4"] .progress-right .progress-bar {
  animation: loading-4 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="4"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="5"] .progress-right .progress-bar {
  animation: loading-5 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="5"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="6"] .progress-right .progress-bar {
  animation: loading-6 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="6"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="7"] .progress-right .progress-bar {
  animation: loading-7 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="7"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="8"] .progress-right .progress-bar {
  animation: loading-8 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="8"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="9"] .progress-right .progress-bar {
  animation: loading-9 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="9"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="10"] .progress-right .progress-bar {
  animation: loading-10 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="10"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="11"] .progress-right .progress-bar {
  animation: loading-11 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="11"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="12"] .progress-right .progress-bar {
  animation: loading-12 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="12"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="13"] .progress-right .progress-bar {
  animation: loading-13 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="13"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="14"] .progress-right .progress-bar {
  animation: loading-14 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="14"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="15"] .progress-right .progress-bar {
  animation: loading-15 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="15"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="16"] .progress-right .progress-bar {
  animation: loading-16 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="16"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="17"] .progress-right .progress-bar {
  animation: loading-17 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="17"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="18"] .progress-right .progress-bar {
  animation: loading-18 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="18"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="19"] .progress-right .progress-bar {
  animation: loading-19 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="19"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="20"] .progress-right .progress-bar {
  animation: loading-20 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="20"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="21"] .progress-right .progress-bar {
  animation: loading-21 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="21"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="22"] .progress-right .progress-bar {
  animation: loading-22 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="22"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="23"] .progress-right .progress-bar {
  animation: loading-23 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="23"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="24"] .progress-right .progress-bar {
  animation: loading-24 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="24"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="25"] .progress-right .progress-bar {
  animation: loading-25 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="25"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="26"] .progress-right .progress-bar {
  animation: loading-26 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="26"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="27"] .progress-right .progress-bar {
  animation: loading-27 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="27"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="28"] .progress-right .progress-bar {
  animation: loading-28 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="28"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="29"] .progress-right .progress-bar {
  animation: loading-29 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="29"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="30"] .progress-right .progress-bar {
  animation: loading-30 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="30"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="31"] .progress-right .progress-bar {
  animation: loading-31 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="31"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="32"] .progress-right .progress-bar {
  animation: loading-32 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="32"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="33"] .progress-right .progress-bar {
  animation: loading-33 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="33"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="34"] .progress-right .progress-bar {
  animation: loading-34 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="34"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="35"] .progress-right .progress-bar {
  animation: loading-35 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="35"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="36"] .progress-right .progress-bar {
  animation: loading-36 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="36"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="37"] .progress-right .progress-bar {
  animation: loading-37 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="37"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="38"] .progress-right .progress-bar {
  animation: loading-38 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="38"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="39"] .progress-right .progress-bar {
  animation: loading-39 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="39"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="40"] .progress-right .progress-bar {
  animation: loading-40 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="40"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="41"] .progress-right .progress-bar {
  animation: loading-41 0.5s linear forwards;
}
.progress[data-percentage="41"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="42"] .progress-right .progress-bar {
  animation: loading-42 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="42"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="43"] .progress-right .progress-bar {
  animation: loading-43 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="43"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="44"] .progress-right .progress-bar {
  animation: loading-44 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="44"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="45"] .progress-right .progress-bar {
  animation: loading-45 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="45"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="46"] .progress-right .progress-bar {
  animation: loading-46 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="46"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="47"] .progress-right .progress-bar {
  animation: loading-47 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="47"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="48"] .progress-right .progress-bar {
  animation: loading-48 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="48"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="49"] .progress-right .progress-bar {
  animation: loading-49 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="49"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="50"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="50"] .progress-left .progress-bar {
  animation: 0;
}

.salesTableWrap .progress[data-percentage="51"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="51"] .progress-left .progress-bar {
  animation: loading-1 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="52"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="52"] .progress-left .progress-bar {
  animation: loading-2 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="53"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="53"] .progress-left .progress-bar {
  animation: loading-3 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="54"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="54"] .progress-left .progress-bar {
  animation: loading-4 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="55"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="55"] .progress-left .progress-bar {
  animation: loading-5 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="56"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="56"] .progress-left .progress-bar {
  animation: loading-6 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="57"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="57"] .progress-left .progress-bar {
  animation: loading-7 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="58"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="58"] .progress-left .progress-bar {
  animation: loading-8 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="59"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="59"] .progress-left .progress-bar {
  animation: loading-9 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="60"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="60"] .progress-left .progress-bar {
  animation: loading-10 0.5s linear forwards 0.5s;
}
.salesTableWrap .progress[data-percentage="61"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="61"] .progress-left .progress-bar {
  animation: loading-11 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="62"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="62"] .progress-left .progress-bar {
  animation: loading-12 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="63"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="63"] .progress-left .progress-bar {
  animation: loading-13 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="64"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="64"] .progress-left .progress-bar {
  animation: loading-14 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="65"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="65"] .progress-left .progress-bar {
  animation: loading-15 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="66"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="66"] .progress-left .progress-bar {
  animation: loading-16 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="67"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="67"] .progress-left .progress-bar {
  animation: loading-17 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="68"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="68"] .progress-left .progress-bar {
  animation: loading-18 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="69"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="69"] .progress-left .progress-bar {
  animation: loading-19 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="70"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="70"] .progress-left .progress-bar {
  animation: loading-20 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="71"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="71"] .progress-left .progress-bar {
  animation: loading-21 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="72"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="72"] .progress-left .progress-bar {
  animation: loading-22 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="73"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="73"] .progress-left .progress-bar {
  animation: loading-23 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="74"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="74"] .progress-left .progress-bar {
  animation: loading-24 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="75"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="75"] .progress-left .progress-bar {
  animation: loading-25 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="76"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="76"] .progress-left .progress-bar {
  animation: loading-26 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="77"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="77"] .progress-left .progress-bar {
  animation: loading-27 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="78"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="78"] .progress-left .progress-bar {
  animation: loading-28 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="79"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="79"] .progress-left .progress-bar {
  animation: loading-29 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="80"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="80"] .progress-left .progress-bar {
  animation: loading-30 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="81"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="81"] .progress-left .progress-bar {
  animation: loading-31 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="82"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="82"] .progress-left .progress-bar {
  animation: loading-32 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="83"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="83"] .progress-left .progress-bar {
  animation: loading-33 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="84"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="84"] .progress-left .progress-bar {
  animation: loading-34 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="85"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="85"] .progress-left .progress-bar {
  animation: loading-35 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="86"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="86"] .progress-left .progress-bar {
  animation: loading-36 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="87"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="87"] .progress-left .progress-bar {
  animation: loading-37 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="88"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="88"] .progress-left .progress-bar {
  animation: loading-38 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="89"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="89"] .progress-left .progress-bar {
  animation: loading-39 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="90"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="90"] .progress-left .progress-bar {
  animation: loading-40 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="91"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="91"] .progress-left .progress-bar {
  animation: loading-41 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="92"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="92"] .progress-left .progress-bar {
  animation: loading-42 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="93"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="93"] .progress-left .progress-bar {
  animation: loading-43 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="94"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="94"] .progress-left .progress-bar {
  animation: loading-44 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="95"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="95"] .progress-left .progress-bar {
  animation: loading-45 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="96"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="96"] .progress-left .progress-bar {
  animation: loading-46 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="97"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="97"] .progress-left .progress-bar {
  animation: loading-47 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="98"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="98"] .progress-left .progress-bar {
  animation: loading-48 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="99"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="99"] .progress-left .progress-bar {
  animation: loading-49 0.5s linear forwards 0.5s;
}

.salesTableWrap .progress[data-percentage="100"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.salesTableWrap .progress[data-percentage="100"] .progress-left .progress-bar {
  animation: loading-50 0.5s linear forwards 0.5s;
}

@keyframes loading-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(3.6);
    transform: rotate(3.6deg);
  }
}
@keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(7.2);
    transform: rotate(7.2deg);
  }
}
@keyframes loading-3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(10.8);
    transform: rotate(10.8deg);
  }
}
@keyframes loading-4 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(14.4);
    transform: rotate(14.4deg);
  }
}
@keyframes loading-5 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(18);
    transform: rotate(18deg);
  }
}
@keyframes loading-6 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(21.6);
    transform: rotate(21.6deg);
  }
}
@keyframes loading-7 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(25.2);
    transform: rotate(25.2deg);
  }
}
@keyframes loading-8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(28.8);
    transform: rotate(28.8deg);
  }
}
@keyframes loading-9 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(32.4);
    transform: rotate(32.4deg);
  }
}
@keyframes loading-10 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(36);
    transform: rotate(36deg);
  }
}
@keyframes loading-11 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(39.6);
    transform: rotate(39.6deg);
  }
}
@keyframes loading-12 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(43.2);
    transform: rotate(43.2deg);
  }
}
@keyframes loading-13 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(46.8);
    transform: rotate(46.8deg);
  }
}
@keyframes loading-14 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(50.4);
    transform: rotate(50.4deg);
  }
}
@keyframes loading-15 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(54);
    transform: rotate(54deg);
  }
}
@keyframes loading-16 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(57.6);
    transform: rotate(57.6deg);
  }
}
@keyframes loading-17 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(61.2);
    transform: rotate(61.2deg);
  }
}
@keyframes loading-18 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(64.8);
    transform: rotate(64.8deg);
  }
}
@keyframes loading-19 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(68.4);
    transform: rotate(68.4deg);
  }
}
@keyframes loading-20 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(72);
    transform: rotate(72deg);
  }
}
@keyframes loading-21 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(75.6);
    transform: rotate(75.6deg);
  }
}
@keyframes loading-22 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(79.2);
    transform: rotate(79.2deg);
  }
}
@keyframes loading-23 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(82.8);
    transform: rotate(82.8deg);
  }
}
@keyframes loading-24 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(86.4);
    transform: rotate(86.4deg);
  }
}
@keyframes loading-25 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(90);
    transform: rotate(90deg);
  }
}
@keyframes loading-26 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(93.6);
    transform: rotate(93.6deg);
  }
}
@keyframes loading-27 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(97.2);
    transform: rotate(97.2deg);
  }
}
@keyframes loading-28 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(100.8);
    transform: rotate(100.8deg);
  }
}
@keyframes loading-29 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(104.4);
    transform: rotate(104.4deg);
  }
}
@keyframes loading-30 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(108);
    transform: rotate(108deg);
  }
}
@keyframes loading-31 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(111.6);
    transform: rotate(111.6deg);
  }
}
@keyframes loading-32 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(115.2);
    transform: rotate(115.2deg);
  }
}
@keyframes loading-33 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(118.8);
    transform: rotate(118.8deg);
  }
}
@keyframes loading-34 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(122.4);
    transform: rotate(122.4deg);
  }
}
@keyframes loading-35 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(126);
    transform: rotate(126deg);
  }
}
@keyframes loading-36 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(129.6);
    transform: rotate(129.6deg);
  }
}
@keyframes loading-37 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(133.2);
    transform: rotate(133.2deg);
  }
}
@keyframes loading-38 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(136.8);
    transform: rotate(136.8deg);
  }
}
@keyframes loading-39 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(140.4);
    transform: rotate(140.4deg);
  }
}
@keyframes loading-40 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(144);
    transform: rotate(144deg);
  }
}
@keyframes loading-41 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(147.6);
    transform: rotate(147.6deg);
  }
}
@keyframes loading-42 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(151.2);
    transform: rotate(151.2deg);
  }
}
@keyframes loading-43 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(154.8);
    transform: rotate(154.8deg);
  }
}
@keyframes loading-44 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(158.4);
    transform: rotate(158.4deg);
  }
}
@keyframes loading-45 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(162);
    transform: rotate(162deg);
  }
}
@keyframes loading-46 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(165.6);
    transform: rotate(165.6deg);
  }
}
@keyframes loading-47 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(169.2);
    transform: rotate(169.2deg);
  }
}
@keyframes loading-48 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(172.8);
    transform: rotate(172.8deg);
  }
}
@keyframes loading-49 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(176.4);
    transform: rotate(176.4deg);
  }
}
@keyframes loading-50 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(180);
    transform: rotate(180deg);
  }
}
.salesTableWrap li.option.active,
.salesTableWrap li.option:hover {
  border-radius: 6px;
  background: var(--primary-50, #ebf8ff);
}
.salesTableWrap .checkbox-custom,
.salesTableWrap .radio-custom {
  opacity: 0;
  position: absolute;
  width: 100%;
}

/* .salesTableWrap .checkbox-custom,.salesTableWrap .checkbox-custom-label,.salesTableWrap .radio-custom,.salesTableWrap .radio-custom-label {
  display: inline-block;
  vertical-align: middle;
  margin: 5px;
  cursor: pointer;
  margin-right: 11px;
} */

.salesTableWrap .checkbox-custom-label,
.salesTableWrap .radio-custom-label {
  position: relative;
  display: none;
}

/* .salesTableWrap .checkbox-custom+.checkbox-custom-label:before,
.salesTableWrap .radio-custom+.radio-custom-label:before {
  content: '';
  border-radius: var(--radius-sm, 6px);
  border: 1px solid var(--gray-300, #D0D5DD);
  background: var(--base-white, #FFF);
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  padding: 2px;
  margin-right: 10px;
  text-align: center;
} */

.salesTableWrap .checkbox-custom:checked + .checkbox-custom-label:before {
  content: "";
  color: #bbb;
  background: url(/src/assets/images/icon/check.png);
  background-repeat: no-repeat;
  background-position: center;
}

.salesTableWrap .radio-custom:checked + .radio-custom-label:before {
  border-radius: var(--radius-sm, 6px);
  border: 1px solid var(--primary-500, #9e77ed);
  background: var(--primary-50, #f9f5ff);
}

.salesTableWrap .radio-custom:checked + .radio-custom-label:before {
  content: "";
  color: #bbb;
  background: url(/src/assets/images/icon/check.png);
  background-repeat: no-repeat;
  background-position: center;
}

.salesTableWrap .checkbox-custom:focus + .checkbox-custom-label,
.salesTableWrap .radio-custom:focus + .radio-custom-label {
  outline: 1px solid #ddd;
}

.salesTableWrap .options {
  display: none;
  position: absolute;
  list-style: none;
  padding: 0;
  margin: 0;
}

.salesTableWrap .options.active {
  display: block;
}

.salesTableWrap .select-menu .select-btn {
  display: flex;
  border-radius: 8px;
  align-items: center;
  cursor: pointer;
  justify-content: flex-start;
}
.salesTableWrap .select-menu .select-btn svg {
  position: absolute;
  right: 0;
}
.salesTableWrap .select-btn i {
  font-size: 25px;
  transition: 0.3s;
}

.salesTableWrap .select-menu {
  position: relative;
}

.salesTableWrap .progressWrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  /* float: left; */
  width: 100%;
}
.salesTableWrap .progress-light-grey {
  background-color: #eaecf0;
  width: 175px;
  border-radius: 8px;
  height: 8px;
}
.salesTableWrap .progress-color {
  color: #fff;
  border-radius: 8px;
  height: 8px;
}
.salesTableWrap .progress-container span {
  opacity: 0;
}
.salesTableWrap .progressWrapper label {
  color: #344054;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-left: 17px;
  position: relative;
  top: 6px;
}
.salesTableWrap li.option.active .labelInfo strong {
  font-weight: 600;
}
.salesTableWrap .labelInfo strong {
  color: var(--gray-700, #344054);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.14px;
  margin-bottom: 5px;
  display: inline-block;
}
.salesTableWrap .progressWrapper .labelInfo {
  text-align: left;
}
.salesTableWrap .select-menu.active .select-btn i {
  transform: rotate(-180deg);
}
.salesTableWrap .select-menu .options {
  position: absolute;
  padding: 0 6px;
  margin-top: 10px;
  width: 278px;
  z-index: 99;
  left: -99px;
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Base-White, #fff);
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}
.salesTableWrap .select-menu.active .options {
  display: block;
}
.salesTableWrap .options .option {
  cursor: pointer;
  padding: 12px 21px 17px;
  border-radius: 8px;
  align-items: center;
  background: #fff;
  margin: 6px 0;
}
@keyframes progress {
  0% {
    --percentage: 0;
  }

  100% {
    --percentage: var(--value);
  }
}

@property --percentage {
  syntax: "<number>";
  inherits: true;
  initial-value: 0;
}
.salesTableWrap .styleActionBtn button:first-child {
  margin-right: 2px;
}
.salesTableWrap .styleActionBtn button:last-child {
  margin-left: 3px;
}
.salesTableWrap .styleActionBtn button {
  border-radius: 4px;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--base-white, #fff);
  padding: 10px;
  color: #d92d20;
  vertical-align: middle;
  display: inline-grid;
  align-items: center;
  justify-content: center;
  margin: 0;
  min-width: auto;
}
.salesTableWrap .styleActionBtn button.quoteWon:hover {
  border-radius: 4px;
  border: 1px solid var(--Success-200, #a9efc5);
  background: var(--Success-50, #ecfdf3);
}
.salesTableWrap .styleActionBtn button.quoteLost:hover {
  border-radius: 4px;
  border: 1px solid var(--Error-200, #fecdca);
  background: var(--Error-100, #fee4e2);
}
.salesTableWrap .ActionsDotsBox ul {
  padding: 0;
}

.salesTableWrap .ActionsDotsBox li.MuiButtonBase-root {
  color: #344054;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.14px;
  justify-content: space-between;
  padding: 9px 14px;
}

.salesTableWrap .styleActionBtn a:last-child {
  color: #079455;
  margin-left: 7px;
}

.salesTableWrap .styleActionBtn a svg {
  font-size: 21px;
}

.salesTableWrap td.ActionBtn a svg {
  color: #667085;
  font-size: 35px;
}
.salesTableWrap ul.multiDropdown {
  width: 211px;
  position: relative;
  text-align: left;
}

.salesTableWrap .multiDropdown .subMenuDropdown {
  position: absolute;
  text-align: left;
  padding: 2px 6px;
  border-radius: 4px 0px 0px 4px;
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--base-white, #fff);
}

.salesTableWrap .multiDropdown .subMenuDropdown ul.subMenuDropdown {
  position: absolute;
  /* right: -95px; */
}
.salesTableWrap .innerTableStyle {
  display: grid;
  justify-content: center;
  align-content: center;
}
.salesTableWrap .innerTableStyle ul {
  display: grid;
  grid-template-columns: repeat(3, 84px);
  grid-gap: 0px 6px;
  align-items: center;
  text-align: center;
}
.salesTableWrap.bs-popover-bottom .popover-arrow {
  display: none;
}
.salesTableWrap .MuiDataGrid-cell [data-field="Contact"] {
  justify-content: center !important;
}
.salesTableWrap .btn-toolbar {
  justify-content: center;
}
.btn-saleContacte {
  margin: 0;
  padding: 0;
  border: 0;
}
.salesTableWrap .contactButListIn2:hover {
  border: 1px solid #76d1ff;
  background: #76d1ff57;
}
/* button.btn.btn-saleContacte,.salesTableWrap .selected-row .disPlayInline svg ,.salesTableWrap .disPlayInlineCenter svg{
  transition: transform .2s; 
}
button.btn.btn-saleContacte:hover svg,.salesTableWrap .selected-row .disPlayInline svg:hover ,.salesTableWrap .disPlayInlineCenter svg:hover{
    transform: scale(1.06);
} */

.contactInfoflex .ClosePopover {
  position: relative;
  top: 0;
  padding: 0;
  margin: 0;
}
.contactInfoflex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.salesTableWrap.bs-popover-bottom {
  border-radius: 4px;
  background: var(--Base-White, #fff);
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
    0px 20px 24px -4px rgba(16, 24, 40, 0.08);
  border: 0;
  max-width: 400px;
  width: 400px;
}
.salesTableWrap .btn-saleContacte:focus {
  border: 0;
}
.salesTableWrap .btn-saleContacte div {
  color: var(--Gray-500, #667085);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.12px;
}
.salesTableWrap .ContactModel span {
  display: block;
  color: #344054;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin: 10px 0 8px;
  padding: 0;
}
.salesTableWrap .MuiPopover-paper {
  margin-left: -18px !important;
}

/* Progress */
.salesTableWrap .QuoteActionStyle .modelHeader span {
  display: flex;
  align-items: flex-start;
}
.salesTableWrap .QuoteActionStyle .modelHeader span .iconOutStyle {
  margin-bottom: 0;
}
.salesTableWrap .QuoteLost .modelHeader span .iconOutStyle {
  background: #fef3f2;
}
.salesTableWrap .QuoteLost .modelHeader span .iconinStyle {
  background: #fee4e2;
}
.salesTableWrap .QuoteLost .modelHeader span .iconinnerStyle {
  background: #fee4e2;
  border: 1px solid #fee4e2;
}
.salesTableWrap .stepBoxStyle ul,
.salesTableWrap .stepBoxStyle ul li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.salesTableWrap .stepBoxStyle ul li span {
  color: #344054;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.salesTableWrap .stepBoxStyle ul li strong {
  border-radius: var(--radius-lg, 10px);
  border: 1px solid
    var(
      --Component-colors-Components-Icons-Featured-icons-Modern-featured-icon-modern-border,
      #eaecf0
    );
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.salesTableWrap .stepBoxStyle ul li em.lineHor {
  border-radius: var(--spacing-xxs, 2px);
  background: var(--Colors-Border-border-secondary, #eaecf0);
  width: 48px;
  height: 2px;
  display: inline-block;
  margin: 0 7px;
}
.salesTableWrap .stepBoxStyle ul {
  margin-top: 16px;
  margin-bottom: 16px;
}
.salesTableWrap .QuoteActionStyle .stepBoxStyle {
  margin-top: 0px;
}
.salesTableWrap .modelStyleBox button.MuiButtonBase-root {
  margin-top: -9px;
  color: #667085;
}
.salesTableWrap .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgba(16, 24, 40, 0.5) !important;
}
.salesTableWrap .QuoteActionStyle .iconOutStyle {
  margin-bottom: 0 !important;
}
.salesTableWrap .stepBoxStyle ul li:first-child span {
  margin-left: 9px;
}
.salesTableWrap .stepBoxStyle ul li:last-child span {
  margin-left: 9px;
}
.salesTableWrap .ContactModel span.flexRemove {
  margin-top: 12px;
}
.salesTableWrap .ContactModel span.flexRemove {
  margin-top: 16px;
  margin-bottom: 3px;
}
.salesTableWrap
  .datepickerField
  .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 16.5px 14px;
  padding-right: 0;
  color: var(--Gray-400, #98a2b3);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  font-family: Inter;
}
.salesTableWrap .SalesContact textarea.form-control {
  min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
  color: var(--Gray-500, #667085);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 12px;
}
.salesTableWrap .stepBoxStyle p {
  color: var(--colors-text-text-tertiary-600, #475467);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.salesTableWrap .QuoteActionStyle {
  padding: 0;
}
.salesTableWrap .stepBoxStyle ul span {
  margin-bottom: 0;
}
.salesTableWrap .QuoteActionStyle .modelHeader {
  padding: 24px 24px 0 24px;
}
.salesTableWrap .footerBorder button {
  border-radius: 40px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  padding: 10px 18px;
  color: var(--Gray-700, #344054);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.salesTableWrap .footerBorder {
  border-top: 1px solid #eaecf0;
  padding: 24px;
  margin-top: 13px;
}

/* Nodata */
.Nodata {
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  float: left;
  width: 100%;
}
.Nodata .image {
  position: relative;
}
.Nodata .image img.SearchIcon {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 13px;
  margin: 0 auto;
}
.gobackButton,
.gobackButton:hover {
  border-radius: 4px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  color: var(--Gray-700, #344054);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.gobackSupport,
.gobackSupport:hover {
  text-decoration: none;
  color: var(--Primary-700, #106b99);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.salesTableWrap .tooltipBox {
  color: #344054;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}
.iconOutStyleflex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0px 24px 3px;
}
.flexIconWrap {
  display: flex;
  align-items: center;
}
.salesTableWrap .footerBorder {
  border-top: 1px solid #eaecf0;
  padding: 12px 24px 0px;
  margin-top: 0;
  display: flex;
  justify-content: flex-end;
}
/* Top Filter tags */

.salesTableWrap .mainTagsWrapper {
  border-top: 1px solid #eaecf0;
  background: #ebf8ff;
  padding: 0px 45px;
  text-align: left;
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
}
.salesTableWrap .mainTagsWrapper::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  padding: 5px;
}
.salesTableWrap .mainTagsWrapper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px #fff;
  border-radius: 10px;
}
.salesTableWrap .mainTagsWrapper::-webkit-scrollbar-thumb {
  background: #eaecf0;
  border-radius: 10px;
}
.salesTableWrap .mainTagsWrapper::-webkit-scrollbar-thumb:hover {
  background: #eaecf0;
}

.salesTableWrap .tags-input-container .tag-item-wrap {
  display: inline-block;
}
.salesTableWrap .tags-input-container {
  margin-right: 20px;
  margin-top: 8px;
  margin-bottom: 8px;
}
.salesTableWrap .tags-input-container .tag-item-wrap button,
.mainTagsWrapper button {
  padding: 0;
}

.salesTableWrap .tags-input-container .tag-item-wrap button {
  padding: 0;
  margin: 0;
  display: inline-flex;
}
.salesTableWrap .tags-input-container .tag-item-wrap {
  border-radius: var(--radius-md, 8px);
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  margin-right: 8px;
  align-items: center;
  color: var(--Gray-700, #344054);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  padding: 3px 8px;
  display: flex;
}
.salesTableWrap .tags-input-container .tag-item-wrap .tag-item {
  border-radius: 6px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  display: flex;
  padding: 3px 4px 3px 8px;
  align-items: center;
  margin-right: 8px;
}
.salesTableWrap .tags-input-container .tag-item-wrap .tag-item:last-child {
  margin-right: 0;
}
.salesTableWrap .tags-input-container .tag-item span.text {
  color: var(--Gray-700, #344054);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.salesTableWrap .tags-input-container .close {
  cursor: pointer;
}
.salesTableWrap .tags-input-container .tag-item-wrap .tag-item .close {
  margin-left: 7px;
}
.salesTableWrap .tags-input-container .tag-item-wrap .tag-item.green {
  border-radius: 16px;
  border: 1px solid var(--Success-200, #abefc6);
  background: var(--Success-50, #ecfdf3);
  mix-blend-mode: multiply;
}
.salesTableWrap .tags-input-container .tag-item-wrap .tag-item.green span.text {
  color: var(--Success-700, var(--Success-700, #067647));
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.salesTableWrap .tags-input-container .tag-item-wrap .tag-item.blue {
  border-radius: 16px;
  border: 1px solid var(--Blue-200, #b2ddff);
  background: var(--Blue-50, #eff8ff);
  mix-blend-mode: multiply;
}
.salesTableWrap .tags-input-container .tag-item-wrap .tag-item.blue span.text {
  color: var(--Blue-700, #175cd3);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.salesTableWrap .tableTopBar div#example-fade-text {
  position: absolute;
}
.salesTableWrap .tableTopBar div#example-fade-text .filterWrap {
  position: absolute;
  z-index: 999;
  left: 0;
  top: 21px;
}

.salesTableWrap .colfilterSmall {
  border-radius: 4px 0px 0px 4px;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Base-White, #fff);
  width: 211px;
  padding: 2px 6px;
}
.salesTableWrap .colfilterSmall .nav-item a.active {
  border-radius: 6px;
  background: var(--Primary-50, #f9f5ff);
  color: var(--Primary-500, #9e77ed);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.175px;
}
.salesTableWrap .colfilterSmall .nav-item a {
  color: var(--Gray-700, #344054);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.14px;
  display: flex;
  align-items: center;
  padding: 9px 10px;
}

.salesTableWrap .colfilterSmall .nav-item a svg {
  margin-right: 12px;
}
.filterWrapmain {
  display: flex;
  align-items: flex-start;
}
.salesTableWrap .colfilterbig {
  border-radius: 0px var(--radius-md, 8px) var(--radius-md, 8px)
    var(--radius-md, 8px);
  border: 1px solid var(--Gray-100, #f2f4f7);
  background: var(--Base-White, #fff);
  padding: 16px 0px 0;
  min-width: 278px;
}
.salesTableWrap .colfilterSmall .nav-item a.active svg {
  fill: #9e77ed;
}
.salesTableWrap .ClosePopover {
  position: absolute;
  right: 15px;
  padding-top: 0;
  top: 24px;
}

.salesTableWrap .tags-input-container .tag-item-wrap .tag-item {
  border: 1px solid #d0d5dd;
  border: 1px solid var(--Gray-300, #d0d5dd);
  border-radius: 6px;
  padding: 3px 4px 3px 8px;
}

.salesTableWrap .tags-input-container .tag-item-wrap li {
  border: 1px solid #d0d5dd;
  border: 1px solid var(--Gray-300, #d0d5dd);
  border-radius: 6px;
  padding: 3px 4px 3px 8px;
  color: var(--Gray-700, #344054);
  text-align: center;
  display: inline-block;
  margin-right: 6px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.salesTableWrap .tags-input-container ul {
  border-radius: var(--radius-md, 8px);
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  padding: 6.5px 14px;
  display: flex;
  align-items: center;
}
.salesTableWrap .ProgressTag .option .progressWrapper {
  margin-left: 16px;
}
.salesTableWrap .ProgressTag .option {
  display: flex;
  align-items: center;
}
.salesTableWrap .ProgressTag .option input:checked ~ .checkmark {
  border-radius: var(--radius-sm, 6px);
  border: 1px solid var(--Primary-500, #9e77ed) !important;
  background: var(--Primary-50, #f9f5ff);
}
.salesTableWrap .ProgressTag .option .checkmark {
  border: 1px solid #d0d5dd !important;
  border: 1px solid var(--Gray-300, #d0d5dd) !important;
}

.salesTableWrap table thead tr th:nth-child(3) {
  position: sticky;
  left: 192px;
  min-width: 270px;
  z-index: 5;
  border-right: 1px solid var(--color10) !important;
}
.salesTableWrap table thead tr th:nth-child(4) {
  position: sticky;
  left: 462px;
  border-left: 0 !important;
  border-right: 1px solid var(--color10) !important;
  z-index: 8;
}
.salesTableWrap table tbody tr td:nth-child(4):before,
.salesTableWrap table thead tr th:nth-child(4):before {
  position: absolute;
  background: var(--color10);
  content: "";
  left: -1px;
  width: 0px;
  height: 100%;
  top: 0;
  z-index: 0;
}
.salesTableWrap table tbody tr td:nth-child(4)::after,
.salesTableWrap table thead tr th:nth-child(4)::after {
  position: absolute;
  background: var(--color10);
  content: "";
  right: -1px;
  width: 1px;
  height: 100%;
  top: 0;
  /* box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.10); */
  z-index: 0;
}
.salesTableWrap table thead tr th:nth-child(2) {
  position: sticky;
  min-width: 144px;
  left: 48px;
  z-index: 8;
  border-right: 0px solid var(--color10) !important;
  padding-left: 0px !important;
}
.salesTableWrap table tbody tr td:nth-child(2):before {
  position: absolute;
  background: var(--color10);
  content: "";
  right: 0;
  width: 1px;
  height: 100%;
  top: 0;
}
.salesTableWrap table tbody tr td:nth-child(4):before,
.salesTableWrap table thead tr th:nth-child(4):before {
  left: 0px;
  width: 0px;
}
.salesTableWrap table thead tr th:nth-child(4) {
  border-right: 0px solid var(--color10) !important;
}
.salesTableWrap table thead tr th:nth-child(2):before {
  position: absolute;
  background: var(--color10);
  content: "";
  right: 0;
  width: 1px;
  height: 100%;
  top: 0;
  z-index: 0;
}
.styleActionBtn .message.true {
  pointer-events: none;
  opacity: 0;
}
.salesTableWrap table tbody tr td:nth-child(2) {
  padding-left: 0 !important;
}
.salesTableWrap .saleNoteBorder {
  white-space: nowrap;
}
.salesTableWrap .disPlayInlineCenter {
  text-align: center;
  white-space: nowrap;
}
.salesTableWrap .SalesContact.modal-header {
  padding: 16px 24px 12px;
  align-items: center;
}

.SalesContact .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 460px;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Base-White, #fff);
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  margin: 0 auto;
}
.SalesContact
  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error
  .MuiOutlinedInput-notchedOutline {
  border-color: #d0d5dd;
}
.SalesContact
  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline {
  border-color: #d0d5dd;
}
.SalesContact textarea.form-control {
  color: var(--Gray-400, #98a2b3);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.SalesContact .popoverbottom .closebox {
  margin-right: 12px;
}
.SalesContact .popoverbottom button {
  min-width: 90px;
}
.SalesContact .modelStyle .modal-footer,
.SalesContact .popoverbottom {
  justify-content: flex-end;
  display: flex;
}

@media only screen and (max-width: 1600px) and (min-width: 1280px) {
  .salesTableWrap .mainStyleMin {
    width: 221px;
  }
  .salesTableWrap table tbody tr td:nth-child(4):before,
  .salesTableWrap table thead tr th:nth-child(4):before {
    left: 0px;
    width: 1px;
  }
  .salesTableWrap table tbody tr td:nth-child(4)::after,
  .salesTableWrap table thead tr th:nth-child(4)::after {
    position: absolute;
    background: var(--color10);
    content: "";
    right: 0;
    width: 1px;
    height: 100%;
    top: 0;
    box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.1);
    z-index: 0;
  }
  .salesTableWrap table thead tr th:nth-child(3) {
    position: sticky;
    left: 192px;
    width: 270px;
    z-index: 5;
    border-right: 0px solid var(--color10) !important;
  }
  .salesTableWrap .table-responsive {
    overflow-x: auto;
  }
  .salesTableWrap .select-menu {
    position: relative;
    width: 200px;
  }
  .salesTableWrap .salesTableWrap table thead tr th:nth-child(2),
  .salesTableWrap table tbody tr td:nth-child(2) {
    position: sticky;
    left: 48px;

    z-index: 8;
    border-right: 0px solid var(--color10) !important;
    padding-left: 0px !important;
  }
  .salesTableWrap .salesTableWrap table tbody tr td:nth-child(2):before {
    position: absolute;
    background: var(--color10);
    content: "";
    right: 0;
    width: 1px;
    height: 100%;
    top: 0;
  }
  .salesTableWrap .salesTableWrap table thead tr th:nth-child(2):before {
    position: absolute;
    background: var(--color10);
    content: "";
    right: 0;
    width: 1px;
    height: 100%;
    top: 0;
    z-index: 0;
  }

  .salesTableWrap .salesTableWrap table thead tr th:nth-child(3),
  .salesTableWrap table tbody tr td:nth-child(3) {
    position: sticky;
    left: 192px;
    min-width: 270px;
    z-index: 5;
    border-right: 0px solid var(--color10) !important;
  }

  .salesTableWrap table thead tr th:nth-child(4) {
    left: 462px;
  }
  .salesTableWrap .salesTableWrap table thead tr th:nth-child(4),
  .salesTableWrap table tbody tr td:nth-child(4) {
    position: sticky;
    left: 462px;
    border-left: 0 !important;
    border-right: 0px solid var(--color10) !important;
    z-index: 8;
  }
  .salesTableWrap .salesTableWrap table tbody tr td:nth-child(4):before,
  .salesTableWrap table thead tr th:nth-child(4):before {
    position: absolute;
    background: var(--color10);
    content: "";
    left: 0;
    width: 1px;
    height: 100%;
    top: 0;
    z-index: 0;
  }
  .salesTableWrap .salesTableWrap table tbody tr td:nth-child(4)::after,
  .salesTableWrap table thead tr th:nth-child(4)::after {
    position: absolute;
    background: var(--color10);
    content: "";
    right: 0;
    width: 1px;
    height: 100%;
    top: 0;
    box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.1);
    z-index: 0;
  }
  .salesTableWrap table thead tr th:nth-child(2) {
    left: 48px;
  }
}

.salesOverlay .p-overlaypanel-content {
  padding: 4px 4px;
  min-width: 250px;
}

.salesOverlay .fullnameText {
  color: var(--Gray-900, #101828);

  /* Text md/Medium */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
