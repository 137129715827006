.quotationWrapperPage {
  position: relative;
  background: var(--BG, #fff);

  width: 100%;

  .quotationScroll {
    overflow-y: auto;
    height: calc(100vh - 0px);
    width: 100%;
    position: relative;
    padding: 40px 0 82px 0px;
    &::before {
      background: #f7f9fc;
      height: 515px;
      width: 100%;
      top: 0;
      position: absolute;
      content: "";
    }
  }
  .quotationWrapper {
    border-radius: 4px 4px 0 0;
    padding: 51px 51px 0 51px;
    background: #fff;
    box-shadow: 0px 20.652px 27.536px -6.884px rgba(16, 24, 40, 0.08),
      0px 6.884px 10.326px -3.442px rgba(16, 24, 40, 0.03);
    width: 1024px;
    margin: 0 auto;
    z-index: 9;
    position: relative;
    .quotationHead {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        h1 {
          margin: 0;
          color: var(--Gray-900, #1a1c21);
          font-size: 48px;
          font-style: normal;
          font-weight: 600;
          line-height: 55.072px;
          text-transform: capitalize;
        }
        span {
          background: var(--Gray-800, #1d2939);
          padding: 2px 6.884px;
          color: var(--Colors-Foreground-fg-white, #fff);
          color: var(--Colors-Foreground-fg-white, #fff);
          font-family: Inter;
          font-size: 18.931px;
          font-style: normal;
          font-weight: 500;
          line-height: 27.536px;
          letter-spacing: 0.568px;
          text-transform: uppercase;
        }
      }
    }
  }
}

.paidLabel {
  top: -0px;
  left: 0px;
  width: 100%;
  height: 40px;
  display: flex;
  color: #085d3a;
  font-weight: 600;
  justify-content: center;
  position: absolute;
  background: #dcfae6;
  border: 1px solid #a9efc5;
}

.Accepted,
.quotationWrapper.Accepted td {
  background: #effaf4 !important;
}

.quotationWrapper.Accepted th {
  background-color: #e8f5f1 !important;
  border-bottom: 1px solid #eaecf0;
}

.Declined,
.quotationWrapper.Declined td {
  background: #fef2f2 !important;
}

.quotationWrapper.Declined th {
  background-color: #f8eded !important;
  border-bottom: 1px solid #eaecf0;
}

.Completed,
.quotationWrapper.Completed td {
  background: #f4f5f6 !important;
}

.quotationWrapper.Completed th {
  background-color: #eff0f3 !important;
  border-bottom: 1px solid #eaecf0;
}

.Review,
.quotationWrapper.Review td {
  background: #fffaf3 !important;
}

.quotationWrapper.Review th {
  background-color: #faf5f0 !important;
  border-bottom: 1px solid #eaecf0;
}

.topCaption {
  text-align: center;
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 30.978px; /* 64.538% */
  margin: auto;
  z-index: 10;
  position: relative;
  display: flex;
  width: 504px;
  height: 95px;
  padding: var(--spacing-4xl, 32px);
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 32px 32px 0px 0px;
}

.text.Accepted {
  color: var(--Success-900, #074d31);
}

.text.Declined {
  color: #7a271a;
}

.text.Review {
  color: #593812;
}

.text.Completed {
  color: #101828;
}

.quotationRefress {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 13px;
  .left {
    width: 50%;
    p {
      margin: 0;
      color: var(--Gray-800, #1d2939);
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.12px;
      span {
        color: var(--Gray-800, #1d2939);
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0.12px;
      }
    }
  }
  .right {
    width: 50%;
    p {
      margin: 0;
      color: var(--Gray-800, #1d2939);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24.094px;
      strong {
        color: var(--Gray-800, #1d2939);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24.094px;
      }
    }
  }
}
.quotationAddress {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 30px;
  .left {
    width: 50%;
    p {
      margin-bottom: 5px;
      color: var(--Gray-600, #475467);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    ul {
      li {
        color: var(--Gray-800, #1d2939);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.16px;
        padding: 3px 0;
        strong {
          color: var(--Gray-800, #1d2939);
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0.2px;
        }
      }
    }
  }
  .right {
    width: 50%;
    p {
      color: var(--Gray-600, #475467);
      font-size: 15.489px;
      font-style: normal;
      font-weight: 400;
      line-height: 24.094px;
      margin-bottom: 5px;
    }
    ul {
      li {
        color: var(--Gray-800, #1d2939);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.16px;
        padding: 3px 0;
        strong {
          color: var(--Gray-800, #1d2939);
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0.2px;
        }
      }
    }
  }
}

.quotationtable {
  margin: 30px auto 0;
  background: #fff;
  border-radius: 0 0 4px 4px;
  background: #fff;
  z-index: 9;
  position: relative;
  // box-shadow: 0px 20.652px 27.536px -6.884px rgba(16, 24, 40, 0.08), 0px 6.884px 10.326px -3.442px rgba(16, 24, 40, 0.03);
}

.quotationfooter {
  z-index: 10;
  width: 100%;
  position: fixed;
  bottom: 0;
  background: #fff;
  box-shadow: 0px -2px 5px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.06);
  .contanerfooter {
    width: 1024px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0px;
    .right {
      display: flex;
    }
    button {
      border-radius: var(--radius-xs, 4px);
      border-radius: var(--radius-xs, 4px);
      border: 1px solid var(--Gray-300, #d0d5dd);
      background: #fff;
      display: flex;
      padding: var(--spacing-lg, 12px) var(--spacing-2xl, 20px);
      justify-content: center;
      align-items: center;
      color: var(--Gray-700, #344054);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;

      &.decline {
        border: 1px solid var(--Error-200, #fecdca);
        background: var(--Error-50, #fef3f2);
        color: var(--Error-700, #b42318);
      }
      &.accept {
        border: 1px solid var(--Success-600, #079455);
        background: var(--Success-600, #079455);
        color: #fff;
        margin-left: 12px;
      }
    }
  }
}

.qupteMainColWrap {
  h2 {
    color: var(--Gray-700, #344054);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.16px;
    margin: 0;
  }
  ul {
    p {
      color: var(--Gray-500, #667085);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.14px;
      margin: 0;
    }
    li {
      color: var(--Gray-500, #667085);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      list-style: disc;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.14px;
      position: relative;
      margin-left: 15px;
    }
  }
}
.invoiceWrapTable {
  tr {
    th {
      border: 0;
      text-align: right !important;
      justify-content: flex-end;
      background: #f9fafb;
      color: var(--Gray-900, #101828);
      font-family: Inter;
      font-size: 12px !important;
      font-style: normal;
      font-weight: 400;
      line-height: 24.094px;
    }
  }
  tr {
    td {
      border: 0;
      color: var(--Gray-500, #344054);
      // font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 24.094px;
      letter-spacing: 0.172px;
      background: #fff !important;
      white-space: unset;
      // border-bottom: 1px solid #EAECF0;
    }
  }
}

.qupteMainColFooter {
  h2 {
    color: var(--Gray-800, #1d2939);
    font-family: Inter;
    font-size: 17.21px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.215px;
  }
  p {
    color: var(--Gray-600, #475467);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
  }
}
.footerBorder {
  border-bottom: 1px solid #eaecf0 !important;
  padding: 10px 0 !important;
  &.footerBorder:nth-child(2) {
    text-align: left !important;
  }
  &.footerBorder:last-child {
    text-align: right !important;
  }
}
.footerBoldText {
  color: var(--Gray-900, #101828) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 14px !important;
}
.footerBoldTextLight {
  color: var(--Gray-800, #1d2939) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 14px !important;
}
.footerBoldTextLight1 {
  color: var(--Gray-800, #1d2939);
  text-align: right !important;
  font-family: Inter !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400;
  line-height: 24px !important;
}
.logoWrapperFooter {
  display: flex;
  justify-content: center;
  padding: 56px 0 26px;
  p {
    color: var(--Gray-500, #667085);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    img {
      width: 37px;
      margin-left: 10px;
    }
  }
}

.modal {
  position: relative;
  width: 980px;
  border-radius: var(--radius-xs, 4px);
  background: var(--Base-White, #fff);
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
}

.modalHeader {
  gap: 16px;
  display: flex;
  align-items: center;
}

.headerTitle {
  font-size: 18px;
  font-weight: 600;
  color: var(--Gray-700, #344054);
}
.modelreadOutLIne {
  color: var(--Error-700, #b42318) !important;
  border: 0 !important;
  &:hover {
    color: var(--Error-700, #b42318) !important;
    border: 0 !important;
  }
}

.formWrapNote {
  label {
    color: #475467;
    color: var(--Gray-600, #475467);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.14px;
    line-height: 22px;
    margin-bottom: 6px;
  }
  .inputInfo {
    background: #fff;
    background: var(--Base-White, #fff);
    border: 1px solid #d0d5dd;
    border: 1px solid var(--Border, #d0d5dd);
    border-radius: 4px;
    border-radius: var(--radius-xs, 4px);
    padding: 8px 14px;
    textarea {
      border: 0;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: -0.08px;
      line-height: 24px;
      min-height: 140px;
      width: 100%;
    }
  }
}

.lable {
  color: var(--Gray-600, #475467);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  letter-spacing: 0.14px;
}

.inputText {
  width: 100%;
  padding: 10px 14px;
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Border, #d0d5dd);
  background: var(--Base-White, #fff);
  box-shadow: none !important;
  outline: none !important;
  font-family: Inter;
  color: var(--Gray-900, #101828) !important;
}

.error {
  border: 1px solid red !important;
}

.phoneInput {
  width: 100%;
  height: 46px !important;
}

.countrySelector {
  height: 46px;
}

.headingInputs {
  color: var(--Gray-700, #344054);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
  margin-bottom: 16px;
}

.dropdownSelect {
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Border, #d0d5dd);
  background: var(--Base-White, #fff);
  box-shadow: none !important;
  outline: none !important;
}

.tempDelete,
.tempDelete:hover,
.tempDelete:focus {
  color: #b42318;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  display: flex;
  padding: var(--spacing-md, 8px) 14px;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-md, 8px);
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Error-200, #fecdca);
  background: var(--Error-50, #fef3f2);
}

.tempAdd,
.tempAdd:hover,
.tempAdd:focus {
  color: var(--Primary-700, #106b99);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  display: flex;
  padding: var(--spacing-md, 8px) 14px;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-md, 8px);
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Primary-200, #a3e0ff);
  background: var(--Primary-50, #ebf8ff);
}

.circledesignstyle {
  width: 48px;
  height: 48px;
  border-radius: 28px;
  display: flex;
  justify-self: center;
  align-items: center;
  .out {
    width: 48px;
    height: 48px;
    display: flex;
    justify-self: center;
    align-items: center;
    border-radius: 28px;
    border: 6px solid var(--Success-50, #ecfdf3);
    background: var(--Success-100, #dcfae6);
    padding: 6px;
  }
}

.stepper {
  display: flex;
  margin: auto;
  gap: calc(70px + 83px) ;
  position: relative;
  padding-right: 60px;
}

.stepper::after {
  content: "";
  width: 260px;
  height: 2px;
  top: 20px;
  left: 50px;
  z-index: 0;
  background-color: #EAECF0;
  position: absolute;
}

.personalDetails,
.paymentMethod {
  display: flex;
  z-index: 2;
  align-items: center;
  flex-direction: column;

  span {
    text-align: center;

    /* Body default bold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 157.143% */
    letter-spacing: 0.175px;
  }
}

.stepperButton {
  width: 40px;
  height: 40px;
  padding: 4px;
  display: flex;
  color: unset !important;
  cursor: default !important;
  justify-content: center;
  align-items: center;
}

.stepperButton:hover {
  color: unset !important;
  cursor: default !important;
  padding: 4px !important;
}