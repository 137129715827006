.formWrapEmail {
    display: flex;
    align-items: center;
    .boxGroupList {
        margin-right: 16px;
        label {
        display: block;
        visibility: visible !important;
        color: var(--Gray-600, #475467);
margin-bottom: 6px;
/* body default medium */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 22px; /* 157.143% */
letter-spacing: 0.14px;

    }
    p{
        color: #B42318;
        position: absolute;
    }
    button{
        border-radius: 16px;
        border: 1px solid var(--Success-200, #A9EFC5);
        background: var(--Success-50, #ECFDF3);
        mix-blend-mode: multiply;
        padding: var(--spacing-xxs, 2px) var(--spacing-md, 8px);
        color: var(--Success-700, #067647);
        text-align: center;
        margin-top: 29px;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; 
        &:hover{
            border-radius: 16px;
        border: 1px solid var(--Success-200, #A9EFC5);
        background: var(--Success-50, #ECFDF3);
        mix-blend-mode: multiply;
        padding: var(--spacing-xxs, 2px) var(--spacing-md, 8px);
        color: var(--Success-700, #067647);
        text-align: center;
        margin-top: 29px;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; 
        }

    }
    input {
        border-radius: var(--radius-xs, 4px);
        border: 1px solid var(--Border, #D0D5DD);
        background: #FFF;
        padding: var(--spacing-md, 8px) var(--spacing-lg, 12px);
        color: var(--Gray-900, #101828);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.08px;
    }
    }
}

#outgoingTable{
    tr{
        td{
            padding: 32px 0 42px !important;
        }
    }
    #addmoreOption{
        &:hover{
        padding: 0 !important;
        }
    }
}

