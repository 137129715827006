.topHeadStyle {
    border-bottom: 1px solid #EAECF0;
    margin-bottom: 20px;
}
.editPara {
    ul {
        li {
            display: block;
        }
    }
    p,strong {
        color: var(--Gray-600, #475467);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
   strong {
        color: var(--Gray-600, #475467);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 6px;
        margin-top: 6px;
        display: inline-block;
        text-transform: uppercase;
        text-align: left;
    }
    h4{
        text-align: left
    }
    
}
.qlEditorText{
    p,strong {
        color: var(--Gray-600, #475467);
        font-family: Inter;
        font-size: 14px !important;
        font-style: normal;
        font-weight: 400 !important;
        line-height: 20px!important;
    }
   strong {
        color: var(--Gray-600, #475467);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 6px;
        margin-top: 6px;
        display: inline-block;
        text-transform: uppercase;
        text-align: left;
    }
    h4{
        text-align: left
    }     
}