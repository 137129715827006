.modal {
  width: 607px;
  border-radius: var(--radius-xs, 4px);
  background: var(--Base-White, #fff);
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
}

.modalHeader {
  gap: 16px;
  display: flex;
  align-items: center;
}

.headerTitle {
  font-size: 18px;
  font-weight: 600;
  color: var(--Gray-700, #344054);
}

.iconStyle {
  display: flex;
  position: relative;
  width: 48px;
  height: 48px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  border: 4px solid #ffe8cd;
  background: linear-gradient(
    135deg,
    rgba(255, 211, 165, 0.2) 0%,
    rgba(253, 101, 133, 0.2) 100%
  );
}

.addRemoveBox {
  width: 294px;
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: #fff;
}

.addRemoveBoxLabel {
  color: var(--Gray-700, #344054);

  /* Body large bold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: 0.08px;
}

.addRemoveBoxNumber {
  color: var(--Gray-400, #98a2b3);
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 34px; /* 106.25% */
  letter-spacing: -0.8px;
}

.addRemoveButtonBox {
  display: flex;
}

.addRemoveButtonLeft {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  border-radius: 40px 0px 0px 40px;
  border-top: 1px solid var(--Gray-300, #d0d5dd);
  border-bottom: 1px solid var(--Gray-300, #d0d5dd);
  border-left: 1px solid var(--Gray-300, #d0d5dd);
  border-right: 1px solid var(--Gray-300, #d0d5dd);
  background: #fff;
}

.addRemoveButtonRight {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  border-radius: 0px 40px 40px 0px;
  border-top: 1px solid var(--Gray-300, #d0d5dd);
  border-right: 1px solid var(--Gray-300, #d0d5dd);
  border-bottom: 1px solid var(--Gray-300, #d0d5dd);
  background: #fff;
}

.currentPricing {
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  border-radius: 6px;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: #fff;
  height: fit-content;
}

.priceBoxText {
  color: var(--Gray-700, #344054);

  /* Body large bold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: 0.08px;
  margin-bottom: 5px;
}

.priceBoxPrice {
  color: var(--Gray-400, #98a2b3);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 34px; /* 106.25% */
  letter-spacing: -0.8px;
}

.slashText {
  color: var(--Gray-700, #344054);

  /* Body default bold */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 157.143% */
  letter-spacing: 0.175px;
}

.userTable thead tr th,
.userTable tbody tr td {
  border-right: 0px solid #fff !important;
}