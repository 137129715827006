.popupModalStyle {
    color: #FFF;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin: 0 auto;
}
.contactFinanceWrap .rightText h2 {
    color: var(--Gray-900, #101828);
    text-align: left;
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: -0.72px;
    /* position: relative; */
}
/* .contactFinanceWrap .rightText h2::before {
    position: absolute;
    content: "";
    background: #000;
    width: 347px;
    height: 1px;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 0;
} */
.contactFinanceWrap .rightText.col-sm-6 {
    padding: 0 30px;
}
.contactFinanceWrap .modelHeaderBillig button {
    position: relative;
    top: -19px;
}
.contactFinanceWrap .modelHeaderBillig {
    padding: 0;
    border-bottom: 0px solid #EAECF0;
    text-align: right;
}
.contactFinanceWrap .rightText p {
    color: var(--Gray-600, #475467);
    text-align: left;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: -0.48px;
}
.contactFinanceWrap .rightText ul li {
    color: var(--Gray-600, #475467);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    padding: 24px 0;
    display: flex;
}
.contactFinanceWrap .rightText ul li img {
    width: 48px;
    height: 48px;
}
.contactFinanceWrap .rightText ul li span {
    margin-left: 16px;
    padding-right: 18px;
}
.contactFinanceWrap .bgShade p {
    color: var(--Gray-900, #101828);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: right;
}

.bgShade {
    border-radius: var(--radius-xs, 4px);
    background: var(--Primary-50, #F9F5FF);
    padding: 16px;
}
.bgShade strong {
    color: var(--Gray-900, #101828);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    display: block;
    text-align: right;
}
.centerimgTag {
    text-align: center;
}
.bgShade span {
    color: var(--Gray-900, #101828);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: right;
    display: block;
}
.newtaskaddModal .inputInfo textarea {
    width: 100%;
    min-height: 140px;
    border: 0;
}
.newtaskaddModal .leftTaskActionBtn button, .newtaskaddModal .leftTaskActionBtn button:hover {
    background: transparent;
    border: 0;
    padding: 0;
    width: auto;
    min-width: 0 !important;
    margin-right: 12px;
}
.newtaskaddModal .modal-content {
    width: 534px;
}
.taskBottonBar {
    justify-content: space-between !important;
    align-items: center;
}
.newtaskaddModal .inputInfo {
    border-radius: var(--radius-xs, 4px);
    border: 1px solid var(--Border, #D0D5DD);
    background: var(--Base-White, #FFF);
    padding: 8px 14px;
}
.contactFinanceWrap .leftFormWrap {
    border-radius: var(--radius-xs, 4px);
    border: 1px solid var(--Gray-200, #EAECF0);
    background: #FFF;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    padding: 48px 32px 56px;
}
.contactFinanceWrap .leftFormWrap h3 {
    color: var(--Gray-800, #1D2939);
    font-family: Inter;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.39px;
}
.contactFinanceWrap .inputInfo textarea {
    border: 0;
    width: 100%;
    min-height: 127px;
    color: var(--Gray-900, #101828);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.contactFinanceWrap .inputInfo {
    border-radius: var(--radius-xs, 4px);
    border: 1px solid var(--Border, #D0D5DD);
    background: var(--Base-White, #FFF);
    padding: 8px 12px;
}
.contactFinanceWrap .inputInfo input {
    color: var(--Gray-400, #98A2B3);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.08px;
    width: 100%;
}
.contactFinanceWrap .formBottom p {
    color: var(--Gray-600, #475467);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.14px;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 0;
}
.modelStyleBoxstatus {
    padding: 40px 20px;
    background: #fff;
    margin: 58px auto;
}
.contactFinanceWrap .formBottom{
    text-align: center;
}
.contactFinanceWrap .formBottom a {
    color: var(--Primary-600, #7F56D9);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: 0.175px;
    text-decoration-line: underline;
    display: block;
    text-align: center;
}
.contactFinanceWrap .formBottom button {
    padding: 10px 18px;
    margin-top: 42px;
    border-radius: 4px;
    border: 1px solid var(--Gray-300, #D0D5DD);
    background: var(--Base-White, #FFF);
    color: var(--Gray-700, #344054);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}