.mergeListItem {
    margin: 20px 0px;
}

.mergeListSrBox {
    width: 20px;
    height: 20px;
    font-size: 10px;
    color: #106B99;
    border-radius: 24px;
    background: #F2FAFF;
    border: 1px solid #A3E0FF;
}

.divider {
    height: 1px;
    margin: 20px 0px;
    background: #EAECF0;
}

.divider2 {
    height: 1px;
    background: #EAECF0;
    margin: 0px 0px 20px 0px;
}