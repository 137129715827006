.boxlist {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--radius-xs, 4px);
  border: 1px solid #eaecf0;
  background: #fff;
  padding: var(--spacing-xl, 16px);
  margin-bottom: 8px;
  cursor: pointer;
  &:hover {
    border-radius: var(--radius-xs, 4px);
    border: 1px solid var(--Primary-200, #a3e0ff);
    background: var(--Primary-25, #f2faff);
    .ricons {
      .pencilSquare {
        opacity: 1;
      }
    }
  }
  .ricons {
    .pencilSquare {
      fill: #1ab2ff;
      opacity: 0;
    }
  }
  span {
    color: var(--Gray-700, #344054);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
}

.modelStyleBoxstatus {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  background: var(--Base-White, #fff);
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
    0px 20px 24px -4px rgba(16, 24, 40, 0.08);
  padding: 0px;
}

.templateBoxWrap {
  border-radius: var(--radius-xs, 4px);
  background: var(--Gray-50, #f9fafb);
  padding: 32px 24px;
  .breadcrumbWrap {
    display: flex;
    align-items: center;
    .gobackBut {
      color: var(--Gray-600, #475467);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      background: transparent;
      border: 0;
      padding: 0;
      text-decoration: none;
      display: flex;
      cursor: pointer;
      &:hover {
        color: var(--Gray-600, #475467);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        background: transparent;
        border: 0;
        padding: 0;
      }
    }
    .activeLinkbread {
      color: var(--Primary-700, #106b99);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
    .readcrumbChevron {
      padding: 0 12px;
    }
    .MenuitemText {
      color: var(--Gray-600, #475467);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }

  .templateHeadBorder {
    border-bottom: 1px solid #eaecf0;
    margin-bottom: 20px;

    h1 {
      color: var(--Gray-900, #101828);
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      margin-top: 10px;
      margin-bottom: 15px;
    }
  }
  .proposalSetions {
    border-radius: var(--radius-xs, 4px);
    background: #fff;
    padding: var(--spacing-3xl, 24px);
    margin-bottom: 24px;
    h2 {
      color: var(--Gray-900, #101828);
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      span {
        border-radius: 22px;
        background: var(--Error-100, #fee4e2);
        padding: var(--spacing-xs, 4px);
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .addNewSections {
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 32px 0;
    button {
      border-radius: var(--radius-xs, 4px);
      border: 1px solid var(--Primary-200, #a3e0ff);
      background: var(--Primary-50, #ebf8ff);
      padding: 10px 18px;
      color: var(--Primary-700, #106b99);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
  }
}
.breadCrumbTemp {
  a {
    color: var(--Gray-600, #475467);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-decoration: none;
  }
  .activeDepartment {
    color: var(--Primary-700, #106b99);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
}
// Form Group
.formTemplateGroup {
  .lable {
    color: var(--Gray-600, #475467);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    letter-spacing: 0.14px;
  }
  .inputText {
    width: 100%;
    padding: 10px 14px;
    border-radius: var(--radius-xs, 4px);
    border: 1px solid var(--Border, #d0d5dd);
    background: var(--Base-White, #fff);
    box-shadow: none !important;
    outline: none !important;
    font-family: Inter;
    color: var(--Gray-900, #101828) !important;
  }

  .error {
    border: 1px solid red !important;
  }
  .lable1 {
    color: #475467;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.08px;
    background: #fff;
    padding: var(--spacing-xl, 16px) var(--spacing-3xl, 24px);
    border-radius: 4px;
    border-bottom: 1px solid var(--Gray-200, #eaecf0);
  }
  .paymentType {
    margin-top: 30px;
    padding: 0 24px;
    &.typeBorder {
      border-top: 1px solid #eaecf0;
      padding-top: 20px;
      padding-bottom: 30px;
    }
    .paymentmain {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 6px;
      span {
        margin: 0 24px;
        color: var(--Gray-600, #475467);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0.14px;
      }
    }
    .customRadio {
      // Hide the original radio button
      opacity: 0;
      position: absolute;
      width: 138px;
      height: 44px;

      // Create a custom radio button
      + label {
        position: relative;
        padding-left: 30px;
        cursor: pointer;
        font-size: 16px;
        user-select: none;
      }

      // Create the circle (custom radio button)
      + label {
        position: absolute;
        width: 138px;
        height: 44px;
        background: transparent;
        transition: all 0.3s ease;
        border-radius: var(--radius-xs, 4px);
        display: flex;
        align-items: center;
        padding: 10px 18px;
        justify-content: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        white-space: nowrap;
      }

      + label.fix {
        color: #1d2939;
        border: 1px solid #98B15B;
      }
  
      &:checked + label.fix {
        background: #e1fc9d;
        border: 1px solid #e1fc9d;
      }
  
      + label.hours {
        color: #5B5813;
        border: 1px solid #D0CA63;
      }
  
      &:checked + label.hours {
        background: #F9F49A;
        border: 1px solid #F9F49A;
      }
  
      + label.timetracker {
        color: #51256e;
        border: 1px solid #C295E0;
      }
  
      &:checked + label.timetracker {
        color: #1D2939;
        background: #E8CCFB;
        border: 1px solid #E8CCFB;
      }  

      + label.shift, + label.timeFrame {
        color: #344054;
        border: 1px solid #D0D5DD;
      }

      &:checked + label.shift, &:checked + label.timeFrame {
        color: #344054;
        background: #F2FAFF;
        border: 1px solid #BAE8FF;
      }

      // Create the inner dot when the radio is checked
      // &:checked + label {
      //   border: 1px solid #c295e0;
      //   color: #c295e0;
      // }

      // &:checked + label::before {
      //   border-color: #c295e0;
      //   color: #c295e0;
      // }
    }

    .radioLabel {
      margin-left: 0px; // Adjust spacing between the radio button and label text
    }

    .RadioButton {
      width: 138px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.listbox {
  display: flex;
  padding: var(--spacing-xl, 16px);
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-3xl, 24px);
  border-radius: var(--radius-xs, 4px);
  border: 1px solid #eaecf0;
  background: #fff;
  &:hover {
    border-radius: var(--radius-xs, 4px);
    border: 1px solid var(--Primary-200, #a3e0ff);
    background: var(--Primary-25, #f2faff);

    .editPencil {
      background: transparent !important;
      visibility: visible !important;
      cursor: pointer !important;
    }
  }

  .heading {
    color: var(--Gray-700, #344054);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 0px;
  }
}
.listbox.notCustomBox {
  background: #f2f4f7 !important;
  border: 1px solid var(--Gray-400, #98a2b3) !important;
}

.templateName {
  color: var(--Gray-900, #101828);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.inputBox {
  width: 100%;
  display: flex;
  padding: 10px 14px;
  align-items: center;
  gap: var(--spacing-md, 8px);
  align-self: stretch;
  border-radius: var(--radius-xs, 4px);
  border: 1px solid var(--Border, #d0d5dd);
  background: #fff;
  outline: none !important;
  box-shadow: none !important;
}

.label {
  color: var(--Gray-600, #475467);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.14px;
  margin-bottom: 5px;
}

.divider {
  width: 100%;
  height: 1px;
  margin-top: 16px;
  margin-bottom: 24px;
  background: #eaecf0;
}

.bottom {
  gap: 10px;
  height: 76px;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  background-color: #fff;
  width: calc(100% - 302px);
  padding: 16px;
}

.transparent,
.transparent:hover,
.transparent:focus {
  background: transparent !important;
}

.createProposal {
  .section {
    display: flex;
    position: relative;
    padding: var(--spacing-3xl, 24px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-xl, 16px);
    align-self: stretch;
    border-radius: var(--radius-xs, 4px);
    background: #fff;
    margin-bottom: 25px;

    .sectionName {
      color: var(--Gray-900, #101828);
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      margin-top: 0px !important;
    }
  }
}

.blueOutlineButton {
  margin: auto;
  color: #106b99 !important;
  font-weight: 600 !important;
  border: 1px solid #a3e0ff !important;
}

.deleteButton {
  position: absolute;
  right: 15.658px;
  top: 16px;
  display: flex;
  cursor: pointer;
  padding: var(--spacing-xs, 4px);
  align-items: center;
  gap: var(--spacing-md, 8px);
  border-radius: 22px;
  background: var(--Error-100, #fee4e2);
}
